export const SET_FRANCHISE_GET_STARTED_MESSAGE = "SET_FRANCHISE_GET_STARTED_MESSAGE";
export const SET_FRANCHISE_FOOTER_DATA = "SET_FRANCHISE_FOOTER_DATA";
export const SET_FRANCHISE_HOME_DATA = "SET_FRANCHISE_HOME_DATA";
export const SET_FRANCHISE_ABOUT_US = "SET_FRANCHISE_ABOUT_US";
export const SET_FRANCHISE_GET_STARTED = "SET_FRANCHISE_GET_STARTED";
export const SET_FRANCHISE_FAQ = "SET_FRANCHISE_FAQ";
export const SET_WHY_ZAXBYS = "SET_WHY_ZAXBYS";
export const SET_FRANCHISE_INVESTMENT = "SET_FRANCHISE_INVESTMENT";
export const SET_FRANCHISE_PROCESS = "SET_FRANCHISE_PROCESS";
export const SET_FRANCHISE_LOCATIONS = "SET_FRANCHISE_LOCATIONS";
export const SET_FRANCHISE_AWARDS = "SET_FRANCHISE_AWARDS";
export const SET_FRANCHISE_LEADERSHIP = "SET_FRANCHISE_LEADERSHIP";
export const SET_FRANCHISE_TESTIMONIALS = "SET_FRANCHISE_TESTIMONIALS";
export const SET_FRANCHISE_PROFILE = "SET_FRANCHISE_PROFILE";
export const SET_FRANCHISE_TRAINING = "SET_FRANCHISE_TRAINING";
export const SET_FRANCHISE_BLOGS = "SET_FRANCHISE_BLOGS";
export const SET_FRANCHISE_GET_STARTED_FORM_LOADING = "SET_FRANCHISE_GET_STARTED_FORM_LOADING";
export const SET_FRANCHISE_BLOGS_URLS = "SET_FRANCHISE_BLOGS_URLS";
