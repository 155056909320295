import { ReactNode } from "react";

import styled from "styled-components";

import { responsive } from "theme/responsive";

export interface Props {
  direction: "row" | "col";
  mobileDirection?: "row" | "col" | "row-reverse" | "col-reverse";
  children: ReactNode;
  gap?: number;
  className?: string;
}

const directionStyles = {
  row: `grid-auto-flow: column;`,
  col: `grid-auto-flow: row`,
};

const mobileDirectionStyles = {
  "col-reverse": `grid-auto-flow: row; & *:last-child { order: -1 }`,
  "row-reverse": `grid-auto-flow: column; & *:last-child { order: -1 }`,
};

const StackedButtonsContainer = styled.div<Props>`
  display: grid;
  padding-bottom: 1px;
  gap: ${(props) => props.gap || "25"}px;

  ${(props) => directionStyles[props.direction]}

  ${(props) =>
    responsive.max.lg(
      `gap: 10px;
      ${{ ...mobileDirectionStyles, ...directionStyles }[props.mobileDirection || props.direction]}
    `
    )}
`;

export default StackedButtonsContainer;
