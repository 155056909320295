export const SET_HOME_DATA = "SET_HOME_DATA";
export const SET_CHECKOUT_DATA = "SET_CHECKOUT_DATA";
export const SET_FOOTER_DATA = "SET_FOOTER_DATA";
export const SET_STORE_DETAILS_DATA = "SET_STORE_DETAILS_DATA";
export const SET_MENU_DATA = "SET_MENU_DATA";
export const SET_VIOLATOR_DATA = "SET_VIOLATOR_DATA";
export const CLOSE_VIOLATOR_BAR = "CLOSE_VIOLATOR_BAR";
export const SET_RETIRED_MENU = "SET_RETIRED_MENU";
export const SET_CAREERS_DATA = "SET_CAREERS_DATA";
export const SET_RESTAURANT_OPPORTUNITIES_DATA = "SET_RESTAURANT_OPPORTUNITIES_DATA";
export const SET_CORPORATE_OPPORTUNITIES_DATA = "SET_CORPORATE_OPPORTUNITIES_DATA";
export const SET_ABOUT_US_DATA = "SET_ABOUT_US_DATA";
export const SET_LEADERSHIP_DATA = "SET_LEADERSHIP_DATA";
export const PROLIFERATE_ARTICLES_DATA = "PROLIFERATE_ARTICLES_DATA";
export const SET_IS_ARTICLES_LOADED = "SET_IS_ARTICLES_LOADED";
export const SET_NEWS_PAGE_DATA = "SET_NEWS_PAGE_DATA";
export const SET_ALL_ARTICLES_LOADED = "SET_ALL_ARTICLES_LOADED";
export const SET_HISTORY_DATA = "SET_HISTORY_DATA";
export const SET_CONTACT_US_DATA = "SET_CONTACT_US_DATA";
export const SET_INITIAL_ARTICLES_DATA = "SET_INITIAL_ARTICLES_DATA";
export const SET_DOWN_DATA = "SET_DOWN_DATA";
export const SET_404_DATA = "SET_404_DATA";
export const SET_CORPORATE_DATA = "SET_CORPORATE_DATA";
export const CMS_IS_LOADING = "CMS_IS_LOADING";
export const SET_SIGNUP_SIGNIN_MESSAGE = "SET_SIGNUP_SIGNIN_MESSAGE";
export const SET_LOCATION_VERIFICATION_CHECKOUT_POPUP_DATA = "SET_LOCATION_VERIFICATION_CHECKOUT_POPUP_DATA";
export const SET_CHECKOUT_CMS_DATA = "SET_CHECKOUT_CMS_DATA";
export const SET_EXCLUDE_TAX_TEXT = "SET_EXCLUDE_TAX_TEXT";
