import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setCurrentDevice } from "redux/reducer/Device/DeviceAction";
import theme from "theme/theme";

function debounce(fn: any, ms: any) {
  let timer: any;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      // @ts-ignore
      fn.apply(this, arguments);
    }, ms);
  };
}

export const DeviceProvider = () => {
  const [resizeCounter, setResizeCounter] = useState(0);
  const isDesktop = useMediaQuery({ minWidth: theme.size.lg });
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== "undefined") {
      dispatch(setCurrentDevice(isDesktop ? "DESKTOP" : "MOBILE"));
    }
  }, [resizeCounter]);

  const debouncedHandleResize = debounce(function handleResize() {
    setResizeCounter((prev) => prev + 1);
  }, 100);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", debouncedHandleResize);
      return () => {
        window.removeEventListener("resize", debouncedHandleResize);
      };
    }
  }, []);

  return null;
};
