import { HeadingOverrideStyled } from "./HeadingOverrideStyled";

interface Props {
  children: string;
}

const HeadingOverride = ({ children }: Props) => {
  return (
    <HeadingOverrideStyled variant="primaryHeading2" className="headingOverride">
      {children}
    </HeadingOverrideStyled>
  );
};

export default HeadingOverride;
