import styled from "styled-components";

export const AppStoreLogosStyled = styled.div`
  display: flex;
  gap: 10px;
`;

export const AppStoreIconContainer = styled.div`
  width: 162px;
  height: 47.53px;
  position: relative;
`;
