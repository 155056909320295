import styled from "styled-components";
import { brandedScroll } from "theme/brandedScroll";

export const ItemsNotAddedModalStyled = styled.div<{ shouldNotScroll?: boolean }>`
  height: auto;
  max-height: 478px;
  min-height: 324px;
  width: 632px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${({ shouldNotScroll }) => !shouldNotScroll && brandedScroll}
  background-color: ${(props) => props.theme.color?.core.white};
  ${(props) => props.theme.responsive?.max.lg} {
    width: 272px;
    min-height: 272px;
    max-height: 406px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;
    position: relative;
    flex-shrink: 0;
    padding-bottom: 32px;
    background-color: ${(props) => props.theme.color?.primary.foundersBlue};
    z-index: 1;

    &:after {
      border-radius: 100%;
      position: absolute;
      content: "''";
      background-color: ${({ theme }) => theme?.color.core.white};
      right: -200px;
      left: -200px;
      bottom: -200px;
      top: 190px;
      ${(props) => props.theme.responsive?.max.lg} {
        right: -100px;
        left: -100px;
        top: 160px;
      }
    }

    h1 {
      color: ${({ theme }) => theme?.color.core.white};
      &.highlight {
        color: ${({ theme }) => theme?.color.primary.tongueTorchRed};
      }
    }
    .second-row {
      display: flex;
      align-items: baseline;
    }

    .subtitle {
      margin: 24px 0 36px;
      color: ${({ theme }) => theme?.color.core.white};
      text-align: center;
      ${(props) => props.theme.responsive?.max.lg} {
        max-width: 208px;
      }
    }
  }

  .body {
    background-color: ${({ theme }) => theme.color?.core.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 8px 18px 24px;
    position: relative;
    z-index: 1;
    flex-grow: 1;
    ${(props) => props.theme.responsive?.max.lg} {
      padding-top: 4px;
    }
  }
`;
