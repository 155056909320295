import styled from "styled-components";

import { responsive } from "theme/responsive";

export const MobileComponent = styled.div`
  &.heroImage {
    @media screen and (max-width: ${(props) => props.theme.size.sm}) {
      min-height: 318px;
    }
  }
  ${responsive.min.lg(`
        display: none;
    `)}
`;
