import { useRouter } from "next/router";
import { KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { ANALYTICS_CONST } from "constants/analytics";
import Analytics from "analytics/Analytics";
import useLocationSearch from "hooks/useLocationSearch";
import { defaultRadiusRange, DEFAULT_LAT, DEFAULT_LNG, initialState, DELIVERY_TABS, PICKUP_TABS } from "constants/constant";
import * as actions from "redux/reducer/Store/actions";
import { setUserAddress, setUserAddressFromAutoComplete, setUserEnteredDeliveryAddress } from "redux/reducer/Address/AddressAction";
import {
  setLocationOrderType,
  setLocationStep,
  setPinsDisableOnLocation,
  setIsNotShowFaveOrRecentTabs,
  setIsMapDragged,
  setLocationActiveTab,
} from "redux/reducer/Location/LocationAction";
import { setErrorMessageToModal, setErrorMessageToModalFlag } from "redux/reducer/UserStatus/UserStatusAction";
import { setUserLocation } from "redux/reducer/Store/actions";
import { RootState, SmartyStreet, SmartySuggestionsLatLngResponse, SmartySuggestionsResponse, Suggestions } from "src/types/types";
import { getStoreForDelivery } from "utils/helper";
import { getSmartyStreetNameViaLocation, getSmartyStreet } from "utils/smartyStreet/getSmartyStreet";
import { getGoogleGeocode } from "utils/getGoogleGeocode";
import { useDebounce } from "hooks/useDebounce";

interface SearchOption {
  searchAddress: string;
  isDeliveryOptionSelected: boolean;
  searchAddressSmarty: string;
}

const useSearchCard = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    pickupLocationName,
    orderType,
    errorMessageFlag,
    isAlternativeFlow,
    userAddressFromAutoComplete,
    deliveryLocationCoordinates,
    storeLocation,
    storeList,
    fromStorePage,
    deliveryLocationName,
    favouriteStoreList,
    recentStoreList,
    isAuthenticated,
    userCurrentLocation,
    isNotShowFaveOrRecentTabs,
    isZipSearch,
  } = useSelector((state: RootState | any) => ({
    pickupLocationName: state.store.pickupLocationName,
    deliveryLocationName: state.store.deliveryLocationName,
    deliveryLocationCoordinates: state.store.deliveryLocationCoordinates,
    storeLocation: state.store.storeLocation,
    storeList: state.store?.storeList,
    orderType: state.location.orderType,
    errorMessageFlag: state.userStatus?.errorMessageFlag,
    isAlternativeFlow: state.location.isAlternativeFlow,
    userAddressFromAutoComplete: state.address?.userAddressFromAutoComplete,
    fromStorePage: state.store?.fromStorePage,
    favouriteStoreList: state?.store?.favouriteStoreList,
    recentStoreList: state?.store?.recentStoreList,
    isAuthenticated: state.user.isAuthenticated,
    userCurrentLocation: state?.globalReducer?.userCurrentLocation,
    isNotShowFaveOrRecentTabs: state?.location?.isNotShowFaveOrRecentTabs,
    isZipSearch: state?.store?.isZipSearch,
  }));

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [smartySuggestion, setSmartySuggestion] = useState<SmartyStreet[]>([]);
  const [showLocationEnableModal, setShowLocationEnableModal] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [clearResult, setClearResult] = useState(false);
  const [searchOption, setSearchOption] = useState<SearchOption>({
    searchAddress: "",
    searchAddressSmarty: "",
    isDeliveryOptionSelected: false,
  });
  const [activeTAB, setActiveTAB] = useState(PICKUP_TABS[0].id);
  const [isInitialTabRendering, setIsInitialTabRendering] = useState(false);
  // For tracking if user has entered anything in the input feild
  // Scenario : If user comes from careers page, without typing anything its showing no result, For resolving that particular issue added this new state.
  const [hasEnteredText, setHasEnteredText] = useState<boolean>(false);

  const variant = searchOption.isDeliveryOptionSelected ? "delivery" : "pickup";

  const tabOptions = searchOption.isDeliveryOptionSelected ? DELIVERY_TABS : PICKUP_TABS;

  // The below variables are added to change the tab from search to fave or recent
  const hasStoreList = useDebounce(storeList?.length > 0);
  const faveStoreList = favouriteStoreList?.filter((item: any) => item.default);
  const hasFaveOrRecentStores = !hasStoreList && (faveStoreList?.length > 0 || recentStoreList?.length > 0);
  const showFaveOrRecentTabs = useDebounce(
    isAuthenticated &&
      // The below is enabled to not switch to fave or recent tabs
      !isNotShowFaveOrRecentTabs &&
      // If it is careers restaurant opportunities page then don't switch to any other tab
      !isAlternativeFlow &&
      variant === "pickup" &&
      // If the user toggle the button from pickup delivery button then don't switch it
      isInitialTabRendering &&
      hasFaveOrRecentStores,
    1000
  );

  const previousRoute = router?.query?.previousRoute;

  useEffect(() => {
    if (!userCurrentLocation) dispatch({ type: "SET_STORE_LIST", payload: [] });
  }, []);

  useEffect(() => {
    setActiveTAB(searchOption.isDeliveryOptionSelected ? DELIVERY_TABS[0].id : PICKUP_TABS[0].id);
  }, [variant]);

  useEffect(() => {
    if (showFaveOrRecentTabs) {
      if (hasStoreList) {
        setActiveTAB(PICKUP_TABS[0].id);
      }
      // When user comes from Account page show search tab
      if (!hasStoreList && faveStoreList?.length > 0 && previousRoute !== "/account/my-store") {
        setActiveTAB(PICKUP_TABS[1].id);
      }
      if (!hasStoreList && faveStoreList?.length <= 0 && recentStoreList?.length > 0) {
        setActiveTAB(PICKUP_TABS[2].id);
      }
    }
  }, [showFaveOrRecentTabs, hasStoreList, faveStoreList?.length, recentStoreList?.length]);

  // This will call by pressing enter key in the search input and it will take first record from the search result.
  const handleKeyDownSearch = (e: ReactKeyboardEvent<HTMLInputElement>) => {
    if (orderType === "PICKUP" || orderType === "DELIVERY") {
      if (e.key !== "Enter") return;
      const eve = new KeyboardEvent("keydown", {
        key: "ArrowDown",
        code: "ArrowDown",
        keyCode: 40,
      });

      if (smartySuggestion && smartySuggestion.length > 0) {
        // Get store details based on user's delivery location
        getStoresList(smartySuggestion[0]);
      }
      inputRef.current?.dispatchEvent(eve);
      inputRef.current?.blur();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const selectActive = (optionTAB: string) => {
    setIsInitialTabRendering(false);
    setActiveTAB(optionTAB);
  };

  // This will fetch the store details from google search auto complete api for pickup
  const address = useLocationSearch(inputRef, !searchOption.isDeliveryOptionSelected);

  // Fetch store list details from backend api
  function getStoreListDetails(lat: number | undefined, lng: number | undefined, zip?: string | undefined): void {
    if (!isAlternativeFlow) {
      dispatch(actions.setIsZipSearch(false));
      dispatch(
        actions.getStoreList({
          lat,
          lng,
          startdate: dayjs().format("YYYY-MM-DD"),
        })
      );
    } else {
      if (zip) {
        dispatch(actions.setIsZipSearch(true));
        dispatch(
          actions.getCareersStoreList({
            zip,
            lat,
            lng,
          })
        );
      } else {
        dispatch(actions.setIsZipSearch(false));
      }
    }
  }

  useEffect(() => {
    if (storeList?.length > 0) {
      // If storeList, then center the first store's lat/long centered
      dispatch(actions.setStoreLocation({ lat: storeList[0].latitude, lng: storeList[0].longitude }));
    } else {
      // This is used to display No Result found and default message
      if (!clearResult) {
        setNoResult(true);
      }
    }
  }, [storeList]);

  // This will be called only for Pickup
  useEffect(() => {
    if (address.addressName.length > 0) {
      // Need to check why we are calling handleChange everytime here
      handleChange("searchAddress", address.addressName);
      if (address.latitude !== 0 && address.longitude !== 0) {
        // Fetch store list details from api
        getStoreListDetails(address.latitude, address.longitude, address.zip);
      }
      // Set the user location to center the map on what was searched in the case no stores are found
      dispatch(
        setUserLocation({
          lat: address.latitude,
          lng: address.longitude,
        })
      );
      dispatch(setUserAddressFromAutoComplete(address));
    }
  }, [address]);

  useEffect(() => {
    dispatch(setErrorMessageToModalFlag(false));
    setSearchOption((prev) => ({ ...prev, searchAddress: pickupLocationName ?? "" }));

    // Reset store details and disable pin on location main page.
    // Not required to reset the store details as its updates once selecting the store
    // dispatch(actions.resetStoreDetail());
    dispatch(setPinsDisableOnLocation(false));

    // Call the variant toggle button in the initial rendering
    handleToggle("isDeliveryOptionSelected", orderType !== "PICKUP", true);

    // Get store details from Backend API based on users current latitude and longitude
    if (navigator.geolocation && !fromStorePage && pickupLocationName.length === 0) {
      navigator.geolocation.getCurrentPosition((position) => {
        getStoreListDetails(position.coords.latitude, position.coords.longitude);
      });
    } else if (pickupLocationName.length > 0) {
      if (storeList && storeList.length > 0) {
        // Centered the first store in the map
        dispatch(actions.setStoreLocation({ lat: storeList[0].latitude, lng: storeList[0].longitude }));
      }
    }
    return () => {
      // Disable the location enable modal on component destroy
      setShowLocationEnableModal(false);
      // reset the search option state
      setSearchOption({ searchAddress: "", searchAddressSmarty: "", isDeliveryOptionSelected: false });
      // reset the initial rendering state to false
      setIsInitialTabRendering(false);

      // reset the not switching the tabs to fave or recent tabs redux value to false
      if (isNotShowFaveOrRecentTabs) dispatch(setIsNotShowFaveOrRecentTabs(false));
    };
  }, []);

  useEffect(() => {
    // This is for delivery flow as soon as user type the address.
    const delayDebounceFn = setTimeout(() => {
      if (searchOption.searchAddressSmarty?.length === 0) {
        setSearchOption((prev) => ({ ...prev, searchAddressSmarty: "" }));
        setSmartySuggestion([]);
      } else {
        // Get results from SmartyStree based on user search
        getSmartyStreet(searchOption.searchAddressSmarty, 5).then((res: SmartySuggestionsResponse) => {
          if (res.success) {
            if (res?.response?.suggestions?.length) {
              const smartyResults = res.response.suggestions.map((object: Suggestions) => {
                return {
                  address: object.street_line,
                  addressLineOne: object.street_line,
                  description: object.street_line,
                  addressLineTwo: object.secondary,
                  area: "",
                  city: object.city,
                  state: object.state,
                  country: "",
                  zip: object.zipcode,
                  latitude: 0,
                  longitude: 0,
                  structured_formatting: {
                    main_text: object.street_line,
                    secondary_text: object.street_line,
                  },
                };
              });
              setSmartySuggestion(smartyResults);
            } else {
              dispatch(setErrorMessageToModal("Type a valid delivery address and select from the list of available options."));
              dispatch(setErrorMessageToModalFlag(true));
            }
          }
        });
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchOption.searchAddressSmarty]);

  // calling toggle function on change of order type, it can have different locations searched for both the inputs
  useEffect(() => {
    if (orderType === "PICKUP") {
      // Added amplitude click_pick_up event
      Analytics.getInstance().logEvent(ANALYTICS_CONST.ORDER.CLICK_PICK_UP, {}, false, true);
    } else {
      // Added amplitude click_delivery event
      Analytics.getInstance().logEvent(ANALYTICS_CONST.ORDER.CLICK_DELIVERY, {}, false, true);
    }
  }, [orderType]);

  // Clear the input on click on cross icon in search input
  const handleClearIcon = () => {
    if (searchOption.isDeliveryOptionSelected) {
      handleChange("searchAddressSmarty", "");
      dispatch(setUserEnteredDeliveryAddress({}));
      dispatch(actions.setUserLocation({}));
    } else {
      handleChange("searchAddress", "");
      dispatch(actions.resetStoreList());
    }
    setClearResult(true);
    setNoResult(false);
    dispatch(actions.setFilteredRadius(+defaultRadiusRange));
    dispatch(setIsMapDragged(false));
  };

  // GPS icon click event next to the search input
  const handleGPSIconClick = () => {
    dispatch(setIsMapDragged(false));
    if (orderType === "DELIVERY") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          // Reverse GeoCode API
          // Fetch user address based on the user's lat/long
          getSmartyStreetNameViaLocation(position.coords.latitude, position.coords.longitude).then(
            (res: SmartySuggestionsLatLngResponse) => {
              if (res?.response?.results && res.response.results.length > 0) {
                getStoresList({
                  addressLineOne: res.response.results[0].address.street,
                  city: res.response.results[0].address.city,
                  state: res.response.results[0].address.state_abbreviation,
                  zip: res.response.results[0].address.zipcode,
                  description: "",
                  addressLineTwo: "",
                  country: "",
                  latitude: 0,
                  longitude: 0,
                  area: "",
                });
              } else {
                dispatch(actions.setStoreLocation({ lat: position.coords.latitude, lng: position.coords.longitude }));
              }
            }
          );
        });
      }
    }
    // This will be called only for Pickup
    // This will reset existing values and get storeListDetails baed on user location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setShowLocationEnableModal(false);
          dispatch(setErrorMessageToModalFlag(false));
          handleChange("searchAddress", "");
          handleChange("searchAddressSmarty", "");
          dispatch(actions.setFilteredList(initialState));
          dispatch(actions.setFilteredRadius(+defaultRadiusRange));
          dispatch(actions.setSelectedStore(0));
          getStoreListDetails(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            setShowLocationEnableModal(true);
            dispatch(setErrorMessageToModalFlag(true));
          }
        }
      );
    }
  };

  // This will trigger while searching any location
  const handleChange = (name: string, value: string | undefined) => {
    setHasEnteredText(true);
    if (name === "searchAddress") {
      dispatch(actions.setPickupLocationName(value));
    } else {
      dispatch(actions.setDeliveryLocationName(value));
    }
    setSearchOption((prev) => ({ ...prev, [name]: value }));
  };

  // Toggle the Pickup and Delivery tab
  const handleToggle = (_name: string, checked: boolean, isInitialFunctionCall: boolean = false) => {
    setIsInitialTabRendering(isInitialFunctionCall);
    setSearchOption((prev) => ({ ...prev, isDeliveryOptionSelected: checked }));
    dispatch(setLocationOrderType(checked ? "DELIVERY" : "PICKUP"));
    if (orderType === "PICKUP") {
      if (pickupLocationName && pickupLocationName.length <= 0) {
        if (storeLocation && Object.keys(storeLocation).length !== 0) {
          dispatch(
            actions.setStoreLocation({
              lat: storeLocation.lat,
              lng: storeLocation.lng,
            })
          );
        } else {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                dispatch(
                  actions.setStoreLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  })
                );
              },
              (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                  dispatch(
                    actions.setStoreLocation({
                      lat: DEFAULT_LAT,
                      lng: DEFAULT_LNG,
                    })
                  );
                }
              }
            );
          }
        }
      } else {
        // If user has searched any location and while toggeling the tab, search value should maintain
        if (userAddressFromAutoComplete) {
          dispatch(
            actions.setStoreLocation({
              lat: userAddressFromAutoComplete.latitude,
              lng: userAddressFromAutoComplete.longitude,
            })
          );
        }
      }
    } else {
      // This is for delivery and maintain the search value while toggeling the tab
      if (deliveryLocationName.length > 0) {
        dispatch(
          actions.setStoreLocation({
            lat: deliveryLocationCoordinates.lat,
            lng: deliveryLocationCoordinates.lng,
          })
        );
      } else {
        dispatch(
          actions.setStoreLocation({
            lat: DEFAULT_LAT,
            lng: DEFAULT_LNG,
          })
        );
      }
    }
  };

  // get selected address nearby store
  const getStoresList = (option: SmartyStreet) => {
    let latitude = 0;
    let longitude = 0;
    setSmartySuggestion([]);
    dispatch(actions.setDeliveryLocationName(option.addressLineOne));
    let addressForGeolocation = option.addressLineOne;
    if (option.city && option.zip) {
      addressForGeolocation += " " + option.city + " " + option.zip;
    }

    // get lat and lng from the address user selected from smarty using google geocode api
    // Need to check getGoogleGeoCode call needs to verify again, if it is required or not
    getGoogleGeocode(addressForGeolocation)
      .then((res) => {
        if (res.success) {
          latitude = res.response?.results[0]?.geometry.location.lat;
          longitude = res.response?.results[0]?.geometry.location.lng;
          option.latitude = latitude;
          option.longitude = longitude;
        }
        // setting the lat, lng for displaying the red pin for the selected address on location search page
        dispatch(
          actions.setDeliveryLocationCoordinates({
            lat: option.latitude,
            lng: option.longitude,
          })
        );
        dispatch(setUserAddress(option));
      })
      .then(() => {
        // setting lat, lng for the address for showing red pin on the store selection page
        dispatch(
          setUserEnteredDeliveryAddress({
            lat: latitude,
            lng: longitude,
          })
        );
        // Need to check if it is used.
        dispatch(
          actions.setStoreLocation({
            lat: latitude,
            lng: longitude,
          })
        );
        const payload = {
          addressLineOne: option.addressLineOne,
          addressLineTwo: option.addressLineTwo,
          city: option.city,
          zip: option.zip,
        };
        // api call for getting store details after address validation
        // This will called only for delivery
        getStoreForDelivery(payload).then((res) => {
          if (res.success) {
            const deliveryStoreDetails = [res.response];
            dispatch(actions.setDeliveryAvailableStores(deliveryStoreDetails));
            dispatch(setLocationStep("ADDRESS_DELIVERY_CARD"));
          } else {
            if (res.error?.errorCode === 400 || res.error?.apiError?.status === "NOT_FOUND") {
              dispatch(setLocationStep("DELIVERY_OUTSIDE_RADIUS"));
            }
          }
        });
      });
  };

  useEffect(() => {
    if (activeTAB) {
      dispatch(setLocationActiveTab(activeTAB));
    }
  }, [activeTAB, dispatch]);

  return {
    searchOption,
    handleClearIcon,
    handleGPSIconClick,
    handleChange,
    handleToggle,
    address,
    inputRef,
    handleKeyDownSearch,
    smartySuggestion,
    getStoresList,
    errorMessageFlag,
    isAlternativeFlow,
    storeList,
    showLocationEnableModal,
    noResult,
    variant,
    activeTAB,
    tabOptions,
    selectActive,
    isAuthenticated,
    userCurrentLocation,
    hasFaveOrRecentStores,
    isZipSearch,
    hasEnteredText,
  };
};

export default useSearchCard;
