import AddressForm from "components/global/AddressForm/AddressForm";
import { AddressModalStyled } from "./AddressModalStyled";
import { Values } from "./types";

interface Props {
  title: string;
  description: string;
  defaultValues: Values;
  modalName: "CONFIRM_ADDRESS" | "NEW_ADDRESS";
  device?: string;
}

const AddressModal = ({ title, description, defaultValues, modalName, device }: Props) => {
  return (
    <AddressModalStyled modalName={modalName} closeModalButtonType={device === "DESKTOP" ? "CLOSE" : "BACK"} className="addressModal">
      <AddressForm title={title} description={description} defaultValues={defaultValues} modalName={modalName} className="addressForm" />
    </AddressModalStyled>
  );
};

export default AddressModal;
