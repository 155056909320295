import styled from "styled-components";

export const ViewOrdersModalStyled = styled.div`
  .modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: ${(props) => props.theme.color.core.white};
    overflow-y: scroll;
    scrollbar-width: 0;
    padding-bottom: 40px;
    .modalContent {
      display: flex;
      flex-direction: column;
      justify-content: start;
      width: 100%;
      height: 100%;
      font-weight: 400;
      .header {
        padding: 0 48px;
        .headerContainer {
          display: flex;
          align-items: baseline;
          justify-content: center;
          padding-top: 104px;
        }
      }
      .hrModal {
        width: 100%;
        margin: 40px 0 0 0;
      }
    }
    .modalHeading {
      font-family: ${(props) => props.theme.font.scriptRegular};
      font-size: 27px;
      text-align: start;
      margin-left: 18px;
      margin-bottom: 20px;
      padding-top: 70px;
      font-weight: 400;
    }

    .smallBottomGap {
      margin-bottom: 20px;
    }

    .storeDetails {
      margin-bottom: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: ${(props) => props.theme.color.core.white};
      color: ${(props) => props.theme.color.primary.foundersBlue};
      padding: 0 48px;
      margin-top: 40px;
      .address {
        max-width: 166px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .imhere {
        width: 105px;
        height: 36px;
      }
    }

    .modalAccordion {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      padding: 0 48px;
      .summaryTop {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 3.2px 8px;
        background-color: ${(props) => props.theme.color.primary.foundersBlue};

        .item-name {
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          text-transform: none;
          color: ${(props) => props.theme.color.core.white};
        }
      }
    }
  }

  .modalCloseButton {
    position: absolute;
    right: -24px;
    top: 24px;
  }
  .orderAccordionBody {
    padding: 36px 20px 28px !important;
    margin-bottom: 16px;
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    display: flex;
    justify-content: space-between;
    .reorderTitle {
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
      width: 86%;
      text-transform: uppercase;
      span {
        font-family: ${(props) => props.theme.font.barlow};
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
      }
    }
    .orderId {
      margin: 10px 0;
      font-family: ${(props) => props.theme.font.sansRegular};
      font-size: 14px;
      color: ${(props) => props.theme.color.secondary.coolGray7};
    }
    .dropdownToggle {
      bottom: 28px;
    }
  }
  .orderAccordionDropdown {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    padding: 24px 20px;
    margin-top: -12px;
    margin-bottom: 16px;
    .orderId {
      margin-bottom: 24px;
    }
    .dropDownItem {
      margin-bottom: 4px;
    }
    .priceBlue {
      font-family: ${(props) => props.theme.font.serifBold};
      color: ${(props) => props.theme.color.core.blue};
      font-size: 20px;
      font-weight: 800;
    }
  }
`;
