import { PHONE_FORMAT } from "constants/regexPatterns";

export const cleanPhone = (value: string) => {
  return value.replace(/[^0-9]/g, "").slice(0, 10);
};

export const transformPhone = (value: string) => {
  return value.replace(/[^0-9]/g, "").slice(0, 10);
};

export const transformDashPhone = (value: string) => {
  return value.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};

export const cleanAmount = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const cleanName = (value: string) => {
  return value.replace(/^[^ A-Za-z0-9_@./!#&+-`~$%^*()=":;',.<>[{}]/, "").replace(/[^ A-Za-z0-9_@./!#&+-`~$%^*()=":;',.<>[{}\s]*$/, "");
};

export const cleanNameAlphanumeric = (value: string) => {
  return value.replace(/^[^A-Za-z0-9]/, "").replace(/[^A-Za-z0-9\s]*$/, "");
};

export const cleanEmail = (value: string) => {
  return value.replace(/\s/, "");
};

export const cleanPaymentCard = (value: string) => {
  return value.replace(/[^0-9]/g, "").slice(0, 16);
};

export const cleanAmexCard = (value: string) => {
  return value.replace(/[^\d]/g, "").slice(0, 15);
};

export const cleanExpirationDate = (value: string) => {
  return value.replace(/[^0-9]/g, "").slice(0, 4);
};

export const cleanZipCode = (value: string) => {
  return value.replace(/[^0-9]/g, "").slice(0, 5);
};

export const cleanCVV = (value: string) => {
  return value.replace(/[^0-9]/g, "").slice(0, 3);
};

export const cleanTipAmount = (value: string) => {
  return value.replace(/[^0-9.]/g, "").slice(0, 6);
};

export const cleanGiftCardNumber = (value: string) => {
  return value.replace(/[^0-9]/g, "").slice(0, 16);
};

export const cleanGiftCardPin = (value: string) => {
  return value.replace(/\D/g, "").slice(0, 6);
};
export const cleanVehicleValue = (value: string) => {
  return value.replace(/[^A-Za-z0-9]/g, "").slice(0, 20);
};
export const urlTrim = (value: string) => {
  const trimmedValue = value?.replace(/[^0-9a-zA-Z ]/g, "");
  return trimmedValue?.replace(/ +/g, "-").toLowerCase();
};

export const cleanState = (value: string) => {
  return value.replace(/[^A-Za-z]/gi, "").slice(0, 2);
};

export const cleanFranchisePhone = (value: string) => {
  return value.replace(/[^0-9]/g, "").slice(0, 15);
};

export const phoneNumberFormat = (phoneNumber: string) => {
  let cleaned = "";
  if (phoneNumber.length > 10) {
    cleaned = ("" + phoneNumber).replace(/\D/g, "").slice(-10);
  } else {
    cleaned = ("" + phoneNumber).replace(/\D/g, "");
  }
  const match = cleaned.match(PHONE_FORMAT);
  if (match) {
    cleaned = "" + match[1] + "-" + match[2] + "-" + match[3];
  }
  return cleaned;
};

export const splitPhoneNumber = (phoneNumber: string, isLegalPage?: boolean) => {
  phoneNumber = phoneNumber.toString();

  const part1 = phoneNumber.slice(0, 3);
  const part2 = phoneNumber.slice(3, 6);
  const part3 = phoneNumber.slice(6);

  let result = isLegalPage ? "(" + part1 + ")" : part1;
  if (part2) {
    result += " - " + part2;
  }
  if (part3) {
    result += " - " + part3;
  }

  return result;
};

export const accountPhoneNumberFormat = (phoneNumber: string) => {
  let cleaned = "";
  const match = cleaned.match(PHONE_FORMAT);
  if (match) {
    cleaned = "" + match[1] + "-" + match[2] + "-" + match[3];
  } else {
    cleaned = splitPhoneNumber(phoneNumber);
  }
  return cleaned;
};

export const communicationPhoneFormat = (phoneNumber: string = "") => {
  const phone = phoneNumber?.toString();

  const part1 = phone.slice(0, 3);
  const part2 = phone.slice(3, 6);
  const part3 = phone.slice(6);

  return "(" + part1 + ") " + part2 + "-" + part3;
};

export const convertTextInToSnakeCase = (text: string) => {
  if (!text) return "";
  return text?.toLowerCase()?.replaceAll("_", "-");
};
