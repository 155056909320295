import styled from "styled-components";
import { responsive } from "theme/responsive";

export const ForgotPasswordUpdatedStyled = styled.div`
  @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
    .text {
      font-size: 14px;
      line-height: 16.87px;
      font-family: ${(props) => props.theme.font.serifMedium};
      margin-bottom: 30px;
    }
    .btnWrapper {
      width: auto;
    }
    .heading {
      margin-bottom: 10px;
    }
  }

  ${responsive.max.lg(`
    .btnWrapper {
      gap: 10px;
    }
  `)}
`;
