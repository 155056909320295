import styled from "styled-components";

export const ProfileVerifyEnterCodeStyled = styled.div`
  .customModal {
    padding: 50px;
    background: ${(props) => props.theme.color.secondary.lightBlue};
    border-radius: 40px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .customModal {
      padding: 20px;
      border-radius: 0;

      > div:first-child {
        width: 100%;
      }

      .enterYourCodeBackButton {
        align-self: flex-start;
        margin-bottom: 20px;
        position: static;
      }
    }
  }
`;
