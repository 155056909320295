import { FooterLogoContainer, CopyrightNotice } from "./FooterLogoStyled";

interface Props {
  copyrightText: string;
}

const FooterLogo = ({ copyrightText }: Props) => {
  // TODO tests, remove conditionals after figure it out how to make them pass
  // snapshots fail for this
  const firstCharacter = copyrightText?.[0];
  const restOfTheCopyRightText = copyrightText?.slice(1);

  return (
    <FooterLogoContainer>
      <CopyrightNotice>
        <span style={{ fontWeight: 400 }}>{firstCharacter}</span> {restOfTheCopyRightText}
      </CopyrightNotice>
    </FooterLogoContainer>
  );
};

export default FooterLogo;
