import styled from "styled-components";

export const LocationTabContainerStyled = styled.div`
  align-self: stretch;
  flex-grow: 1;
  border-radius: 0 0 40px 40px;
  position: relative;
  .tabsOptions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 32px;
    border-bottom: 1px solid #bbbcbc;
  }

  .tabsUnderline {
    width: 100%;
    background: white;
    height: 3px;
    border-radius: 20px;
    margin-bottom: 40px;
  }

  .cardText {
    color: white;

    &:not(:last-child) {
      margin-top: 25px;
    }
  }

  ${(props) => props.theme.responsive?.max.lg} {
    flex-grow: 0;
    .tabsOptions {
      width: 100%;
    }
  }
`;

export const TabOption = styled.p<{ isSelected: boolean }>`
  font-family: ${(props) => props.theme.font.barlow};
  position: relative;
  cursor: pointer;
  text-align: center;
  color: ${(props) => props.theme.color.primary.foundersBlue};
  font-size: 16px;
  font-weight: ${(props) => (props.isSelected ? "700" : "500")};
  width: 100%;

  &::after {
    position: ${(props) => props.isSelected && "absolute"};
    bottom: ${(props) => props.isSelected && "-7px"};
    height: ${(props) => props.isSelected && "6px"};
    width: ${(props) => props.isSelected && "100%"};
    background: ${(props) => props.isSelected && props.theme.color.primary.foundersBlue};
    content: "";
    left: ${(props) => props.isSelected && "0"};
  }
`;
