import { ButtonProps } from "../types";
import { SecondaryButtonStyled } from "./SecondaryButtonStyled";

const SecondaryButton = ({ variant, children, ...rest }: ButtonProps) => {
  return (
    <SecondaryButtonStyled variant={variant} {...rest}>
      {children}
    </SecondaryButtonStyled>
  );
};

export default SecondaryButton;
