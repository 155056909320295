import styled from "styled-components";
import Link from "components/global/Link/Link";

const ActionLink = styled(Link)`
  color: ${(props) => props.theme.color.core.red};
  font-family: ${(props) => props.theme.font.sansRegular};
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-decoration: underline;
  margin: 0 auto;
  padding-bottom: 1px;
  display: ${(props) => (props.display ? props.display : "block")};
`;

export default ActionLink;
