import styled from "styled-components";

export const VerifyStyled = styled.div`
  .cardBody {
    padding-top: 50px !important;
  }

  .encryptedPhoneNumber {
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 20px;
    font-weight: 700;
  }

  ${(props) => props.theme.responsive.max.lg} {
    .cardBody {
      padding-top: 20px !important;
    }
  }
`;
