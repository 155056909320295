import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { HorizontalLine } from "components/atomic-components/atoms/HorizontalLineStyled/HorizontalLineStyled";
import { ViewOrdersAccordionDropdownStyled } from "./ViewOrdersAccordionDropdownStyled";

interface Props {
  className?: string;
  isCostAvailable: boolean;
  items?: any;
  total: any;
  orderId: string;
}

const ViewOrdersAccordionDropdown = ({ className, items, total, orderId }: Props) => {
  const dropdownOrderId = (
    <div className="orderId">
      Order <span>{orderId}</span>
    </div>
  );

  const dropdownItems = (
    <>
      <div className="dropDownItem">
        {items?.map((product: any) => (
          <Paragraph variant="primaryParagraph2" mobileFontSize={14} mobileFontWeight={600} className="itemName" key={product.name}>
            {product.name}
          </Paragraph>
        ))}
      </div>
      <HorizontalLine className="hr" />
    </>
  );

  return (
    <ViewOrdersAccordionDropdownStyled className={className}>
      {dropdownOrderId}
      {dropdownItems}
      <div className="priceContainer">
        <Paragraph variant="paragraphHeading1" mobileFontSize={16} mobileFontWeight={800}>
          Total:
        </Paragraph>
        <Paragraph variant="paragraphHeading1" mobileFontSize={16} mobileFontWeight={800}>
          ${total}
        </Paragraph>
      </div>
    </ViewOrdersAccordionDropdownStyled>
  );
};

export default ViewOrdersAccordionDropdown;
