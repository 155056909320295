import styled from "styled-components";
import { WHITE_NAME_LOGO_TM, NAME_LOGO_WITH_CHICKEN } from "assets/images";
export const FooterLogoContainer = styled.div`
  text-align: center;
  grid-column: span 3;
  &::before {
    content: "";
    background: url(${NAME_LOGO_WITH_CHICKEN.src});
    width: 144px;
    height: 118px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      background: url(${WHITE_NAME_LOGO_TM.src});
      height: 47px;
      width: 193px;
      margin-top: 33px;
    }
  }
`;

export const CopyrightNotice = styled.p`
  font-family: ${(props) => props.theme.font.barlowSemiCondensed};
  margin-top: 25px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 13px;
  color: ${(props) => props.theme.color.primary.foundersBlue};

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    font-family: ${(props) => props.theme.font.barlow};
    margin-top: 32px;
    margin-bottom: 0px;
    font-weight: 400;
  }
`;
