import dayjs from "dayjs";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ANALYTICS_CONST } from "constants/analytics";
import { computeStraightLineDistanceInMiles } from "utils/compute";
import { setSelectedStore, setUserLocation, setFromUserSavedCard } from "redux/reducer/Store/actions";
import LocationCardTile from "components/atomic-components/molecules/LocationCardTile/LocationCardTile";
import { RootState, StoreHours } from "src/types/types";
import { urlTrim } from "utils/cleaning";
import Analytics from "analytics/Analytics";
import { setLocationStep } from "redux/reducer/Location/LocationAction";
import { setDoubleCheckDateTimeButtonClicked } from "redux/reducer/UserStatus/UserStatusAction";
import { AddressSelectedCardBodyStyled } from "./AddressSelectedCardBodyStyled";
interface Props {
  backButton?: ReactNode;
  isFavourite?: boolean;
  className?: string;
  isNearByCard?: boolean;
  storeData?: any;
  isUserAddress?: boolean;
  onUpdateFavoriteStore?: () => void;
  disableFavourite?: boolean;
  isDeliveryAddress?: boolean;
}

const AddressSelectedCardBody = ({
  isFavourite = false,
  backButton,
  className,
  isNearByCard,
  storeData,
  isUserAddress,
  onUpdateFavoriteStore,
  disableFavourite = false,
  isDeliveryAddress = false,
}: Props) => {
  const dispatch = useDispatch();
  const { userAddress, details, tokens, orderType, userLocation, fromUserSavedCard, fromStorePage, isAlternativeFlow, step } = useSelector(
    (state: RootState) => ({
      userAddress: state.address?.userAddress,
      details: state.user.details,
      tokens: state.user.tokens,
      orderType: state.location.orderType,
      userLocation: state?.store?.userLocation,
      fromUserSavedCard: state?.store?.fromUserSavedCard,
      fromStorePage: state.store?.fromStorePage,
      isAlternativeFlow: state.location.isAlternativeFlow,
      step: state.location.step,
    })
  );

  const router = useRouter();

  const [distance, setDistance] = useState<number>(0);

  const userLocationLat = userLocation?.lat?.toString();
  const userLocationLng = userLocation?.lng?.toString();

  const handleStoreRequest = () => {
    // Added store detail call
    Analytics.getInstance().logEvent(ANALYTICS_CONST.STORE.STORE_DETAIL_CALL);
    dispatch(setUserLocation({}));

    orderType === "PICKUP" &&
      router.push(
        {
          pathname: "/locations/[state]/[city]/[address]",
          query: { storeId: storeData?.storeId },
        },

        `/locations/${urlTrim(storeData.state)}/${urlTrim(storeData.city)}/${urlTrim(storeData.address)}`
      );
  };

  const selectedLocation = () => {
    dispatch(setSelectedStore(storeData?.storeId));
    dispatch(setLocationStep("ADDRESS_PICKUP"));
    dispatch(setDoubleCheckDateTimeButtonClicked(false));
    // Added select_store event
    Analytics.getInstance().logEvent(ANALYTICS_CONST.STORE.SELECT_STORE, { ecommerce: { store_id: storeData?.corporateStoreid } });
  };

  useEffect(() => {
    if (userLocationLat && userLocationLng && storeData?.latitude && storeData?.longitude) {
      const x = {
        lat: parseFloat(userLocationLat),
        lng: parseFloat(userLocationLng),
      };

      const y = {
        lat: parseFloat(storeData.latitude),
        lng: parseFloat(storeData.longitude),
      };

      const miles = computeStraightLineDistanceInMiles(x, y);
      const floor = Math.floor(miles);
      setDistance(floor);
    }

    return () => {
      dispatch(setFromUserSavedCard(false));
    };
  }, [userLocationLat, userLocationLng, storeData]);

  const getDistance = () => {
    switch (true) {
      case fromUserSavedCard && !fromStorePage && !isUserAddress:
        return distance;
      case !isUserAddress && storeData && !fromUserSavedCard:
        return storeData.distance ?? distance;
      case isUserAddress && (storeData?.addressType || storeData?.company):
        return storeData.addressType ?? storeData?.company;
      default:
        return "";
    }
  };

  const getStoreTimingInRangeFormat = (storeDetails: any) => {
    return storeData?.storeHours?.map(
      (item: StoreHours) =>
        item.type === "business" &&
        item.ranges?.map((time, i) => {
          return `${dayjs(time.opensAt).format("hh:mmA")}-${dayjs(time.closeAt).format("hh:mmA")} Today`;
        })
    );
  };

  return (
    <AddressSelectedCardBodyStyled className={className}>
      {backButton}
      <LocationCardTile
        {...{
          distance: getDistance(),
          address: isUserAddress
            ? userAddress
            : { ...storeData, addressLineOne: storeData?.address, time: getStoreTimingInRangeFormat(storeData) },
          showAddressTime: !isUserAddress,
          showFavouriteIcon: Boolean(!isAlternativeFlow && details && tokens && step !== "DELIVERY_OUTSIDE_RADIUS"),
          showSelectButton: isNearByCard,
          handleToggleFavourite: onUpdateFavoriteStore,
          isFavourite,
          handleSelect: selectedLocation,
          handleStoreDetails: handleStoreRequest,
        }}
        isDeliveryAddress={isDeliveryAddress}
      />
    </AddressSelectedCardBodyStyled>
  );
};

export default AddressSelectedCardBody;
