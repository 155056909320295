import styled from "styled-components";

export const OrderAccordionStyled = styled.div<{ isDropdownExpanded: boolean }>`
  .orderAccordionBody,
  .orderAccordionDropdown {
    width: 100%;
    .orderId {
      color: ${(props) => props.theme.color.primary.foundersBlue};
    }
  }

  .orderAccordionBody {
    position: relative;
    background: white;
    margin-bottom: 20px;

    .dropdownToggle {
      position: absolute;
      bottom: 20px;
      right: 20px;
      cursor: pointer;
      span {
        transform: ${(props) => (props.isDropdownExpanded ? "rotate(90deg)" : "rotate(270deg)")};
        transition: transform 0.3s ease;
      }
    }
  }
`;
