import Image from "next/image";
import { IC_MINUS_BLACK, IC_PLUS_BLACK } from "assets/images";
import { ZoomButtonsStyled } from "./ZoomButtonsStyled";
interface Props {
  onZoomIn: () => void;
  onZoomOut: () => void;
  className?: string;
}

const ZoomButtons = ({ onZoomIn, onZoomOut, className }: Props) => {
  return (
    <ZoomButtonsStyled className={className}>
      <div className="zoomButton" onClick={onZoomIn}>
        <Image src={IC_PLUS_BLACK} alt="Zoom in button" />
      </div>
      <div className="zoomButton" onClick={onZoomOut}>
        <Image src={IC_MINUS_BLACK} alt="Zoom out button" />
      </div>
    </ZoomButtonsStyled>
  );
};

export default ZoomButtons;
