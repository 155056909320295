import { useState, useEffect, FormEvent } from "react";
import validator from "validator";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";
import { useLogin } from "hooks/auth/login/useLogin";
import { updateWebTokenExpiryTime } from "src/apis/generateTimeBasedTokenForWeb";
import { ANALYTICS_CONST } from "constants/analytics";
import { setPickupOrderType } from "redux/reducer/Store/actions";
import { setVehicleList } from "redux/reducer/user/actions";
import Button from "components/atomic-components/atoms/button/Button";
import Checkbox from "components/atomic-components/atoms/form-elements/Checkbox/Checkbox";
import TextField from "components/atomic-components/atoms/form-elements/TextField/TextField";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import SubHeadings from "components/atomic-components/atoms/typography/SubHeadings/SubHeadings";
import GenericError from "components/atomic-components/atoms/GenericError/GenericError";
import CurveModal from "components/atomic-components/molecules/Modals/CurveModal/CurveModal";
import useModal from "hooks/useModal";
import { useMParticle } from "hooks/useMParticle";
import { GenericErrorResponse, RootState } from "src/types/types";
import {
  deleteLocalStorageData,
  setLocalStorageData,
  setSessionStorageData,
  getSessionStorageData,
  deleteSessionStorageData,
} from "utils/storage";
import {
  setIsPasswordResetLimitExceeded,
  setMigratedUserInfo,
  setPresentUserStatus,
  setRememberStatus,
} from "redux/reducer/UserStatus/UserStatusAction";
import Analytics from "analytics/Analytics";
import { EXP_SIGN_UP_EVENT } from "constants/AmplitudeExperimentConstants";
import {
  MIGRATED_USER_PASSWORD_RESET_REQUIRED_CODE,
  USER_EXCEEDED_PASSWORD_RESET_LIMIT_ERROR_CODE,
  USER_NOT_CONFIRMED_CODE,
} from "constants/errorCodeConstants";
import { FORBIDDEN_ERROR_MESSAGE } from "constants/constant";
import {
  Body,
  Inputs,
  ActionButtonPadding,
  Footer,
  TermLinks,
  TermLink,
  LoginButtonPadding,
  Header,
  WelcomeModalStyled,
} from "./LoginStyled";

const Login = () => {
  const dispatch = useDispatch();
  const { signinLoginMessage, pickupOrderType } = useSelector((state: RootState) => ({
    signinLoginMessage: state.cms.signinLoginMessage,
    pickupOrderType: state.store?.pickupOrderType,
  }));
  const [serverError, setServerError] = useState("");
  const [isForbidden, setIsForbidden] = useState(false);
  const router = useRouter();

  const { modal, setModal } = useModal();
  const { values, getInputProps, handleVerify, handleSubmit, isLoading } = useLogin();
  const { mParticleLoginIdentity } = useMParticle();

  const referralCode = sessionStorage.getItem("referralCode");

  useEffect(() => {
    deleteLocalStorageData("isUserMigrated");
  }, []);

  const handleGoToForgotPassword = () => {
    // Added amplitude login_forgot_my_password event
    Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.LOGIN_FORGOT_MY_PASSWORD);
    setModal("FORGOT_PASSWORD");
  };

  const handleGoToVerify = () => setModal("VERIFY");
  const handleCloseModal = (buttonClicked?: boolean | undefined) => {
    if (referralCode) {
      sessionStorage.removeItem("referralCode");
    }
    if ((router?.pathname === "/" || router?.pathname === "/home") && "page" in router?.query) {
      router.replace("/");
    }
    if (buttonClicked) {
      deleteSessionStorageData("accountPageRedirectUrl");
    }
    setModal("");
  };

  const handleGoToSignup = () => {
    setModal("SIGNUP");
    Analytics.getInstance().getExperiment().variant(EXP_SIGN_UP_EVENT);

    Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.SIGN_UP_STARTED, {
      placement: "login screen",
    });
  };

  const handleError = (err: GenericErrorResponse) => {
    if (err?.message === "Validation Error") {
      return;
    }
    setServerError(err?.apiError?.message ?? "WE COULDN’T VERIFY YOUR INFORMATION. PLEASE TRY AGAIN.");
    if (err?.errorCode === 403) {
      setIsForbidden(true);
      setServerError(FORBIDDEN_ERROR_MESSAGE);
    } else {
      setIsForbidden(false);
    }
  };

  const handleLogin = (e: FormEvent) => {
    e.preventDefault();

    // Added amplitude signin_started event
    Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.SIGN_IN_STARTED, { placement: "login screen" });

    const mparticleLoginCallback = () => {
      const userIdentities = {
        email: values.username,
      };
      mParticleLoginIdentity(userIdentities);
    };
    // While logging in if there is guestUserDetails key in the session storage delete it
    const lastSavedGuestUserDetails = getSessionStorageData("guestUserDetails");
    if (lastSavedGuestUserDetails) {
      deleteSessionStorageData("guestUserDetails");
    }

    handleSubmit(mparticleLoginCallback)
      .then((res: any) => {
        if (res.authenticationResult) {
          const responseCred = res.authenticationResult;
          const stringCred = JSON.stringify(responseCred);
          if (res?.values?.remember) {
            setLocalStorageData("tokens", stringCred);
          } else {
            setSessionStorageData("tokens", stringCred);
          }

          // set token expiry time in localStorage
          updateWebTokenExpiryTime(responseCred.expiresIn);

          if (!pickupOrderType) {
            dispatch(setPickupOrderType("instore"));
          }

          dispatch(setRememberStatus(Boolean(res?.values?.remember)));
          dispatch(setPresentUserStatus("RETURNING")); // delete after complete integration

          setLocalStorageData(
            "userDetails",
            JSON.stringify({
              firstName: res.profileResponse.firstName,
              lastName: res.profileResponse.lastName,
              phone: res.profileResponse.phone,
              email: res.profileResponse.email,
            })
          );

          // Added amplitude user id
          Analytics.getInstance().setUserId(res.profileResponse.cdpId.toUpperCase());

          // Added amplitude login_success event
          Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.LOGIN_SUCCESS);

          // Added user context in datadog
          // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#identify-user-sessions
          datadogRum.setUser({
            id: res.profileResponse.cdpId,
            name: `${res.profileResponse.firstName} ${res.profileResponse.lastName}`,
            email: res.profileResponse.email,
          });

          handleCloseModal();

          // If user signin from logout page, then redirect it to the home page.
          dispatch(setVehicleList([]));
          const redirectUrl = getSessionStorageData("redirectUrl");
          const accountPageRedirectUrl = getSessionStorageData("accountPageRedirectUrl");
          if (referralCode) {
            router.push("/account/invite-friends");
            sessionStorage.removeItem("referralCode");
          } else if (redirectUrl) {
            router.push(redirectUrl);
            deleteSessionStorageData("redirectUrl");
          } else if (accountPageRedirectUrl) {
            router.push(accountPageRedirectUrl);
            deleteSessionStorageData("accountPageRedirectUrl");
          } else if (router?.pathname.includes("/account/logged-out")) {
            // If user signin from logout page, then redirect it to the home page.
            router.push("/");
          }
        } else if (res.error) {
          // Added amplitude login_failure event

          Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.LOGIN_FAILURE);

          if (res.error.apiError.code === USER_EXCEEDED_PASSWORD_RESET_LIMIT_ERROR_CODE) {
            dispatch(setIsPasswordResetLimitExceeded(true));
            handleGoToForgotPassword();
          } else if (res.error.apiError?.code === MIGRATED_USER_PASSWORD_RESET_REQUIRED_CODE) {
            dispatch(setMigratedUserInfo({ errorCode: MIGRATED_USER_PASSWORD_RESET_REQUIRED_CODE, username: res.values.username }));
            handleGoToForgotPassword();
            setLocalStorageData("isUserMigrated", "Yes");
          } else if (res.error.apiError?.code === USER_NOT_CONFIRMED_CODE) {
            return handleVerify().then(handleGoToVerify);
          }

          // @ts-ignore
          handleError(res.error); // api error type will be update in next release of common module
        }
      })
      .catch((err: GenericErrorResponse) => {
        handleError(err);
      });
  };

  const errorHideShow = function (error: string, forbidden: boolean) {
    if (!error && !forbidden) {
      return null;
    } else if (error && forbidden) {
      return (
        <GenericError className="errorStyle" fontSize={11} fontWeight={"700"} textAlign={"center"} variant="loginError">
          {serverError}
        </GenericError>
      );
    } else if (error && !forbidden) {
      return (
        <GenericError className="errorStyle" errorMargin={true} fontSize={11} fontWeight={"700"} textAlign={"center"} variant="loginError">
          {serverError}
        </GenericError>
      );
    }
  };

  const BODY = (
    <Body>
      <form onSubmit={handleLogin}>
        <Header>
          <Headings variant="primaryHeading1" fontSize={34} fontWeight={700} className="welcomeBackTitle">
            WELCOME!
          </Headings>
          <SubHeadings variant="primarySubHeading2" fontSize={24} fontWeight={800} className="welcomeAuthSubTitle">
            {signinLoginMessage?.Login.Subtitle}
          </SubHeadings>
        </Header>
        <Inputs
          onChange={() => {
            setServerError("");
          }}
        >
          <TextField label="Email" {...getInputProps("username")} maxLength={250} className="textfieldSpacing" autoFocus />
          <TextField label="Password" type="password" {...getInputProps("password")} maxLength={50} className="textfieldSpacing" />

          <Checkbox variant="primaryBlueFilled" {...getInputProps<boolean>("remember")} className="checkbox">
            Keep me Logged In
          </Checkbox>
          {errorHideShow(serverError, isForbidden)}
        </Inputs>
        <LoginButtonPadding>
          <Button
            className="button-wrapper"
            type="submit"
            variant="primaryBlue"
            disabled={!validator.isEmail(serverError || values.username) || !values.username || !values.password || isLoading}
          >
            Log in
          </Button>
        </LoginButtonPadding>

        <ActionButtonPadding>
          <Button type="button" variant="ghostBlue" className="forgotPassword" onClick={handleGoToForgotPassword} underline>
            FORGOT MY PASSWORD
          </Button>
        </ActionButtonPadding>
      </form>
    </Body>
  );

  const FOOTER = (
    <Footer>
      <SubHeadings variant="primarySubHeading2" fontSize={24} fontWeight={800} className="white-color">
        Not part of the Zaxbys Fam?
      </SubHeadings>
      <Button className="button-wrapper" type="button" variant="primaryPureWhite" onClick={handleGoToSignup}>
        Sign up
      </Button>
      <TermLinks>
        <TermLink to="/legal/privacy-policy" target="_blank">
          Privacy Policy
        </TermLink>
        ,{" "}
        <TermLink to="/legal/rewards-policy" target="_blank">
          Rewards Policy
        </TermLink>
        , <br />
        <TermLink to="/legal/terms-of-use" target="_blank">
          Terms Of Use
        </TermLink>{" "}
      </TermLinks>
    </Footer>
  );

  return (
    <WelcomeModalStyled>
      <CurveModal
        isOpen={modal === "LOGIN"}
        body={BODY}
        footer={FOOTER}
        onClose={() => handleCloseModal(true)}
        showCloseButton
        mobileVariant="fullScreen"
      />
    </WelcomeModalStyled>
  );
};

export default Login;
