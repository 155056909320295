import styled from "styled-components";

export const FooterOrderInfo = styled.h3<{ variant: "BLUE" | "RED" }>`
  font-family: ${(props) => props.theme.font.sansRegular};
  font-size: 16px;
  line-height: 19px;

  color: ${(props) => (props.variant === "BLUE" ? props.theme.color.core.blue : props.theme.color.core.red)};

  &:last-of-type {
    margin-bottom: 25px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    &:last-of-type {
      margin-bottom: 15px;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    font-size: 14px;
  }
`;
