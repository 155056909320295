import styled from "styled-components";
import { responsive } from "theme/responsive";

export const GenericSuccessMessage = styled.div`
  color: ${(props) => props.theme.color.limitedUse.leafyGreen};
  font-family: ${(props) => props.theme.font.barlow};
  font-size: 16px;
  font-weight: 800;
  line-height: normal;
  text-align: center;
  margin: 10px auto 0;
  padding-bottom: 1px;
  display: block;
  text-transform: uppercase;
  ${responsive.max.sm(`
      font-weight: 700;
     `)}
`;
