import React, { ReactNode } from "react";
import AuthCard from "components/Auth/AuthCard/AuthCard";
import Modal from "components/global/Modal/Modal";
import { PromptModalStyled } from "./PromptModalStyled";
interface Props {
  onClose: () => void;
  body: ReactNode;
  footer?: ReactNode;
  isCloseRequired?: boolean;
  isButtonTextVisible?: boolean;
  isOpen: boolean;
  className?: string;
}
const PromptModal = ({ isOpen, onClose, body, footer, isButtonTextVisible = true, isCloseRequired = true, className }: Props) => {
  return (
    <PromptModalStyled>
      <Modal isOpen={isOpen} className="modal">
        <div className={`selectStoreModalContainer ${className}`}>
          <AuthCard
            className="authCardStoreModal"
            body={body}
            footer={footer}
            isCloseRequired={isCloseRequired}
            onClose={() => onClose()}
            isButtonTextVisible={isButtonTextVisible}
          />
        </div>
      </Modal>
    </PromptModalStyled>
  );
};

export default PromptModal;
