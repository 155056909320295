import { PropsWithChildren } from "react";
import { isUrlExternal } from "utils/helper";
import { LinkContainerStyled, LinkStyled } from "./LinkStyled";

interface Props extends PropsWithChildren {
  ariaLabel?: string;
  to: string;
  passRef?: boolean;
  className?: string;
  [key: string]: any;
  onClick?: Function;
  target?: string;
}

const Link = ({ ariaLabel, children, to, target, passHref = true, className = "", onClick, ...rest }: Props) => {
  // Solution team asked that all external URLS open in a new tab
  const isLinkExternal = isUrlExternal(to);
  const anchorTarget = isLinkExternal ? "_blank" : "";
  return (
    <LinkContainerStyled>
      <LinkStyled href={to} passHref={passHref} {...rest}>
        <a className={className} target={target || anchorTarget} aria-label={ariaLabel} onClick={() => onClick && onClick()}>
          {children}
        </a>
      </LinkStyled>
    </LinkContainerStyled>
  );
};

export default Link;
