import styled from "styled-components";
import { IMG_TEXTURED_BLOCK_YELLOW } from "assets/images";

export const AddressSelectedCardBodyStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .backButton {
    align-self: flex-start;
  }

  .address-wrapper {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    .addressPointer {
      cursor: pointer;
    }
  }

  .addressLine {
    font-family: ${(props) => props.theme.font.serifHeavy};
    color: ${(props) => props.theme.color.core.blue};
    font-size: 14px;
    text-decoration: underline;
    align-self: flex-start;
    margin-bottom: 4px;
    &.storeTimings {
      margin-top: 12px;
      text-decoration: none;
      font-size: 14px;
      font-family: ${(props) => props.theme.font.serifBold};
      .day {
        font-family: ${(props) => props.theme.font.serifMedium};
      }
    }
    &.large {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
    }
  }
  .address-select-cta {
    margin-left: auto;
  }
  .mileIndicator {
    align-self: flex-start;
    background: url(${IMG_TEXTURED_BLOCK_YELLOW.src});
    padding: 4px;
    color: ${(props) => props.theme.color.core.blue};
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: ${(props) => props.theme.font.sansRough};
    word-break: break-word;
    background-size: cover;
    margin-bottom: 3px;
    span {
      font-size: 24px;
      font-family: inherit;
      color: inherit;
      position: relative;
      bottom: 2px;
    }
  }
`;
