import { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { ANALYTICS_CONST } from "constants/analytics";
import Analytics from "analytics/Analytics";
import { GMapsContext } from "context/GMapsContext";

const getCleanedAddress = (place: any) => {
  const shouldBeComponent = {
    home: ["street_number"],
    postal_code: ["postal_code"],
    street: ["street_address", "route"],
    region: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    city: ["locality", "sublocality", "sublocality_level_1", "sublocality_level_2", "sublocality_level_3", "sublocality_level_4"],
    country: ["country"],
  };

  const cleanedAddress = {
    home: "",
    postal_code: "",
    street: "",
    region: "",
    city: "",
    country: "",
  };

  // Need to check
  type ShouldBeComponent = typeof shouldBeComponent;

  place.address_components?.forEach((component: any) => {
    for (const shouldBe in shouldBeComponent) {
      if (shouldBeComponent[shouldBe as keyof ShouldBeComponent].indexOf(component.types[0]) !== -1) {
        if (shouldBe === "country") {
          cleanedAddress[shouldBe as keyof ShouldBeComponent] = component.short_name;
        } else {
          cleanedAddress[shouldBe as keyof ShouldBeComponent] = component.long_name;
        }
      }
    }
  });

  return cleanedAddress;
};

const OPTIONS = {
  types: ["(regions)"],
  componentRestrictions: { country: "us" },
  sessionToken: true,
};

// This will return result from google autosuggest api
const useLocationSearch = (inputRef: MutableRefObject<HTMLInputElement | null>, enabled: boolean) => {
  const { isGmapScriptLoaded } = useContext(GMapsContext);
  const autocompleteRef = useRef<google.maps.places.Autocomplete>();
  const eventRef = useRef<google.maps.MapsEventListener>();
  const [address, setAddress] = useState({
    addressName: "",
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    state: "",
    zip: "",
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    if (
      !isGmapScriptLoaded ||
      typeof document === "undefined" ||
      typeof window.google?.maps?.places.Autocomplete !== "function" ||
      !inputRef.current ||
      !enabled
    ) {
      return;
    }

    autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, OPTIONS);

    return () => {
      // explicitly null the object so it is gc and not dangling in memory
      autocompleteRef.current = undefined;
    };
  }, [inputRef.current, isGmapScriptLoaded]);

  useEffect(() => {
    if (!autocompleteRef.current || eventRef.current) {
      return;
    }

    autocompleteRef.current.setFields(["geometry", "name", "formatted_address", "address_component", "place_id"]);
    eventRef.current = autocompleteRef.current.addListener("place_changed", () => {
      // Added search_store event
      Analytics.getInstance().logEvent(ANALYTICS_CONST.STORE.SEARCH_STORE);
      const place = (autocompleteRef.current as google.maps.places.Autocomplete).getPlace();
      const cleanedAddress = getCleanedAddress(place);
      const streetAddress = cleanedAddress.home + " " + cleanedAddress.street;
      const locationName = place.name || "";
      const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const doesLocationNameHaveStreetAddress = digits.includes(locationName.charAt(0));
      setAddress({
        addressName: place?.formatted_address || "",
        addressLineOne: doesLocationNameHaveStreetAddress ? streetAddress : locationName,
        addressLineTwo: doesLocationNameHaveStreetAddress ? "" : streetAddress,
        city: cleanedAddress.city,
        state: cleanedAddress.region,
        zip: cleanedAddress.postal_code,
        latitude: place?.geometry?.location?.lat() || 0,
        longitude: place?.geometry?.location?.lng() || 0,
      });
    });

    return () => {
      if (!eventRef.current) {
        return;
      }

      // Remove the event listener than remove the event reference to refresh on remount
      eventRef.current.remove();
      eventRef.current = undefined;
    };
  }, [autocompleteRef.current]);

  return address;
};

export default useLocationSearch;
