import styled from "styled-components";
import { ButtonProps } from "../types";

const BaseButtonStyled = styled.button<ButtonProps>`
  width: 246px;
  text-align: center;
  padding: 0;
  height: 44px;
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
  font-family: ${(props) => props.theme.font?.barlow};
  border-radius: 38px;
  text-transform: uppercase;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.color?.primary.tongueTorchRed};
  }
  @media only screen and (max-width: ${(props) => props.theme.size?.sm}) {
    width: 115px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    height: 36px;
  }

  &:active {
    opacity: 0.6;
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.3;
    cursor: not-allowed;
  `}
`;

export default BaseButtonStyled;
