import styled, { css } from "styled-components";

export interface ParagraphProps {
  variant:
    | "paragraphHeading1"
    | "paragraphHeading2"
    | "primaryParagraph1"
    | "primaryParagraph2"
    | "secondaryParagraph1"
    | "secondaryParagraph2"
    | "paragraphSubHeading1"
    | "paragraphLogistics1"
    | "tileDescription"
    | "secondaryParagraph3"
    | "secondaryParagraph4"
    | "productPrice"
    | string;
  fontSize?: number;
  fontWeight?: number;
  mobileFontWeight?: number;
  mobileFontSize?: number;
  className?: string;
  onClick?: (e?: any) => void;
}

const paragraphHeading1 = css<ParagraphProps>`
  line-height: 24px;
  text-transform: uppercase;
  font-size: ${({ fontSize }) => fontSize ?? 20}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 16}px;
    font-weight: ${({ mobileFontWeight }) => mobileFontWeight ?? 700};
    line-height: 19px;
  }
`;

const primaryParagraph1 = css<ParagraphProps>`
  line-height: 19px;
  font-size: ${({ fontSize }) => fontSize ?? 16}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${(props) => props.mobileFontSize ?? 14}px;
    font-weight: ${(props) => props.mobileFontWeight ?? 400};
    line-height: 19px;
  }
`;

const primaryParagraph2 = css<ParagraphProps>`
  font-size: ${({ fontSize }) => fontSize ?? 16}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 14}px;
    font-weight: ${({ mobileFontWeight }) => mobileFontWeight ?? 500};
  }
`;

const paragraphHeading2 = css<ParagraphProps>`
  text-transform: uppercase;
  font-size: ${({ fontSize }) => fontSize ?? 14}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 12}px;
  }
`;

const secondaryParagraph1 = css<ParagraphProps>`
  line-height: 17px;
  font-size: ${({ fontSize }) => fontSize ?? 14}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 12}px;
    font-weight: ${({ mobileFontWeight }) => mobileFontWeight ?? 400};
  }
`;

const secondaryParagraph2 = css<ParagraphProps>`
  line-height: normal;
  font-size: ${({ fontSize }) => fontSize ?? 14}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 12}px;
    font-weight: ${({ mobileFontWeight }) => mobileFontWeight ?? 500};
  }
`;

const paragraphSubHeading1 = css<ParagraphProps>`
  line-height: 17px;
  font-size: ${({ fontSize }) => fontSize ?? 14}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    line-height: 14px;
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 12}px;
  }
`;

const paragraphLogistics1 = css<ParagraphProps>`
  line-height: 19px;
  font-size: ${({ fontSize }) => fontSize ?? 16}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    line-height: 16.8px;
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 12}px;
    font-weight: ${({ mobileFontWeight }) => mobileFontWeight ?? 500};
  }
`;

const tileDescription = css<ParagraphProps>`
  line-height: 26px;
  font-size: ${({ fontSize }) => fontSize ?? 22}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  color: ${(props) => props.theme.color?.core.white};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 16}px;
    font-weight: 500;
  }
`;

const secondaryParagraph3 = css<ParagraphProps>`
  line-height: 21.6px;
  font-size: ${({ fontSize }) => fontSize ?? 18}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${(props) => props.theme.color?.core.black};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 14}px;
    line-height: 16.8px;
    font-weight: ${({ mobileFontWeight }) => mobileFontWeight ?? 500};
  }
`;

const secondaryParagraph4 = css<ParagraphProps>`
  line-height: 16.8px;
  font-size: ${({ fontSize }) => fontSize ?? 14}px;
  font-family: ${(props) => props.theme.font?.barlowSemiCondensed};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 12}px;
    line-height: 16.8px;
    font-weight: ${({ mobileFontWeight }) => mobileFontWeight ?? 500};
  }
`;

const productPrice = css<ParagraphProps>`
  font-family: ${(props) => props.theme.font?.barlow};
  font-size: 14px;
  font-weight: 500;
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 13px;
    line-height: 15.6px;
  }
`;

export const ParagraphStyled = styled.p<ParagraphProps>`
  font-style: normal;
  ${(props) =>
    ({
      paragraphHeading1,
      primaryParagraph1,
      primaryParagraph2,
      paragraphHeading2,
      secondaryParagraph1,
      secondaryParagraph2,
      paragraphSubHeading1,
      paragraphLogistics1,
      tileDescription,
      secondaryParagraph3,
      secondaryParagraph4,
      productPrice,
    }[props.variant])}
`;
