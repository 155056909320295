import mParticle from "@mparticle/web-sdk";
// @ts-ignore
import mixpanelKit from "@mparticle/web-mixpanel-kit";
import DataDogAnalytics from "analytics/Datadog";
import { AnalyticsListener } from "./AnalyticsListener";
import { mParticleEventsList } from "./MparticleEventsList";

export default class MparticleEventListener implements AnalyticsListener {
  init() {
    try {
      const apiKey = process.env.NEXT_PUBLIC_MPARTICLE_API_KEY;
      const mParticleConfig: any = {
        // (optional) `appName and appVersion are used to associate with your web app
        // and are included in all event uploads
        isDevelopmentMode: process.env.NEXT_PUBLIC_APP_ENV !== "prod",
        logLevel: process.env.NEXT_PUBLIC_APP_ENV !== "prod" ? "verbose" : "none",
        // zaxbys CDP url's
        v1SecureServiceUrl: process.env.NEXT_PUBLIC_MPARTICLE_V1_SECURE_SERVICE_URL,
        v2SecureServiceUrl: process.env.NEXT_PUBLIC_MPARTICLE_V2_SECURE_SERVICE_URL,
        v3SecureServiceUrl: process.env.NEXT_PUBLIC_MPARTICLE_V3_SECURE_SERVICE_URL,
        configUrl: process.env.NEXT_PUBLIC_MPARTICLE_CONFIG_URL,
        identityUrl: process.env.NEXT_PUBLIC_MPARTICLE_IDENTITY_URL,
        aliasUrl: process.env.NEXT_PUBLIC_MPARTICLE_ALIAS_URL,
        // This callback will be called when mParticle successfully initializes
        // and will return any known User Identities from mParticle.
        // You can then synchronize this user data with any services that are
        // unique to your application.
        identityCallback: (result: any) => {
          if (result.getUser()) {
            // User has been identified
            // proceed with any custom logic that requires a valid, identified user

            const user = result.getUser();
            const { userIdentities } = user.getUserIdentities();

            // For demonstration purposes, we are printing out the known values for a user
            // to the console. An example of a use case for this callback might be to sync
            // the identity of a user with your own authentication logic
            Object.keys(userIdentities).forEach((identity) => {
              console.error("User Identity Value: ", identity, userIdentities[identity as keyof mParticle.UserIdentities]);
            });
          } else {
            // the IDSync call failed
          }
        },
      };

      if (apiKey) {
        mixpanelKit.register(mParticleConfig);
        mParticle.init(apiKey, mParticleConfig);
      } else {
        console.error("Please add your mParticle API Key");
      }
    } catch (error: any) {
      DataDogAnalytics.getInstance().datadogAddError(error, { errorFrom: "Amplitude Init" });
    }
  }

  logEvent(eventName: string, properties?: Record<string, unknown>, isMparticle: boolean = true) {
    try {
      if (isMparticle) {
        const convertedProperties = this.convertObjectValuesInStringFormat(properties);
        if (mParticleEventsList?.includes(eventName)) {
          mParticle.logEvent(eventName, mParticle.EventType.Other, convertedProperties);
        }
      }
    } catch (error: any) {
      DataDogAnalytics.getInstance().datadogAddError(error, { errorFrom: "mParticle logEvent" });
    }
  }

  // Convert the key values to a string format for the given object
  public convertObjectValuesInStringFormat = (properties: any) => {
    const propertiesObject = properties ? Object.entries(properties) : [];
    if (propertiesObject.length === 0) {
      return {};
    }

    let convertedProperties = {};
    for (const [key, value] of propertiesObject) {
      convertedProperties = { ...convertedProperties, [key]: typeof value !== "string" ? JSON.stringify(value) : value };
    }

    return convertedProperties;
  };
}
