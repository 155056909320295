import { FACEBOOK_URL, GOOGLE_BUSINESS_URL, INSTAGRAM_URL, TIK_TOK_URL, TWITTER_URL, YOUTUBE_URL } from "constants/constant";
import { SocialMediaIconStyled } from "./SocialIconStyled";

interface Props {
  icon: "twitter" | "instagram" | "facebook" | "youtube" | "googleBusiness" | "tiktok";
  url?: string;
  mobileStyle: "DEFAULT" | "ALTERNATE";
  [key: string]: any;
}

const SocialMediaIcon = ({ icon, url, mobileStyle, ...rest }: Props) => {
  const socialMediaUrls = {
    twitter: TWITTER_URL,
    facebook: FACEBOOK_URL,
    instagram: INSTAGRAM_URL,
    youtube: YOUTUBE_URL,
    googleBusiness: GOOGLE_BUSINESS_URL,
    tiktok: TIK_TOK_URL,
  };

  return (
    <SocialMediaIconStyled
      mobileStyle={mobileStyle}
      aria-label={`${icon} icon`}
      icon={icon}
      href={url || socialMediaUrls[icon]}
      rel="noreferrer"
      target="_blank"
      {...rest}
    />
  );
};

export default SocialMediaIcon;
