/* stylelint-disable no-descending-specificity */
import styled from "styled-components";
import Link from "components/global/Link/Link";
import { responsive } from "theme/responsive";

export const WelcomeModalStyled = styled.div`
  display: flex;
  align-items: center;
  .modalClose {
    position: absolute;
    top: 20px;
  }
  .modal-style {
    width: 632px;
  }
  .modal {
    overflow-y: auto;
  }
  .stackedButton {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 16px;
  }

  .input-wrapper {
    margin-bottom: 0;
  }
  .forgotPasswordOverlay {
    top: 97px;
    height: calc(100vh - 85px);
  }

  .cardBody {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    padding: 30px 30px 36px 30px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .login {
    overflow: hidden;
    background: linear-gradient(
      to bottom,
      ${(props) => props.theme.color.primary.coleslawWhite} 64%,
      ${(props) => props.theme.color.primary.foundersBlue}
    );
    ${(props) => props.theme.responsive?.min.md} {
      border-radius: 20px;
    }
  }
  .curve {
    margin-top: -50px;
    margin-bottom: 27px;
    ${(props) => props.theme.responsive?.max.md} {
      margin-top: -30px;
      margin-bottom: 26px;
    }
  }

  .errorStyle {
    margin: 0;
  }

  ${(props) => props.theme.responsive?.min.lg} {
    .cardBody {
      padding: 24px 24px 36px 24px;
      margin-bottom: 20px;
    }
    .modal {
      height: 100%;
      border-radius: 20px;
      width: 70%;
      margin-top: 23%;
    }
  }
  ${(props) => props.theme.responsive?.max.md} {
    .cardBody {
      padding-top: 72;
      margin-bottom: 20px;
    }
    .modal {
      height: 100%;
      border-radius: 20px;
      width: 70%;
      margin-top: 23%;
    }
  }
  .cardFooter {
    background: ${(props) => props.theme.color.primary.foundersBlue};
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    transform: translate(0px, -30px);
    margin-bottom: -30px;
    padding: 20px 30px;
  }
  .authCardClose {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    top: 40px;
    right: 28px;
    padding: 0;
    ${(props) => props.theme.responsive?.min.md} {
      top: 28px;
    }
    ${(props) => props.theme.responsive?.max.sm} {
      top: 50px;
    }
  }

  ${(props) => props.theme.responsive?.max.lg} {
    .cardBody {
      padding: 76px 24px 36px 24px;
    }
    .modal {
      height: 100%;
      border-radius: 20px;
      width: 70%;
      margin-top: 23%;
    }

    .authCardClose {
      background: ${(props) => props.theme.color.primary.coleslawWhite};
      top: 32px;
      right: 28px;
      padding: 0;
    }

    .cardFooter {
      background: ${(props) => props.theme.color.primary.foundersBlue};
      padding: 55px 0;
      width: 100%;
      transform: translate(0, -30px);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;
export const Body = styled.div`
  grid-gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  .textInputGroupClass {
    .errorRed {
      color: ${(props) => props.theme.color.primary.tongueTorchRed};
      padding-bottom: -10px;
    }
  }

  ${(props) => props.theme.responsive?.max.sm} {
    width: 100%;
    padding: 4px;
  }

  ${(props) => props.theme.responsive?.min.lg} {
    display: grid;
    width: 386px;
    padding: 0;
    > form {
      width: inherit;
    }
  }

  .checkbox {
    margin-bottom: 40px;
    font-weight: 700;
    line-height: normal;
  }

  .welcomeAuthSubTitle {
    margin-bottom: 20px;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    .welcomeBackTitle {
      margin: 30px 0 -20px;
    }
    .white-button {
      background-color: ${(props) => props.theme.color.limitedUse.pureWhite};
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .welcomeBackTitle {
    margin-top: 30px;
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
  }
  .welcomeAuthSubTitle {
    margin-bottom: 25px;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    .welcomeBackTitle {
      margin: 20px 0 0;
      color: ${(props) => props.theme.color.primary.tongueTorchRed};
    }
  }
`;

export const BoldText = styled.span`
  font-family: ${(props) => props.theme.font.barlow};
`;
export const Inputs = styled.div`
  display: grid;
  width: 100%;
  gap: 16px;
  :last-child {
    gap: 40px;
  }
  & > *:nth-last-child(2) {
    &.invalid {
      margin-bottom: 15px;
    }
  }
  .checkbox-wrapper {
    margin-bottom: 0;
  }
  .input-checkbox {
    height: 18px;
    width: 20px;
  }

  .checkbox {
    .checkbox-label {
      font-family: ${(props) => props.theme.font.barlow};
      color: ${(props) => props.theme.color.primary.foundersBlue};
      padding-left: 35px;
      ${(props) => props.theme.responsive?.min.lg} {
        text-transform: uppercase;
      }
      font-size: 16px;
      ${(props) => props.theme.responsive?.max.md} {
        font-weight: 500;
      }
    }
  }
  ${(props) => props.theme.responsive?.max.lg} {
    .input-wrapper {
      margin-bottom: 5px;
    }
    .checkbox {
      .checkbox-label {
        font-size: 12px;
        padding-left: 25px;
      }
    }
  }
`;

export const ForgotPasswordButton = styled.span`
  font-size: 14px;
  font-family: ${(props) => props.theme.font.barlowCondensed};
  font-weight: 900;
  text-decoration: underline;
  align-self: center;
  cursor: pointer;
  display: inline-block;
  ${(props) => props.theme.responsive?.min.xs} {
    width: 240px;
    margin: 20px 0 0;
  }
  ${(props) => props.theme.responsive?.max.md} {
    font-size: 14px;
    font-weight: 700;
    width: 240px;
    margin: 25px 0;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 25px;

  .button-wrapper {
    height: 44px;
    width: 188px;
  }

  .white-color {
    color: ${(props) => props.theme.color.limitedUse.pureWhite};
    ${(props) => props.theme.responsive?.max.sm} {
      width: 225px;
      text-align: center;
    }
  }
  .cardFooter {
    background: ${(props) => props.theme.color.primary.foundersBlue};
    border-top-right-radius: 50% 30px;
    border-top-left-radius: 50% 30px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 106%;
    transform: translate(-12px, 0);
    margin-bottom: -1px;
  }
  ${(props) => props.theme.responsive?.max.lg} {
    .cardBody {
      padding: 24px 24px 36px 24px;
    }
    .modal {
      height: auto;
      border-radius: 20px;
      width: 70%;
      margin-top: 23%;
    }

    .title {
      margin-top: 10px;
      font-size: 24px;
      line-height: 29px;
    }
    .locationString {
      font-size: 21px;
    }

    .subHeading {
      margin: 0;
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 500;
    }

    .stackedButton {
      flex-direction: column;
      align-items: center;
    }
    .cardFooter {
      background: ${(props) => props.theme.color.primary.foundersBlue};
      padding: 30px 50px;
      width: 106%;
      transform: translate(-6px, 0);
    }
  }
`;

export const TermLinks = styled.div`
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => props.theme.color.primary.coleslawWhite};
  margin-bottom: 20px;
  > span {
    font-family: ${(props) => props.theme.font.barlow};
    font-weight: 700;
    font-size: 16px;
    ${(props) => props.theme.responsive?.max.md} {
      font-size: 12px;
    }
    > a {
      text-decoration: underline;
    }
  }
  ${(props) => props.theme.responsive?.max.lg} {
    margin-top: -10px;
  }
`;

export const TermLink = styled(Link)`
  font-weight: 700;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.font.barlow};
  color: ${(props) => props.theme.color.primary.coleslawWhite};
  line-height: normal;
  ${(props) => props.theme.responsive?.max.sm} {
    font-size: 12px;
  }
  ${(props) => props.theme.responsive?.min.lg} {
    font-size: 16px;
  }
`;

export const LoginButtonPadding = styled.div`
  display: flex;
  justify-content: center;
  .button-wrapper {
    width: 188px;
    height: 44px;
    margin-top: 40px;
  }
  align-items: center;
  ${(props) => props.theme.responsive?.min.lg} {
    gap: 40px;
    padding: 0 0 10px 0;
  }
  ${(props) => props.theme.responsive?.max.md} {
    .button-wrapper {
      font-size: 14px;
      font-weight: 700;
      width: 148px;
      height: 36px;
      margin-top: 32px;
    }
  }
`;

export const ActionButtonPadding = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 0;
  margin: 10px 0 30px;
  .button-wrapper {
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-family: ${(props) => props.theme.font.barlow};
    ${responsive.max.md(`
   
    width: 240px;
    font-size: 14px;

  `)}
  }
  ${responsive.min.md(`
  margin: 5px 0 0px;
  .button-wrapper {
    font-size: 14px;
    font-weight: 700;
    color: ${(props: any) => props.theme.color.primary.foundersBlue};
    font-family: ${(props: any) => props.theme.font.barlow};
  }
  `)}
`;
