import { ReactNode } from "react";
import { ParagraphStyled } from "./ParagraphStyled";

interface Props {
  children: ReactNode;
}

const ParagraphOverride = ({ children }: Props) => {
  return (
    <ParagraphStyled variant="secondaryParagraph1" fontWeight={400} className="paragraphOveride">
      {children}
    </ParagraphStyled>
  );
};

export default ParagraphOverride;
