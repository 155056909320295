import styled, { css } from "styled-components";
import BaseButton from "../BaseButton/BaseButton";
import { ButtonProps } from "../types";

const first = css`
  color: white;
  background: ${(props) => props.theme.color.core.blue};
  border: 2px solid transparent;
`;

const second = css`
  background: white;
  color: ${(props) => props.theme.color.core.blue};
  border: 2px solid ${(props) => props.theme.color.core.blue};
`;

const third = css`
  ${second}
  border: 2px solid transparent;
`;

const fourth = css`
  ${first}
  background: ${(props) => props.theme.color.secondary.red};
`;

const fifth = css`
  color: ${(props) => props.theme.color.core.blue};
  background: ${(props) => props.theme.color.secondary.orange};
  border: 2px solid transparent;
`;

const sixth = css`
  color: black;
  background: none;
  border: none;
`;

const seventh = css`
  background: white;
  border: 2px solid transparent;
  color: ${(props) => props.theme.color.core.red};
`;

const eighth = css`
  color: white;
  background: ${(props) => props.theme.color.core.red};
  border: 2px solid transparent;
`;

const ninth = css`
  color: ${(props) => props.theme.color.core.white};
  background: ${(props) => props.theme.color.core.red};
  border: 2px solid ${(props) => props.theme.color.core.white};
`;

const tenth = css`
  color: ${(props) => props.theme.color.core.white};
  background-color: ${(props) => props.theme.color.limitedUse.darkRed3};
  border: 2px solid ${(props) => props.theme.color.core.white};
  padding: 8px;
`;

const eleventh = css`
  color: ${(props) => props.theme.color.core.white};
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.color.core.white};
  padding: 8px;
`;

export const PrimaryButtonStyled = styled(BaseButton)<ButtonProps>`
  ${(props) => ({ first, second, third, fourth, fifth, sixth, seventh, eighth, ninth, tenth, eleventh }[props.variant])}
`;
