import { css } from "styled-components";
import { IMG_TEXTURED_BKGD_LIGHT_BLUE_XL, IMG_TEXTURED_BKGD_LIGHT_BLUE_XL_REVERSED } from "assets/images";

export const mobileComponent = css`
  @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
    display: none;
  }
`;

export const desktopComponent = css`
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    display: none;
  }
`;

export const texturedBlueBackground = css`
  mask-image: url(${IMG_TEXTURED_BKGD_LIGHT_BLUE_XL_REVERSED.src}), url(${IMG_TEXTURED_BKGD_LIGHT_BLUE_XL.src}),
    linear-gradient(white, white);
  mask-repeat: no-repeat;
  mask-size: 100% auto, 100% auto, 100% calc(100% - 30px);
  mask-position: top center, bottom center, center center;
  background: ${(props) => props.theme.color.secondary.lightBlue};
  background-size: 100% auto, 100% auto, auto;
`;
