import mParticle from "@mparticle/web-sdk";
import { setSessionStorageData } from "utils/storage";

// For more user identities see https://docs.mparticle.com/developers/sdk/web/idsync/ in the bottom of the page
type userIdentitiesType = {
  email?: string;
  // eslint-disable-next-line camelcase
  mobile_number?: string;
};

type userAttributesType = { $firstname?: string; $lastname?: string; $mobile?: string };

const convertGuestUserKeysToMparticleformat = (guestUserData: any) => {
  // eslint-disable-next-line camelcase
  const userIdentities: userIdentitiesType = {};

  const userAttributes: userAttributesType = {};

  if (guestUserData?.email) {
    userIdentities.email = guestUserData.email;
  }

  if (guestUserData?.firstName) {
    userAttributes.$firstname = guestUserData?.firstName;
  }

  if (guestUserData?.lastName) {
    userAttributes.$lastname = guestUserData?.lastName;
  }

  return {
    userIdentities,
    userAttributes,
  };
};

export const useMParticle = () => {
  const getIdentityRequestAndCallback = (
    userIdentities: userIdentitiesType,
    userAttributes: userAttributesType,
    isLoginIdentityAPI: boolean
  ) => {
    const identityRequest = {
      userIdentities,
    };

    const identityCallback = (result: any) => {
      const currentUserAttributes = result.getUser().getAllUserAttributes();
      const currentUserIdentities = result.getUser().getUserIdentities().userIdentities;

      if (result?.body?.ErrorCode) {
        // If the API result is not success throws validation error then login the user with user identities
        if (result?.body?.ErrorCode === "VALIDATION_ERROR") {
          mParticle.Identity.login(
            {
              userIdentities: {
                email: userIdentities?.email ?? currentUserIdentities.email,
              },
            },
            // call recursive function for the login API
            identityCallback
          );
        }

        return null;
      }

      if (isLoginIdentityAPI) {
        setSessionStorageData("isMparticleLoginEnabled", JSON.stringify(true));
        if (result.getPreviousUser()?.getAllUserAttributes()) {
          // Copy attributes from previous user to current user
          result.getUser().setUserAttributes(result.getPreviousUser().getAllUserAttributes());
        }

        const previousUser = result.getPreviousUser();

        // If the user is anonymous, create and send the alias request
        if (previousUser && Object.keys(previousUser.getUserIdentities().userIdentities).length === 0) {
          const aliasRequest = mParticle.Identity.createAliasRequest(result.getPreviousUser(), result.getUser());
          mParticle.Identity.aliasUsers(aliasRequest);
        }
      }

      if (Object.keys(userAttributes).length > 0) {
        result.getUser().setUserAttributes(userAttributes);
      }

      // update guest user details to the session storage
      setSessionStorageData(
        "guestUserDetails",
        JSON.stringify({
          firstName: userAttributes?.$firstname ?? currentUserAttributes.$firstname,
          lastName: userAttributes?.$lastname ?? currentUserAttributes.$lastname,
          email: userIdentities?.email ?? currentUserIdentities.email,
        })
      );
    };

    return {
      identityRequest,
      identityCallback,
    };
  };

  const mParticleLoginIdentity = (guestUserDetails: any) => {
    const { userIdentities, userAttributes } = convertGuestUserKeysToMparticleformat(guestUserDetails);
    const { identityRequest, identityCallback } = getIdentityRequestAndCallback(userIdentities, userAttributes, true);

    if (Object.keys(userIdentities ?? {}).length > 0) {
      mParticle.Identity.login(identityRequest, identityCallback);
    }
  };

  const mParticleModifyIdentity = (guestUserDetails: any) => {
    const { userIdentities, userAttributes } = convertGuestUserKeysToMparticleformat(guestUserDetails);

    const { identityRequest, identityCallback } = getIdentityRequestAndCallback(userIdentities, userAttributes, false);

    if (Object.keys(userIdentities ?? {}).length > 0) {
      mParticle.Identity.modify(identityRequest, identityCallback);
    } else if (Object.keys(userAttributes ?? {}).length > 0) {
      const currentUser = mParticle.Identity.getCurrentUser();

      currentUser.setUserAttributes(userAttributes);
    }
  };

  return { mParticleLoginIdentity, mParticleModifyIdentity };
};
