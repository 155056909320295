import {
  SET_FRANCHISE_ABOUT_US,
  SET_FRANCHISE_AWARDS,
  SET_FRANCHISE_FAQ,
  SET_FRANCHISE_FOOTER_DATA,
  SET_FRANCHISE_GET_STARTED,
  SET_FRANCHISE_GET_STARTED_MESSAGE,
  SET_FRANCHISE_HOME_DATA,
  SET_FRANCHISE_INVESTMENT,
  SET_FRANCHISE_LOCATIONS,
  SET_FRANCHISE_PROCESS,
  SET_WHY_ZAXBYS,
  SET_FRANCHISE_LEADERSHIP,
  SET_FRANCHISE_TESTIMONIALS,
  SET_FRANCHISE_PROFILE,
  SET_FRANCHISE_TRAINING,
  SET_FRANCHISE_BLOGS,
  SET_FRANCHISE_GET_STARTED_FORM_LOADING,
  SET_FRANCHISE_BLOGS_URLS,
} from "./FranchiseConst";

const initialState = {
  getStartedMessage: {
    message: "Success!",
    description: "We will contact you within the next 48 hours",
  },
  isFranchiseFooterLoaded: false,
  franchiseFooter: null,
  isFranchiseHomeLoaded: false,
  franchiseHome: null,
  isFranchiseAboutUsLoaded: false,
  franchiseAboutUs: null,
  isFranchiseGetStartedLoaded: false,
  franchiseGetStarted: null,
  franchiseFAQ: [],
  isFranchiseFAQLoaded: false,
  isWhyZaxbysLoaded: false,
  whyZaxbysPage: null,
  isInvestmentLoaded: false,
  franchiseInvestment: null,
  isFranchiseProcessLoaded: false,
  franchiseProcess: null,
  isFranchiseLocationLoaded: false,
  franchiseLocation: null,
  isFranchiseAwardsLoaded: false,
  franchiseAwards: null,
  isFranchiseLeadershipLoaded: false,
  franchiseLeadership: null,
  isFranchiseTestimonialLoaded: false,
  franchiseTestimonials: null,
  isFranchiseProfileLoaded: false,
  franchiseProfile: null,
  franchiseTraining: null,
  isFranchiseTrainingLoaded: false,
  franchiseBlogs: null,
  isFranchiseBlogsLoaded: false,
  isFormLoading: false,
  frachiseBlogURLs: [],
};

export default function FranchiseReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case SET_FRANCHISE_GET_STARTED_MESSAGE:
      return { ...state, getStartedMessage: action.payload };
    case SET_FRANCHISE_FOOTER_DATA:
      return {
        ...state,
        franchiseFooter: action.payload,
        isFranchiseFooterLoaded: true,
      };

    case SET_FRANCHISE_HOME_DATA:
      return {
        ...state,
        franchiseHome: action.payload,
        isFranchiseHomeLoaded: true,
      };

    case SET_FRANCHISE_ABOUT_US:
      return {
        ...state,
        franchiseAboutUs: action.payload,
        isFranchiseAboutUsLoaded: true,
      };

    case SET_FRANCHISE_GET_STARTED:
      return {
        ...state,
        franchiseGetStarted: action.payload,
        isFranchiseGetStartedLoaded: true,
      };

    case SET_FRANCHISE_FAQ:
      return {
        ...state,
        franchiseFAQ: action.payload,
        isFranchiseFAQLoaded: true,
      };

    case SET_WHY_ZAXBYS:
      return {
        ...state,
        whyZaxbysPage: action.payload,
        isWhyZaxbysLoaded: true,
      };

    case SET_FRANCHISE_INVESTMENT:
      return {
        ...state,
        franchiseInvestment: action.payload,
        isInvestmentLoaded: true,
      };

    case SET_FRANCHISE_PROCESS:
      return {
        ...state,
        franchiseProcess: action.payload,
        isFranchiseProcessLoaded: true,
      };

    case SET_FRANCHISE_LOCATIONS:
      return {
        ...state,
        franchiseLocation: action.payload,
        isFranchiseLocationLoaded: true,
      };

    case SET_FRANCHISE_AWARDS:
      return {
        ...state,
        franchiseAwards: action.payload,
        isFranchiseAwardsLoaded: true,
      };

    case SET_FRANCHISE_LEADERSHIP:
      return {
        ...state,
        franchiseLeadership: action.payload,
        isFranchiseLeadershipLoaded: true,
      };

    case SET_FRANCHISE_TESTIMONIALS:
      return {
        ...state,
        franchiseTestimonials: action.payload,
        isFranchiseTestimonialLoaded: true,
      };

    case SET_FRANCHISE_PROFILE:
      return {
        ...state,
        franchiseProfile: action.payload,
        isFranchiseProfileLoaded: true,
      };

    case SET_FRANCHISE_TRAINING:
      return {
        ...state,
        franchiseTraining: action.payload,
        isFranchiseTrainingLoaded: true,
      };

    case SET_FRANCHISE_BLOGS:
      return {
        ...state,
        franchiseBlogs: action.payload,
        isFranchiseBlogsLoaded: true,
      };
    case SET_FRANCHISE_GET_STARTED_FORM_LOADING:
      return { ...state, isFormLoading: action.payload };
    case SET_FRANCHISE_BLOGS_URLS:
      return { ...state, frachiseBlogURLs: action.payload };
    default:
      return state;
  }
}
