import React from "react";
import Script from "next/script";
import DataDogAnalytics from "analytics/Datadog";
import Analytics from "analytics/Analytics";

export default class AnalyticsWrapper extends React.Component {
  componentDidMount() {
    Analytics.getInstance().init();
    DataDogAnalytics.getInstance().initDataDog();
  }

  render() {
    const gtmKey = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as string;
    return (
      <Script
        id="gtag-base"
        nonce="f9959a0860a72cc3"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
            n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmKey}');
          `,
        }}
      />
    );
  }
}
