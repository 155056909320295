import { BasketAction, BasketState } from "./types";

const SOMETHING_WENT_WRONG = "Something went wrong";

const initialState: BasketState = {
  error: "",
  isLoading: false,
  basket: undefined,
  tabBarHeight: 0,
  upsellItem: [],
  favBasketsList: [],
  savedFavBasket: false,
  submitOrderStatus: undefined,
  localNotes: "",
  isCheckoutScreen: false,
  errorHandOffMode: "",
  errorOrder: "",
  selectedFavId: "",
  basketStoreId: null,
  userFavoriteBasketName: "",
  faveRecentConfirmationFlag: false,
  productLengthFlag: false,
  faveDisableFlag: false,
  validateBasketApiCallFlag: false,
  setItemAddedTime: 0,
  setProductTimer: 0,
  itemsNotTransferred: [],
  createFavBasket: "",
  createFavError: false,
  deleteFavUserBasket: "",
  favModal: "",
  validateBasket: {},
  itemsNotAvailableForHandoffMode: [],
};

export const basketReducer = (state = initialState, action: BasketAction): BasketState => {
  switch (action.type) {
    case "BASKET_BEGIN_LOADING":
      return { ...state, isLoading: true };

    case "BASKET_CLOSE_LOADING":
      return { ...state, isLoading: false };

    case "BASKET_SYSTEM_ERROR":
      return { ...state, error: action.payload || SOMETHING_WENT_WRONG, isLoading: false };

    case "SET_BASKET_DETAILS":
      return { ...state, basket: action.payload || undefined };

    case "SET_UP_SELL_LIST":
      return { ...state, upsellItem: action.payload || [] };

    case "SET_TAB_BAR_HEIGHT":
      return { ...state, tabBarHeight: action.payload || 0 };

    case "REMOVE_UP_SELL_LIST": {
      const indexProduct = state.upsellItem.findIndex((item: any) => item?.productId === action.payload);
      const newUpsellItems = [...state.upsellItem];
      if (indexProduct !== -1) {
        newUpsellItems.splice(indexProduct, 1);
      }
      return {
        ...state,
        upsellItem: newUpsellItems,
      };
    }

    case "REORDER_FAV_BASKET_LIST":
      return { ...state, favBasketsList: action.payload || [] };

    case "REORDER_FAV_SET_BASKET":
      return { ...state, savedFavBasket: action.payload || false };

    case "SUBMIT_ORDER_STATUS":
      return { ...state, submitOrderStatus: action.payload || undefined };

    case "ADD_NOTES_FOR_DRIVER":
      return { ...state, localNotes: action.payload || "" };

    case "SET_IS_CHECKOUT_SCREEN":
      return { ...state, isCheckoutScreen: action.payload || false };

    case "HAND_OFF_MODE_ERROR":
      return { ...state, errorHandOffMode: action.payload || "" };

    case "ORDER_PLACE_ERROR":
      return { ...state, errorOrder: action.payload || "" };

    case "CREATE_BASKET":
      return {
        ...state,
        basket: action.payload || undefined,
      };

    case "RESET_BASKET":
      return {
        ...state,
        basket: undefined,
        upsellItem: [],
      };

    case "SET_SELECTED_FAV_ID":
      return {
        ...state,
        selectedFavId: action.payload || "",
      };

    case "SET_BASKET_STORE_ID":
      return {
        ...state,
        basketStoreId: action.payload || 0,
      };

    case "SET_USER_FAV_BASKET_NAME":
      return {
        ...state,
        userFavoriteBasketName: action.payload || "",
      };

    case "FAVE_RECENT_CONFIRMATION_FLAG":
      return {
        ...state,
        faveRecentConfirmationFlag: action.payload || false,
      };

    case "SET_FLAG_ON_PRODUCT_LENGTH":
      return {
        ...state,
        productLengthFlag: action.payload || false,
      };

    case "SET_FAVE_DISABLE_FLAG":
      return {
        ...state,
        faveDisableFlag: action.payload || false,
      };

    case "SET_VALIDATE_BASKET_API_CALL_FLAG":
      return {
        ...state,
        validateBasketApiCallFlag: action.payload || false,
      };

    case "SET_TIMER":
      return {
        ...state,
        setItemAddedTime: action.payload || 0,
      };

    case "SET_PRODUCT_TIMER":
      return {
        ...state,
        setProductTimer: action.payload || 0,
      };

    case "ITEMS_NOT_TRANSFERRED":
      return {
        ...state,
        itemsNotTransferred: action.payload || [],
      };

    case "CREATE_FAV_BASKET":
      return {
        ...state,
        createFavBasket: action.payload || "",
      };

    case "CREATE_FAV_BASKET_ERROR":
      return {
        ...state,
        createFavError: action.payload || false,
      };

    case "DELETE_USER_FAV_BASKET":
      return {
        ...state,
        deleteFavUserBasket: action.payload || "",
      };

    case "SET_FAV_MODALS":
      return {
        ...state,
        favModal: action.payload || "",
      };

    case "VALIDATE_BASKET":
      return {
        ...state,
        validateBasket: action.payload || {},
      };

    case "ITEMS_NOT_AVAILABLE_FOR_HANDOFF":
      return {
        ...state,
        itemsNotAvailableForHandoffMode: action.payload || [],
      };

    default:
      return state;
  }
};
