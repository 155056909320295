import styled from "styled-components";
import Image from "next/image";

const getRightValue = (props: any) => {
  if (props.buttonType === "eGift") {
    return props.$isReversed ? "75px" : "125px";
  } else {
    return props.$isReversed ? "0px" : "67px";
  }
};

export const CarouselButtonStyled = styled.button<{
  $isReversed?: boolean;
  isArrowWhite?: boolean;
  buttonType?: string;
}>`
  padding: ${(props) => (props.buttonType === "eGift" ? "12px" : "20px")};
  background: ${(props) => (props.isArrowWhite ? `${props.theme.color.core.blue}` : `${props.theme.color.core.white}`)};
  border: none;
  position: absolute;
  cursor: pointer;
  right: ${(props) => getRightValue(props)};
  bottom: -64px;

  @media screen and (max-width: ${(props) => props.theme.size.lg}) {
    display: none;
  }
`;

export const ArrowIcon = styled(Image)<{ $isReversed?: boolean }>`
  ${(props) => props.$isReversed && `transform: rotate(180deg)`};
  width: 20px;
  height: 20px;
`;
