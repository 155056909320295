import styled from "styled-components";
import Modal from "components/atomic-components/atoms/layout-elements/Modal/Modal";

export const CurveModalStyled = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: scroll;
  overflow-x: hidden;
  .modal-style {
    height: 100%;
    top: 100px;
  }
  .cardDetail {
    border-radius: 20px;
    overflow: hidden;
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    width: 632px;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      border-radius: 0px;
    }
    .cardBodyCurve {
      border-radius: 20px 20px 0 0;
      background: ${(props) => props.theme.color.primary.coleslawWhite};
      padding-top: 60px;
      padding-bottom: 40px;

      @media only screen and (max-width: ${(props) => props.theme.size.md}) {
        padding-top: 25px;
        padding-bottom: 0px;
        margin: auto;
      }
    }
    .cardFooterCurve {
      border-radius: 0 0 20px 20px;
      background: ${(props) => props.theme.color.primary.foundersBlue};
      border-top-right-radius: 50% 30px;
      border-top-left-radius: 50% 30px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      width: 106%;
      transform: translate(-12px, 0);
      position: relative;
      padding-top: 40px;
      padding-bottom: 40px;
      .maskWrapper {
        position: absolute;
        top: -19px;
        left: 0;
        width: 100%;
      }
    }
  }
  ${(props) => props.theme.responsive?.max.lg} {
    .modal-style {
      top: 0;
      border-radius: 0;
      overflow-x: hidden;
      width: 101vw;
    }
    .cardDetail {
      width: 100%;
      min-height: 100%;
      height: auto;
      overflow: unset;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .cardBodyCurve {
        border-radius: 0px;
      }
      .cardFooterCurve {
        border-top-right-radius: 50% 30px;
        border-top-left-radius: 50% 30px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        width: 106%;
        transform: translate(-6px, 0);
        .maskWrapper {
          position: absolute;
          top: -10px;
          width: 100%;
          background: ${(props) => props.theme.color.primary.coleslawWhite};
        }
        &:before {
          display: none;
        }
      }
    }
  }
`;
