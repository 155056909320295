import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { HEART_GREY_DESELECT, HEART_SECLECTED } from "assets/images";
import SubHeadings from "components/atomic-components/atoms/typography/SubHeadings/SubHeadings";
import Button from "components/atomic-components/atoms/button/Button";
import { getStoreDetails } from "redux/reducer/Store/actions";
import { setCareerStore } from "redux/reducer/Careers/CareersAction";
import { Address, RootState } from "src/types/types";
import {
  LocationCardTileStyled,
  MilesAndDetailsWrapper,
  AddressWrapper,
  FaveAddressWrapper,
  TotalMilesWrapper,
  AddressDetailsWrapper,
  AddressTimeWrapper,
} from "./LocationCardTileStyled";

interface Props {
  distance?: string | number;
  address: Address;
  showFavouriteIcon?: boolean;
  isFavourite?: boolean;
  isStoreDetails?: boolean;
  showSelectButton?: boolean;
  showAddressTime?: boolean;
  isDeliveryAddress?: boolean;
  handleToggleFavourite?: () => void;
  handleSelect: (address: Address) => void;
  handleStoreDetails?: () => void;
}

const LocationCardTile = ({
  distance,
  address,
  showAddressTime = false,
  showFavouriteIcon = false,
  showSelectButton = false,
  isFavourite = false,
  isDeliveryAddress = false,
  isStoreDetails = false,
  handleToggleFavourite,
  handleSelect,
  handleStoreDetails,
}: Props) => {
  const dispatch = useDispatch();
  const { device, isAlternativeFlow } = useSelector((state: RootState) => ({
    device: state?.currentDevice?.device,
    isAlternativeFlow: state.location.isAlternativeFlow,
  }));

  const isClickable = showSelectButton && device === "MOBILE";
  const hasTitle = Boolean(address?.addressType);

  const handleCardClick = () => {
    if (!isDeliveryAddress) {
      if (isAlternativeFlow) {
        dispatch(
          setCareerStore({
            storeId: address?.storeId ? Number(address?.storeId) : undefined,
            storeName: address?.storeName,
            address: address?.addressLineOne,
            city: address?.city,
            state: address?.state,
            zip: address?.zip,
            hiringUrl: address?.hiringUrl ?? "",
            hiringEmail: address?.hiringEmail,
            distance: address?.distance,
          })
        );
      } else {
        dispatch(getStoreDetails(String(address?.storeId), true));
      }
    }
    handleSelect(address);
  };

  return (
    <LocationCardTileStyled
      className="locationCardTile"
      isSelectable={isClickable}
      onClick={() => isClickable && handleCardClick()}
      hasTitle={hasTitle}
    >
      <MilesAndDetailsWrapper className="milesAndDetails">
        {!isDeliveryAddress && (
          <TotalMilesWrapper className="totalMiles">
            <Paragraph
              variant="primaryParagraph1"
              className="mileText"
              fontWeight={700}
              aria-label={`distance is ${distance}  MILE${distance !== 1 && "S"}`}
            >
              {distance} MILE{distance !== 1 && "S"}
            </Paragraph>
          </TotalMilesWrapper>
        )}
        {isStoreDetails && (
          <Paragraph variant="primaryParagraph1" className="mileText" fontWeight={700}>
            ADDRESS
          </Paragraph>
        )}

        <FaveAddressWrapper className="faveAddress">
          {!isDeliveryAddress && (
            <Paragraph
              variant="primaryParagraph1"
              className={`detailText ${!showFavouriteIcon && "noFavouriteIcon"}`}
              fontWeight={700}
              fontSize={14}
              onClick={(e: any) => {
                e.stopPropagation();
                handleStoreDetails && handleStoreDetails();
              }}
            >
              DETAILS
            </Paragraph>
          )}
          <div className={`iconWrapper ${address?.addressType === "" && "noCustomName"}`}>
            {address?.addressType && (
              <div className="addressTypeWrapper">
                <SubHeadings variant="primarySubHeading2" fontSize={20} fontWeight={600} className="addressType" isTextUpperCase={false}>
                  {address?.addressType}
                </SubHeadings>
              </div>
            )}

            {showFavouriteIcon && (
              <div
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleToggleFavourite && handleToggleFavourite();
                }}
                onKeyUp={() => {
                  // Added this function to avoid the error
                }}
                role="button"
                tabIndex={0}
                className="favIconWrapper"
              >
                <Image
                  src={isFavourite ? HEART_SECLECTED : HEART_GREY_DESELECT}
                  alt="star icon solid"
                  layout="fill"
                  aria-label={`fave icon ${isFavourite ? "click to remove store from fave list" : "click to mark store as fave"}`}
                />
              </div>
            )}
          </div>
        </FaveAddressWrapper>
      </MilesAndDetailsWrapper>
      {address?.addressType && <div className="horizontalLine" />}
      <AddressWrapper className="addressWrapper" isDeliveryAddress={isDeliveryAddress} hasTitle={hasTitle}>
        <AddressDetailsWrapper className="addressDetails">
          <SubHeadings
            variant="primarySubHeading2"
            fontSize={20}
            fontWeight={600}
            className="addressLineOne"
            isTextUpperCase={false}
            tabIndex={0}
            aria-label={`currently focused store is ${address?.addressLineOne}`}
          >
            {address?.addressLineOne}
          </SubHeadings>
          <Paragraph variant="secondaryParagraph1" fontWeight={500} fontSize={14} className="addressSub">
            {address?.city}, {address?.state} {address?.zip}
          </Paragraph>
        </AddressDetailsWrapper>
        <AddressTimeWrapper className={`addressTime ${showSelectButton ? "mt-minus-8" : ""}`}>
          {showAddressTime && (
            <Paragraph
              variant="secondaryParagraph1"
              fontWeight={500}
              fontSize={14}
              className="addressSub storeTime"
              aria-label={`store timing is ${address?.time}`}
            >
              {address?.time}
            </Paragraph>
          )}
          {showSelectButton && (
            <Button
              variant="primaryRed"
              onClick={() => !isClickable && handleCardClick()}
              className={`selectButton ${address?.addressType === "" && "noCustomName"}`}
              aria-label="click to select the store"
            >
              SELECT
            </Button>
          )}
        </AddressTimeWrapper>
      </AddressWrapper>
    </LocationCardTileStyled>
  );
};

export default LocationCardTile;
