import Markdown, { MarkdownToJSX } from "markdown-to-jsx";
import { ReactNode } from "react";
import Link from "../Link/Link";

interface CustomLinkProps {
  children: ReactNode;
  href: string;
  title: string;
}

const CustomMarkdownLink = ({ children, href, title }: CustomLinkProps) => {
  return (
    <Link to={href} title={title}>
      {children}
    </Link>
  );
};

interface MarkdownProps {
  markdown: string;
  className?: string;
  overrides?: MarkdownToJSX.Overrides;
}

const MarkdownComponent = ({ markdown, className, overrides }: MarkdownProps) => {
  return (
    <Markdown
      className={className}
      options={{
        overrides: {
          a: {
            component: CustomMarkdownLink,
          },
          ...overrides,
        },
      }}
    >
      {markdown}
    </Markdown>
  );
};

export default MarkdownComponent;
