import styled, { css } from "styled-components";
import {
  IC_CHECKBOX_BLUE_LG,
  IC_CHECKBOX_BLUE_SM,
  IC_CHECKBOX_WHITE_LG,
  IC_CHECKBOX_WHITE_SM,
  IC_CHECKBOX_DISABLED_SM,
  IC_CHECKBOX_DISABLED_LG,
} from "assets/images";

interface CheckBoxProps {
  variant: "primaryGreenFilled" | "primaryBlueOutlined" | "primaryBlueFilled" | "primaryGreenFilledCustomized" | string;
  rounded?: boolean;
}

const primaryFilledStyle = css`
  /* stylelint-disable no-descending-specificity */
  input:not(:checked) + label:after,
  input:not(:disabled):checked + label:after {
    background-image: url(${IC_CHECKBOX_WHITE_LG.src});
    background-repeat: no-repeat;
    left: 5px;
    top: 5px;
    ${(props) => props.theme.responsive?.max.lg} {
      background-image: url(${IC_CHECKBOX_WHITE_SM.src});
      left: 3px;
      top: 3px;
    }
  }
  input:not(:checked) + label:before {
    border: 2px solid ${(props) => props.theme.color?.secondary.coolGray7};
    background-color: ${(props) => props.theme.color?.limitedUse.pureWhite};
  }
  input:checked + label:before {
    background-color: ${(props) => props.theme.color?.primary.foundersBlue};
    border: none;
  }

  input:disabled:checked + label:after {
    background-image: url(${IC_CHECKBOX_WHITE_LG.src});
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    top: 5px;
    ${(props) => props.theme.responsive?.max.lg} {
      background-image: url(${IC_CHECKBOX_WHITE_SM.src});
      left: 3px;
      top: 3px;
    }
  }
`;

const primaryBlueFilled = css`
  input:checked + label:before {
    background-color: ${(props) => props.theme.color?.primary.foundersBlue};
    border: none;
  }
  input:disabled:checked + label:before {
    background-color: ${(props) => props.theme.color?.secondary.coolGray4};
  }
  ${primaryFilledStyle}
`;

const primaryGreenFilled = css`
  ${primaryFilledStyle}
  label:before {
    background-color: ${(props) => props.theme.color?.limitedUse.pureWhite};
  }
  input:checked + label:before {
    background-color: ${(props) => props.theme.color?.limitedUse.leafyGreen};
    border: none;
  }
  input:disabled:checked + label:before {
    background-color: ${(props) => props.theme.color?.secondary.coolGray4};
  }
`;

const primaryGreenFilledCustomized = css`
  ${primaryFilledStyle}
  label:before {
    background-color: ${(props) => props.theme.color?.core.white};
  }
  input:checked + label:before,
  input:disabled:checked + label:before {
    background-color: ${(props) => props.theme.color?.limitedUse.leafyGreen};
    border: none;
  }
  input:disabled:not(:checked) + label:before {
    background-color: ${(props) => props.theme.color?.core.white} !important;
  }
`;

const primaryBlueOutlined = css`
  input:not(:checked) + label:after,
  input:not(:disabled):checked + label:after {
    background-image: url(${IC_CHECKBOX_BLUE_LG.src});
    left: 5px;
    top: 5px;
    ${(props) => props.theme.responsive?.max.lg} {
      background-image: url(${IC_CHECKBOX_BLUE_SM.src});
      left: 3px;
      top: 3px;
    }
  }
  input:not(:checked) + label:before,
  input:checked + label:before {
    background-color: ${(props) => props.theme.color?.core.white};
    border: 2px solid ${(props) => props.theme.color?.primary.foundersBlue};
  }

  input:disabled:checked + label:before {
    border: 2px solid ${(props) => props.theme.color?.secondary.coolGray4};
  }
  input:disabled:checked + label:after {
    content: "";
    background-image: url(${IC_CHECKBOX_DISABLED_LG.src});
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    top: 5px;
    ${(props) => props.theme.responsive?.max.lg} {
      background-image: url(${IC_CHECKBOX_DISABLED_SM.src});
      left: 3px;
      top: 3px;
    }
  }
`;

export const CheckboxStyled = styled.div<CheckBoxProps>`
  ${(props) => ({ primaryGreenFilled, primaryBlueOutlined, primaryBlueFilled, primaryGreenFilledCustomized }[props.variant])}
  input:not(:checked),
  input:checked {
    position: absolute;
    left: -9999px;
  }
  input:not(:checked) + label,
  input:checked + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
  }

  input:not(:checked) + label:after,
  input:checked + label:after {
    content: "";
    position: absolute;
    width: 13.4px;
    height: 10.68px;
    transition: all 0.2s;
  }

  input:not(:checked) + label:before {
    background-color: ${(props) => props.theme.color?.limitedUse.pureWhite};
  }

  input:not(:checked) + label:before,
  input:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: -2px;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    outline: none;
    ${(props) =>
      props.rounded &&
      `
    border-radius: 50%;
    `}
  }
  input:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  input:disabled:not(:checked) + label:before {
    background-color: ${(props) => props.theme.color?.secondary.coolGray1};
    border: 2px solid ${(props) => props.theme.color?.secondary.coolGray4};
  }
  input:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  ${(props) => props.theme.responsive?.max.lg} {
    input:not(:checked) + label:before,
    input:checked + label:before {
      width: 18px;
      height: 18px;
    }
    input:not(:checked) + label:after,
    input:checked + label:after {
      width: 10.05px;
      height: 8.01px;
    }
  }
`;

export const StyledError = styled.div`
  color: ${(props) => props.theme.color?.primary.tongueTorchRed};
  font-family: ${(props) => props.theme.font.sourceSansPro};
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 6px;
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 10px;
    margin-top: 3px;
  }
`;
