import styled from "styled-components";

export const LocationPinStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div:first-child {
    width: 60%;
  }
`;
