import styled from "styled-components";

export const HiddenInput = styled.input`
  display: none;
`;

export const ToggleInputStyled = styled.div<{ isFromCommunication: boolean }>`
  .label {
    width: 187px;
    border-radius: 35px;
    padding: 12px 0;
    background: ${(props) => props.theme.color.secondary.coolGray1};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 44px;

    & + input:checked {
      background: blue;
    }

    & > span {
      flex: 1;
      text-align: center;
      cursor: pointer;
    }

    * {
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      position: relative;
      z-index: 1;
    }

    *:first-child {
      color: ${(props) => props.theme.color.core.white};
    }

    &::after {
      content: "";
      transition: transform 0.05s linear;
      width: 50%;
      border-radius: 35px;
      height: 100%;
      position: absolute;
      transform: translateX(-2%);
      ${({ isFromCommunication, theme }) =>
        isFromCommunication &&
        `
      height: calc(100% + 2px);
      background: ${theme.color.primary.foundersBlue};
      `}
    }
  }
  /* stylelint-disable no-descending-specificity */
  input:checked + .label {
    &::after {
      transform: translateX(100%);
    }

    *:first-child {
      color: ${(props) => props.theme.color.secondary.coolGray10};
    }

    *:last-child {
      color: ${(props) => props.theme.color.core.white};
    }
  }
  .activeOption {
    color: ${(props) => props.theme.color.secondary.coolGray10};
  }

  ${(props) => props.theme.responsive.max.lg} {
    ${({ isFromCommunication }) =>
      isFromCommunication &&
      `
    .label {
      height: 36px;
      padding: 7px 0;
      width: 152px;
    }`}
  }
`;
