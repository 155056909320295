import styled from "styled-components";

import BackButton from "components/global/Buttons/BackButton/BackButton";
import CloseButton from "components/global/Buttons/CloseButton/CloseButton";
interface CardCloseButtonProps {
  isCloseRequiredInMobile: boolean;
}

export const CardCloseButton = styled(CloseButton)<CardCloseButtonProps>`
  &.authCardClose {
    > div {
      width: 14px;
      height: 15px;
    }
  }
  position: absolute;
  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    top: 28px;
    display: ${(props: CardCloseButtonProps) => (props.isCloseRequiredInMobile ? "grid" : "none")};
    right: 20px;
    position: relative;
    margin-left: auto;
  }

  @media only screen and (min-width: ${(props) => props.theme.size.md}) {
    top: 30px;
    right: 30px;
    display: flex;
    position: absolute;
  }
`;
export const Card = styled.div<{
  isCardMobile: boolean;
  isBackVisible: boolean;
  isSignUp: boolean;
  isAccountCreateModal: boolean;
}>`
  height: ${(props) => !props.isSignUp && "100%"};
  width: 100%;
  position: relative;
  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    height: ${(props) => (props.isAccountCreateModal ? "auto" : "100vh")};
  }
  background: ${(props) => props.theme.color.primary.coleslawWhite};
  border-radius: ${(props) => (props.isCardMobile ? "20px" : "0")};
  margin: ${(props) => props.isCardMobile && "20px"};

  .back-close-button-wrapper {
    display: flex;
  }

  .cardBody {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }
  }
  .curve {
    margin-top: -50px;
    margin-bottom: 11px;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      margin-top: -30px;
      margin-bottom: 41px;
    }
  }
  .cardFooter {
    padding: 30px;
    background-color: ${(props) => props.theme.color.primary.foundersBlue};
    @media only screen and (min-width: ${(props) => props.theme.size.md}) {
      background-size: cover;
      background-repeat: no-repeat;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
    }
  }
  &.loginModalCard {
    display: flex;
    flex-direction: column;
    .cardFooter {
      flex-grow: 1;
    }
  }
  &.verify-modal {
    .cardBody {
      border-radius: 20px;
      > div {
        > {
          & *:last-child {
            margin-top: 35px;
          }
        }
      }
    }
    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      .cardBody {
        padding-top: 20px;
      }
      .cardFooter {
        padding: 30px;
      }
      &.verify-modal {
        .cardBody {
          > div {
            > {
              & *:last-child {
                margin-top: 50px;
              }
            }
          }
        }
      }
      > button {
        position: relative;
        margin-top: 20px;
        margin-left: 20px;
        top: 0;
        left: 0;
        background: ${(props) => props.theme.color.primary.coleslawWhite};
      }
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
    border-radius: 20px;
  }
`;

export const CardBackButton = styled(BackButton)`
  position: absolute;
  grid-gap: 8px;
  background: ${(props) => props.theme.color.primary.coleslawWhite};
  color: ${(props) => props.theme.color.primary.foundersBlue};
  display: flex;

  img {
    height: 16px !important;
    width: 16px !important;
  }

  @media only screen and (min-width: ${(props) => props.theme.size.xs}) {
    left: 20px;
    top: 30px;
  }
`;
