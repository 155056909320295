import styled from "styled-components";

export const ErrorModalStyled = styled.div<{ isBackButton?: boolean; paragraphFontWeight?: number; paragraphMobileFontWeight?: number }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.primary.coleslawWhite};
  padding: 72px 0 60px;
  width: 632px;
  text-align: center;
  gap: 24px;
  border-radius: 20px;

  .heading {
    max-width: 383px;
    margin: auto;
  }

  p {
    max-width: 360px;
    margin: auto;
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 18px;
    font-weight: ${(props) => props.paragraphFontWeight ?? 400};
    line-height: 21.6px;
    color: ${(props) => props.theme.color.core.black};
  }
  .paragraph {
    span {
      max-width: 360px;
      margin: auto;
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 18px;
      font-weight: ${(props) => props.paragraphFontWeight ?? 400};
      line-height: 21.6px;
      color: ${(props) => props.theme.color.core.black};
    }
  }
  .cta {
    margin: auto;
  }

  .errorModalClose {
    margin-top: 30px;
    width: 162px;
    height: 44px;
    border: 3px solid ${(props) => props.theme.color.primary.foundersBlue};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 20px;
    font-weight: 700;
    line-height: 21.6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cmsErrorMessageLink {
    width: min(95%, 330px);
    margin: auto;
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;

    a {
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 16px;
      font-weight: 700;
      line-height: 19.2px;
      text-align: center;
    }
  }

  ${(props) => props.theme.responsive?.max.lg} {
    padding: 48px 24px 32px;
    width: 272px;

    p {
      font-size: 14px;
      line-height: 16.8px;
      font-weight: ${(props) => props.paragraphMobileFontWeight ?? 500};
    }

    .errorModalClose {
      font-size: 15px;
      line-height: 18px;
      width: 148px;
      height: 36px;
      margin-top: 20px;
    }
  }
`;
