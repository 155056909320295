import { ReactNode } from "react";
import { CurveModalStyled } from "./CurveModalStyled";

interface Props {
  body: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  isOpen: boolean;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  onClose?: () => void;
  className?: string;
  mobileVariant?: "fullScreen" | "croppedVariant" | string;
}

const CurveModal = ({
  children,
  isOpen,
  onClose,
  body,
  footer,
  showCloseButton = true,
  showBackButton = false,
  mobileVariant = "croppedVariant",
  className,
}: Props) => {
  return (
    <CurveModalStyled
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={showCloseButton}
      showBackButton={showBackButton}
      mobileVariant={mobileVariant}
      className={className}
    >
      <div className="cardDetail">
        <div className="cardBodyCurve">{body}</div>
        {footer && <div className="cardFooterCurve">{footer}</div>}
      </div>
    </CurveModalStyled>
  );
};

export default CurveModal;
