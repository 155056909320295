import StarIcon from "components/global/StarIcon/StarIcon";
import { LabelWithStarStyled } from "./LabelWithStarStyled";

interface LabelWithStarProps {
  text: string;
  starStyle: "BLUE" | "WHITE";
  isActive?: boolean;
  className?: string;
  starPosition?: "LEFT" | "RIGHT";
  onClick?: any;
  isSelectable?: boolean;
}

const LabelWithStar = ({
  text,
  starStyle,
  onClick,
  isActive = false,
  isSelectable = false,
  className = "",
  starPosition = "RIGHT",
}: LabelWithStarProps) => {
  return (
    <LabelWithStarStyled
      variant={starStyle}
      isActive={isActive}
      onClick={onClick}
      className={className}
      isSelectable={isSelectable}
      data-testid="label-with-star"
    >
      {starPosition === "LEFT" && <StarIcon variant={starStyle} isActive={isActive} />}
      <label className="label">{text}</label>
      {starPosition === "RIGHT" && <StarIcon variant={starStyle} isActive={isActive} />}
    </LabelWithStarStyled>
  );
};

export default LabelWithStar;
