import styled from "styled-components";

export const ProductCardStyled = styled.div`
  display: flex;
  width: 298px;
  height: 84px;
  align-items: center;
  ${(props) => props.theme.responsive?.max.lg} {
    width: 232px;
    height: 60px;
  }

  .product-image {
    flex-shrink: 0;
    & > span {
      background: ${({ theme }) => theme?.color.primary.coleslawWhite} !important;
      border-radius: 8px;
    }
  }

  .foodInfo {
    display: flex;
    flex-direction: column;
    padding: 10px;
    .price {
      margin-top: 10px;
      ${(props) => props.theme.responsive?.max.lg} {
        margin-top: 4px;
      }
    }
  }
`;
