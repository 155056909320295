import DataDogAnalytics from "analytics/Datadog";
import { AnalyticsListener } from "./AnalyticsListener";

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export default class GTMAnalyticsListener implements AnalyticsListener {
  init() {
    try {
      // No need to invoke any instance
    } catch (error: any) {
      DataDogAnalytics.getInstance().datadogAddError(error, { errorFrom: "GTM" });
    }
  }

  getExcludedEvents() {
    return ["page_view"];
  }

  public pageview(location: string): void {
    this.pushToDataLayer({
      event: "config",
      ...{
        page_location: location,
      },
    });
  }

  logEvent(eventName: string, properties: object = {}) {
    this.pushToDataLayer({ event: eventName, ...properties });
  }

  private pushToDataLayer(data: any) {
    if (typeof window === "undefined") {
      console.error("gtm called server side");
      return;
    }

    window.dataLayer?.push(data);
  }
}
