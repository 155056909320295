import { ReactNode } from "react";
import Image from "next/image";
import { IC_ARROW_THIN_LEFT_FOUNDERS_BLUE } from "assets/images";
import { OrderAccordionStyled } from "./OrderAccordionStyled";

interface Props {
  accordionBody: ReactNode;
  accordionDropdown: ReactNode;
  onDropdownToggle?: () => void;
  isExpanded: boolean;
  className?: string;
}

const OrderAccordion = ({ accordionBody, accordionDropdown, onDropdownToggle, isExpanded, className }: Props) => {
  return (
    <OrderAccordionStyled isDropdownExpanded={isExpanded} className={className}>
      <div className="orderAccordionBody">
        {accordionBody}
        <div className="dropdownToggle" onClick={onDropdownToggle}>
          <Image src={IC_ARROW_THIN_LEFT_FOUNDERS_BLUE} alt="Toggle dropdown arrow" />
        </div>
      </div>
      {isExpanded && <div className="orderAccordionDropdown">{accordionDropdown}</div>}
    </OrderAccordionStyled>
  );
};

export default OrderAccordion;
