import styled from "styled-components";

export const AddressSelectCardContainerStyled = styled.div<{
  isPinnedLocation?: boolean;
}>`
  max-width: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;

  padding: 0;
  position: relative;

  .cardBody,
  .cardFooter {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
  }

  .cardBody {
    background: ${(props) => props.theme.color.core.white};
    padding: 40px 24px 20px;
    border-radius: 12px 12px 0 0;

    .favoriteStore {
      margin-bottom: 0;
    }

    &.nearbyCardBody {
      padding: 0;
      width: 100%;
      border-radius: 0;
    }
  }

  .cardFooter {
    padding: 20px 24px 24px;
    background: ${(props) => props.theme.color.core.white};
    border-radius: 0 0 12px 12px;

    .selectStoreButton {
      width: 240px;
    }
  }
  .test {
    .cardFooter {
      background: none;
    }
  }

  ${(props) => props.theme.responsive.max.lg} {
    height: 100%;
    width: 100%;
    max-width: none;

    .cardBody,
    .cardFooter {
      border-radius: 0;
    }

    .cardBody {
      padding: 0;
    }

    .cardFooter {
      height: 100%;
      width: 100%;
    }
  }
`;
