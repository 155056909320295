import styled from "styled-components";

export const ViewOrdersAccordionDropdownStyled = styled.div`
  .orderId {
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;

    > span {
      font-family: ${(props) => props.theme.font.barlow};
    }
  }
  .dropDownItem {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    gap: 4px 0px;
    .itemName {
      max-width: 238px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .hr {
    width: 100%;
    margin: 24px 0;
  }
  .priceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
