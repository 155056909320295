import styled from "styled-components";

export const FooterSocialIconsContainerStyled = styled.div`
  display: flex;
  & > * {
    background-color: ${(props) => props.theme.color.primary.foundersBlue};
    width: 37.16px;
    height: 37.16px;
  }
  & > *:not(:last-child) {
    margin-right: 10px;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    margin-top: 10.84px;
    margin-right: 0;
    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }
`;
