import { ReactNode } from "react";
import { AddressSelectCardContainerStyled } from "./AddressSelectedCardContainerStyled";

interface Props {
  body: ReactNode;
  footer: ReactNode;
  className?: string;
  isNearByCard?: boolean;
  isPinnedLocation?: boolean;
}
const AddressSelectedCardContainer = ({ body, footer, className, isNearByCard, isPinnedLocation }: Props) => {
  return (
    <AddressSelectCardContainerStyled className={className} isPinnedLocation={isPinnedLocation}>
      <div className={isNearByCard ? "nearbyCardBody cardBody" : "cardBody"}>{body}</div>
      {!isNearByCard && <div className="cardFooter">{footer}</div>}
    </AddressSelectCardContainerStyled>
  );
};

export default AddressSelectedCardContainer;
