import styled, { css } from "styled-components";
import { CLOSE_X_RED, IC_CHECKBOX_BLUE_SM } from "assets/images";

export const StrengthIndicator = styled.div`
  .strengthWrapper {
    ${(props) => `
     display: flex;
     background-color: rgba(255, 255, 255, 0.7);
     width: 95%;
     justify-content: space-around;
     margin: auto;
     height: 40px;
     align-items: center;
     border-radius: 4px;
     padding: 4px;
    `}
  }
  .required {
    ${(props) => `
    color: ${props.theme.color.secondary.coolGray10};
    font-family: ${props.theme.font.sourceSansPro};
    font-weight: 700;
    font-size: 11px;
    text-transform: uppercase;
    `}
    ${(props) => props.theme.responsive?.max.sm} {
      font-size: 10px;
    }
  }
  span {
    ${(props) => `
    font-family: ${props.theme.font.sourceSansPro};
    font-weight: 900;
    font-size: 11px;
    display: flex;
    `}
    ${(props) => props.theme.responsive?.max.sm} {
      font-size: 10px;
    }
  }
`;

export const PasswordCase: any = styled.span`
  ${(props: any) =>
    props.validation &&
    css`
      color: ${props.theme.color.primary.foundersBlue};
      &:before {
        content: "";
        background: url(${IC_CHECKBOX_BLUE_SM.src});
        background-repeat: no-repeat;
        height: 15px;
        width: 15px;
        background-size: contain;
        margin: 2px 4px;
        ${(props) => props.theme.responsive?.max.md} {
          margin: 0;
        }
      }
    `}

  ${(props: any) =>
    !props.validation &&
    css`
      color: ${props.isPasswordFieldoutOfFocus ? props.theme.color.primary.tongueTorchRed : props.theme.color.secondary.coolGray10};
      &:before {
        content: "";
        background: url(${props.isPasswordFieldoutOfFocus ? CLOSE_X_RED.src : ""});
        background-repeat: no-repeat;
        width: 8px;
        background-size: contain;
        height: 15px;
        width: 15px;
        margin: 2px 4px;
        ${(props) => props.theme.responsive?.max.md} {
          margin: 0;
        }
      }
    `}
`;
