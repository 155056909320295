import { ButtonProps } from "../types";
import { PrimaryButtonStyled } from "./PrimaryButtonStyled";

const PrimaryButton = ({ variant, children, ...rest }: ButtonProps) => {
  return (
    <PrimaryButtonStyled variant={variant} {...rest}>
      {children}
    </PrimaryButtonStyled>
  );
};

export default PrimaryButton;
