import styled from "styled-components";

export const CurbsideBannerStyled = styled.div`
  background-color: ${(props) => props.theme.color.core.blue};
  position: fixed;
  bottom: -1px;
  z-index: 999;
  color: ${(props) => props.theme.color.core.white};
  width: 100%;
  padding: 8.3px 24px;
  border-top: 4px solid #f5f5f5;
  display: grid;
  grid-template-columns: 2fr 1fr;

  .storeDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .headingContainer {
      display: flex;
      align-items: baseline;
      .whitePart {
        color: ${(props) => props.theme.color.core.white};
      }
    }

    .orderConfirmationLink {
      background-color: transparent;
      border: none;
      width: unset;
      max-width: 197px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-decoration: underline;
      color: ${(props) => props.theme.color.core.white};
      text-decoration-color: ${(props) => props.theme.color.core.white};
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      text-align: left;
      height: 15px;
      text-transform: none;
    }
  }
  .actionButtons {
    display: flex;
    align-items: center;
    .imHereButton {
      width: 121px;
      height: 36px;
    }
  }
`;
