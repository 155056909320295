import Link from "next/link";
import styled, { css } from "styled-components";

interface LinkProps {
  variant: "inlineBody1" | "inlineBody2" | string;
  fontWeight?: number;
}
const inlineBody1 = css`
  font-size: 16px;
  line-height: 19px;
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 14px;
    line-height: 17px;
  }
`;

const inlineBody2 = css`
  font-size: 14px;
  line-height: 17px;
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 12px;
    line-height: 14px;
  }
`;
const textDecorationNone = css`
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 14px;
    line-height: 17px;
  }
`;
export const LinkContainerStyled = styled.span<LinkProps>`
  cursor: pointer;
  a {
    ${(props) => ({ inlineBody2, inlineBody1, textDecorationNone }[props.variant])}
    font-family: ${(props) => props.theme.font?.barlow};
    font-weight: ${({ fontWeight }) => fontWeight ?? 700};
    color: ${(props) => props.theme.color?.primary.foundersBlue};
    text-transform: uppercase;
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
`;
