import { ReactNode } from "react";
import BackButton from "components/global/Buttons/BackButton/BackButton";
import CloseButton from "components/global/Buttons/CloseButton/CloseButton";
import Modal from "components/global/Modal/Modal";
import { OrderModal } from "containers/Order/types";
import { OrderNowModalStyled } from "./OrderNowModalStyled";
import { useOrderNowModal } from "./useOrderNowModal";

interface Props {
  modalName: OrderModal;
  closeModalButtonType: "BACK" | "CLOSE";
  children?: ReactNode;
  className?: string;
}

const OrderNowModal = ({ modalName, closeModalButtonType, children, className }: Props) => {
  const { orderModal, closeModal } = useOrderNowModal();
  return (
    <OrderNowModalStyled className={className}>
      <Modal overlayClassName="overlay" isOpen={orderModal === modalName}>
        <div className="modalContent">
          {closeModalButtonType === "BACK" ? (
            <BackButton variant="eleventh" onClick={closeModal} className="modalBackButton" />
          ) : (
            <CloseButton variant="eleventh" showText={false} onClick={closeModal} className="modalCloseButon" />
          )}
          {children}
        </div>
      </Modal>
    </OrderNowModalStyled>
  );
};

export default OrderNowModal;
