import SearchCard from "components/OrderNow/SearchCard/SearchCard";
import OrderPageContainer from "../OrderPageContainer/OrderPageContainer";

const LoggedInSearch = () => {
  return (
    <OrderPageContainer mobileLayout="REBRAND_SCREEN">
      <SearchCard />
    </OrderPageContainer>
  );
};

export default LoggedInSearch;
