import {
  CLOSE_VIOLATOR_BAR,
  SET_CHECKOUT_DATA,
  SET_FOOTER_DATA,
  SET_HOME_DATA,
  SET_MENU_DATA,
  SET_STORE_DETAILS_DATA,
  SET_VIOLATOR_DATA,
  SET_RETIRED_MENU,
  SET_RESTAURANT_OPPORTUNITIES_DATA,
  PROLIFERATE_ARTICLES_DATA,
  SET_IS_ARTICLES_LOADED,
  SET_NEWS_PAGE_DATA,
  SET_ALL_ARTICLES_LOADED,
  SET_CONTACT_US_DATA,
  SET_INITIAL_ARTICLES_DATA,
  SET_DOWN_DATA,
  SET_404_DATA,
  SET_CORPORATE_DATA,
  CMS_IS_LOADING,
  SET_SIGNUP_SIGNIN_MESSAGE,
  SET_LOCATION_VERIFICATION_CHECKOUT_POPUP_DATA,
  SET_CHECKOUT_CMS_DATA,
  SET_EXCLUDE_TAX_TEXT,
} from "./CMSConst";
import { Action, CMSRootState } from "./types";

const initialState: CMSRootState = {
  isLoading: false,
  home: null,
  isHomeLoaded: false,
  checkout: null,
  isCheckoutLoaded: false,
  footer: null,
  isFooterLoaded: false,
  storeDetails: null,
  isStoreDetailsLoaded: false,
  menu: null,
  isMenuLoaded: false,
  violator: null,
  isViolatorLoaded: false,
  retiredMenu: null,
  isRetiredMenuLoaded: false,
  restaurantOpportunities: null,
  isRestaurantOpportunitiesLoaded: false,
  articles: null,
  isArticlesLoaded: false,
  areAllArticlesLoaded: false,
  newsPage: null,
  isNewsPageLoaded: false,
  contactUs: null,
  isContactUsLoaded: false,
  errorDown: null,
  isErrorDownLoaded: false,
  error404: null,
  isError404Loaded: false,
  corporate: null,
  isCorporateLoaded: false,
  signinLoginMessage: null,
  isSigninLoginMessageLoaded: false,
  locationVerificationCheckout: null,
  cmsDataForCheckout: null,
  cmsExcludeTaxText: null,
};

const CMSReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case CMS_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload || false,
      };

    case SET_HOME_DATA:
      return {
        ...state,
        home: action.payload,
        isHomeLoaded: true,
      };

    case SET_SIGNUP_SIGNIN_MESSAGE:
      return {
        ...state,
        signinLoginMessage: action.payload,
        isSigninLoginMessageLoaded: true,
      };

    case SET_CHECKOUT_DATA:
      return {
        ...state,
        checkout: action.payload,
        isCheckoutLoaded: true,
      };

    case SET_FOOTER_DATA:
      return {
        ...state,
        footer: action.payload,
        isFooterLoaded: true,
      };

    case SET_STORE_DETAILS_DATA:
      return {
        ...state,
        storeDetails: action.payload,
        isStoreDetailsLoaded: true,
      };

    case SET_MENU_DATA:
      return {
        ...state,
        menu: action.payload,
        isMenuLoaded: true,
      };

    case SET_VIOLATOR_DATA:
      return {
        ...state,
        violator: action.payload,
        isViolatorLoaded: true,
      };

    case CLOSE_VIOLATOR_BAR:
      return {
        ...state,
        violator: null,
      };

    case SET_RETIRED_MENU:
      return {
        ...state,
        retiredMenu: action.payload,
        isRetiredMenuLoaded: true,
      };

    case SET_RESTAURANT_OPPORTUNITIES_DATA:
      return {
        ...state,
        restaurantOpportunities: action.payload,
        isRestaurantOpportunitiesLoaded: true,
      };

    case SET_INITIAL_ARTICLES_DATA:
      return {
        ...state,
        articles: action.payload,
        isArticlesLoaded: true,
        areAllArticlesLoaded: false,
      };

    case PROLIFERATE_ARTICLES_DATA:
      return {
        ...state,
        articles: [...(state.articles || []), ...(action.payload || [])],
        isArticlesLoaded: true,
      };

    case SET_IS_ARTICLES_LOADED:
      return {
        ...state,
        isArticlesLoaded: action.payload,
      };

    case SET_NEWS_PAGE_DATA:
      return {
        ...state,
        newsPage: action.payload,
        isNewsPageLoaded: true,
      };

    case SET_ALL_ARTICLES_LOADED:
      return {
        ...state,
        areAllArticlesLoaded: true,
      };

    case SET_CONTACT_US_DATA:
      return {
        ...state,
        contactUs: action.payload,
        isContactUsLoaded: true,
        isLoading: false,
      };

    case SET_DOWN_DATA:
      return {
        ...state,
        errorDown: action.payload,
        isErrorDownLoaded: true,
      };

    case SET_404_DATA:
      return {
        ...state,
        error404: action.payload,
        isError404Loaded: true,
      };

    case SET_CORPORATE_DATA:
      return {
        ...state,
        corporate: action.payload,
        isCorporateLoaded: true,
      };

    case SET_LOCATION_VERIFICATION_CHECKOUT_POPUP_DATA:
      return {
        ...state,
        locationVerificationCheckout: action.payload,
      };

    case SET_CHECKOUT_CMS_DATA:
      return {
        ...state,
        cmsDataForCheckout: action.payload,
        isCheckoutLoaded: true,
      };
    case SET_EXCLUDE_TAX_TEXT:
      return {
        ...state,
        cmsExcludeTaxText: action.payload,
      };

    default:
      return state;
  }
};

export default CMSReducer;
