import { ChangeEvent, FocusEvent, KeyboardEvent, MouseEventHandler, MutableRefObject, useEffect, useRef } from "react";
import Image from "next/image";
import useToggle from "hooks/useToggle";
import { EYE_OFF_GREY, EYE_GREY, SEARCH_IC, IC_CLEAR_INPUT } from "assets/images";
import { TextFieldWrapper, StyledInput, StyledError, StyledMessage } from "./TextFieldStyled";
export interface TextFieldProps {
  name: string;
  value: string;
  defaultValue?: string;
  error?: string | boolean;
  required?: boolean;
  label?: string;
  onBlur?: any;
  onChange: any;
  className?: string;
  type?: "text" | "email" | "password" | "tel" | "number";
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  id?: string;
  maxLength?: number;
  disabled?: boolean;
  onFocus?: any;
  ariaLabel?: string;
  caption?: string;
  cleanFunction?: (value: string) => string;
  isSearch?: boolean;
  variant?: "primaryBlueOutline" | string;
  placeholder?: string;
  isClear?: boolean;
  onClear?: MouseEventHandler<HTMLDivElement>;
  customRef?: MutableRefObject<HTMLInputElement | null>;
  cursorPos?: number;
  autoComplete?: "on" | "off";
  autoFocus?: boolean;
}

const TextField = ({
  type = "text",
  id,
  name,
  value,
  label,
  placeholder,
  required = true,
  error,
  onChange,
  className,
  onFocus,
  onBlur,
  defaultValue,
  maxLength,
  disabled,
  onKeyDown,
  ariaLabel,
  caption,
  cleanFunction,
  isSearch,
  isClear,
  onClear,
  customRef,
  cursorPos,
  autoComplete = "off",
  autoFocus = false,
}: TextFieldProps) => {
  const [isHidden, toggleIsHidden] = useToggle(true);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (customRef && inputRef.current) {
      customRef.current = inputRef.current;
    }
  }, [inputRef, customRef]);

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    const { name: inputName, value: inputValue } = e.target;
    if (onFocus) {
      onFocus(inputName, inputValue);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value: inputValue } = e.target;
    let value = inputValue;
    if (cleanFunction && typeof cleanFunction === "function") {
      value = cleanFunction(inputValue);
    }
    onChange(inputName, value, e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name: inputName, value: inputValue } = e.target;
    if (onBlur) {
      onBlur(inputName, inputValue);
    }
  };

  const handlePasswordIconClick = (e: any) => {
    // keydown event on click of enter and space keys
    if (e.key === "Enter" || e.keyCode === 32) toggleIsHidden();
  };

  const inputType = type === "password" ? (isHidden ? "password" : "text") : type;
  const icon = isHidden ? EYE_OFF_GREY : EYE_GREY;

  return (
    <div className="textFieldWrapper">
      <TextFieldWrapper className="input-wrapper" error={!!error}>
        <StyledInput
          type={inputType}
          name={name}
          value={value}
          required={required}
          onChange={handleChange}
          className={className}
          onBlur={handleBlur}
          onFocus={handleFocus}
          id={id}
          defaultValue={defaultValue}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          aria-label={ariaLabel}
          isSearch={isSearch}
          placeholder={placeholder}
          ref={inputRef}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
        />
        <label htmlFor={name} className="inputLabel">
          {isSearch && (
            <div className="search-icon">
              <Image src={SEARCH_IC} alt="Search ic" layout="fill" />
            </div>
          )}
          {label}
        </label>
        {type === "password" && (
          <div className="show-icon">
            <span onKeyDown={(e: any) => handlePasswordIconClick(e)} onClick={toggleIsHidden} tabIndex={0}>
              <Image src={icon} className="eyeIcon" alt="Eye icon to toggle password visibility" />
            </span>
          </div>
        )}
        {isClear && (
          <div className="show-icon" id="clearIcon" onClick={onClear}>
            <Image src={IC_CLEAR_INPUT} alt="icon-clear" />
          </div>
        )}
      </TextFieldWrapper>
      {error && <StyledError>{error}</StyledError>}
      {caption && <StyledMessage className="captionMessage">{caption}</StyledMessage>}
    </div>
  );
};

export default TextField;
