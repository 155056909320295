import { getLocalStorageData, getSessionStorageData } from "utils/storage";
import { defaultActiveTip } from "constants/constant";
import {
  SET_USER_STATUS_CONST,
  SET_USER_GEOLOCATION_STATUS_CONST,
  SET_USER_ORDER_TYPE,
  SET_LOCATIONS_SCREEN_FLAG,
  SET_REMEMBER_STATUS,
  SET_USER_PAGE_FLAG,
  SET_CONFIRM_ADDRESS_SAVE_LOGIN_FLAG,
  SET_CONTACTLESS_DRIVER_NOTE_FLAG,
  SET_ERROR_MESSAGE_TO_MODAL,
  SET_ERROR_MESSAGE_TO_MODAL_FLAG,
  SET_USER_FROM_CHECKOUT,
  SET_BLACKOUT_ERROR_MESSAGE,
  SET_MIGRATED_USER_INFO,
  SET_STORE_CLOSING_FLAG,
  SET_SELECT_VALID_DATE,
  SET_IS_OLD_PHONE_NUM_VALIDATED,
  SET_USER_PROFILE_PHONE_UPDATE_DATA,
  SET_IS_USER_EXCEEDED_PASSWORD_RESET_LIMIT,
  SET_IS_SHOW_WARNING_MODAL_FLAG,
  SET_WARNING_MESSAGE_TO_MODAL,
  SET_SELECT_STORE_MODAL_FLAG,
  SET_USER_SELECTED_TIP_PERCENTAGE,
  SET_SHOW_ENTER_CODE_MODAL,
  SET_DOUBLE_CHECK_DATE_TIME_BUTTON_CLICKED,
} from "./UserStatusConst";

let firstTimeLoadStatus = "GUEST";
if (getLocalStorageData("tokens") || getSessionStorageData("tokens")) {
  firstTimeLoadStatus = "RETURNING";
}

const initialState = {
  migratedUser: {},
  presentUserStatus: firstTimeLoadStatus,
  presentUserGeolocationStatus: false,
  presentUserOrderType: "PICKUP",
  hasLocationsVisited: false,
  remember: false,
  userPageLocation: false,
  confirmAddressSaveFlag: false,
  contactlessDriverNoteFlag: false,
  errorMessageToModal: null,
  errorMessageFlag: false,
  fromCheckout: false,
  blackoutErrorMessage: "",
  storeClosingFlag: {
    isError: false,
    errorMsg: "",
  },
  selectValidDate: false,
  isOldPhoneNumberValidated: false,
  userProfilePhoneUpdate: {},
  isPasswordResetLimitExceeded: false,
  warningMessageToModal: null,
  isShowWarningModal: false,
  isShowSelectModalFlag: false,
  previousTipPercentage: defaultActiveTip,
  isShowEnterCodeModal: false,
  doubleCheckDateTimeButtonClicked: false,
};

// REVISIT : Need to revisit any type and make it proper

export default function userStatusReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case SET_MIGRATED_USER_INFO:
      return {
        ...state,
        migratedUser: action.payload,
      };

    case SET_USER_STATUS_CONST:
      return {
        ...state,
        presentUserStatus: action.payload,
      };
    case SET_USER_GEOLOCATION_STATUS_CONST:
      return {
        ...state,
        presentUserGeolocationStatus: action.payload,
      };
    case SET_USER_ORDER_TYPE:
      return {
        ...state,
        presentUserOrderType: action.payload,
      };
    case SET_LOCATIONS_SCREEN_FLAG:
      return {
        ...state,
        hasLocationsVisited: action.payload,
      };
    case SET_REMEMBER_STATUS:
      return {
        ...state,
        remember: action.payload,
      };
    case SET_USER_PAGE_FLAG:
      return {
        ...state,
        userPageLocation: action.payload,
      };
    case SET_USER_SELECTED_TIP_PERCENTAGE:
      return {
        ...state,
        previousTipPercentage: action.payload,
      };
    case SET_CONFIRM_ADDRESS_SAVE_LOGIN_FLAG:
      return {
        ...state,
        confirmAddressSaveFlag: action.payload,
      };
    case SET_CONTACTLESS_DRIVER_NOTE_FLAG:
      return {
        ...state,
        contactlessDriverNoteFlag: action.payload,
      };
    case SET_ERROR_MESSAGE_TO_MODAL:
      return {
        ...state,
        errorMessageToModal: action.payload,
      };
    case SET_ERROR_MESSAGE_TO_MODAL_FLAG:
      return {
        ...state,
        errorMessageFlag: action.payload,
      };
    case SET_USER_FROM_CHECKOUT:
      return {
        ...state,
        fromCheckout: action.payload,
      };
    case SET_BLACKOUT_ERROR_MESSAGE:
      return {
        ...state,
        blackoutErrorMessage: action.payload,
      };

    case SET_STORE_CLOSING_FLAG:
      return {
        ...state,
        storeClosingFlag: action.payload,
      };

    case SET_SELECT_VALID_DATE:
      return {
        ...state,
        selectValidDate: action.payload,
      };

    case SET_IS_OLD_PHONE_NUM_VALIDATED:
      return {
        ...state,
        isOldPhoneNumberValidated: action.payload,
      };

    case SET_USER_PROFILE_PHONE_UPDATE_DATA:
      return {
        ...state,
        userProfilePhoneUpdate: action.payload,
      };

    case SET_IS_USER_EXCEEDED_PASSWORD_RESET_LIMIT:
      return {
        ...state,
        isPasswordResetLimitExceeded: action.payload,
      };

    case SET_WARNING_MESSAGE_TO_MODAL:
      return {
        ...state,
        warningMessageToModal: action.payload,
      };

    case SET_IS_SHOW_WARNING_MODAL_FLAG:
      return {
        ...state,
        isShowWarningModal: action.payload,
      };

    case SET_SELECT_STORE_MODAL_FLAG:
      return {
        ...state,
        isShowSelectModalFlag: action.payload,
      };

    case SET_SHOW_ENTER_CODE_MODAL:
      return {
        ...state,
        isShowEnterCodeModal: action.payload,
      };

    case SET_DOUBLE_CHECK_DATE_TIME_BUTTON_CLICKED:
      return {
        ...state,
        doubleCheckDateTimeButtonClicked: action.payload,
      };

    default:
      return state;
  }
}
