import { useState, useEffect } from "react";

const useTimer = (initial: number, interval = 1000) => {
  const [seconds, setSeconds] = useState(initial);

  const complete = seconds < 0;

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1);
      }

      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, interval);

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const resetTimer = () => {
    setSeconds(initial);
  };

  return {
    seconds,
    complete,
    resetTimer,
  };
};

export default useTimer;
