import styled from "styled-components";

export const ZoomButtonsStyled = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${(props) => props.theme.color.core.blue};
  background: white;
  border-radius: 10px;
  width: 44px;
  .zoomButton {
    padding: 15px 12px;
    cursor: pointer;
  }
`;
