import { OrderModal, OrderStep, OrderType } from "containers/Order/types";
import {
  DISABLE_PINS_ON_LOCATION,
  SET_ORDER_MODAL,
  SET_ORDER_TYPE,
  SET_STEP,
  SET_ALTERNATIVE_FLOW,
  SET_IS_NOT_SHOW_FAVE_OR_RECENT_TABS,
  SET_IS_MAP_DRAGGED,
  SET_LOCATION_ACTIVE_TAB,
} from "./LocationConst";

export const setLocationStep = (payload: OrderStep) => {
  return { type: SET_STEP, payload: payload };
};

export const setLocationOrderModal = (payload: OrderModal) => {
  return { type: SET_ORDER_MODAL, payload: payload };
};

export const setLocationOrderType = (payload: OrderType) => {
  return { type: SET_ORDER_TYPE, payload: payload };
};

export const setIsAlternativeFlow = (payload: boolean) => {
  return { type: SET_ALTERNATIVE_FLOW, payload: payload };
};

export const setPinsDisableOnLocation = (payload: boolean) => {
  return { type: DISABLE_PINS_ON_LOCATION, payload: payload };
};

export const setIsNotShowFaveOrRecentTabs = (payload: boolean) => {
  return { type: SET_IS_NOT_SHOW_FAVE_OR_RECENT_TABS, payload: payload };
};

export const setIsMapDragged = (payload: boolean) => {
  return { type: SET_IS_MAP_DRAGGED, payload: payload };
};

export const setLocationActiveTab = (payload: string) => {
  return { type: SET_LOCATION_ACTIVE_TAB, payload: payload };
};
