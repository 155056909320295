import styled from "styled-components";

export const NearByCardContainerStyled = styled.div`
  margin-top: 20px;
  width: 100%;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  flex-grow: 1;
  position: relative;

  > :first-child {
    .locationCardTile {
      margin-top: 0;
    }
  }

  .nearby-heading {
    display: flex;
    align-items: center;
    .filterButton {
      height: 38px;
      padding: 0;
    }
  }
  .nearbyCardBody {
    &.cardBody {
      .addressLine {
        font-size: 12px;
        &.large {
          font-size: 16px;
        }
        &.storeTimings {
          font-size: 12px;
        }
      }
    }
  }
  .btn {
    border-radius: 38px;
    padding: 10px 0;
    font-size: 14px;
    font-family: ${(props) => props.theme.font.sansRegular};
    text-transform: uppercase;
    background: transparent;
    border: 2px solid ${(props) => props.theme.color.core.white};
    border-color: ${(props) => props.theme.color.core.white};
    color: ${(props) => props.theme.color.core.white};
    display: inline-block;
    width: 107px;
    margin-left: auto;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .nearby-heading {
      .filterButton {
        height: 35px;
      }
      &::after {
        content: " ";
        display: block;
      }
      border-bottom: 3px solid ${(props) => props.theme.color.core.white};
      padding-bottom: 17px;
    }
  }
`;

export const Heading = styled.h1`
  font-size: 24px;
  font-family: ${(props) => props.theme.font.scriptRough};
  color: ${(props) => props.theme.color.core.white};
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    font-family: ${(props) => props.theme.font.scriptRegular};
    font-weight: 400;
    font-size: 22px;
  }
`;
