import styled from "styled-components";

export const AddressSuggestionsStyled = styled.div<{ suggestionsNo?: number }>`
  ${(props) =>
    props.suggestionsNo &&
    `.customAutoComplete {
      :focus {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
  }`}
  .addressContainer {
    background: ${(props) => props.theme.color.primary.foundersBlue};
    max-height: 180px;
    overflow: auto;
    scrollbar-width: none;

    & > li:last-child {
      margin-bottom: 12px;
    }
  }

  .d-none {
    display: none;
  }

  .addressItem {
    padding: 5px 0 0 14px;
    gap: 10px;
    list-style: none;
    display: flex;
    align-items: center;
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.core.white};
    font-size: 14px;
    line-height: 16.8px;
    text-align: left;
    & * {
      font-family: ${(props) => props.theme.font.barlow};
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
