import { PropsWithChildren } from "react";
import { AccountPageTitleStyled } from "./AccountPageTitleStyled";

interface AccountPageTitleProps extends PropsWithChildren {
  maxWidth?: number;
  className?: string;
}

const AccountPageTitle = ({ maxWidth, children, className, ...rest }: AccountPageTitleProps) => {
  return (
    <AccountPageTitleStyled maxWidth={maxWidth || 505} {...rest} className={className}>
      {children}
    </AccountPageTitleStyled>
  );
};

export default AccountPageTitle;
