import styled from "styled-components";
import Link from "components/global/Link/Link";

export const FooterContainer = styled.footer`
  width: 100%;
  box-shadow: 0px -4px 2px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.color.core.blue};
  padding-bottom: 60px;
  position: relative;
  .footerBottom {
    padding: 36px 160px 59.07px 123px;
    display: flex;
    background: ${(props) => props.theme.color.core.blue};
    justify-content: space-between;
  }
  .footerGoButton {
    position: absolute;
    top: -25px;
    right: 47px;
    cursor: pointer;
    z-index: 10001;
    @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.md}) {
      display: none;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
    .footerBottom {
      padding: 42px 40px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 10;
    }
    .footerPrimaryLink {
      width: 100%;
    }
    .logo-wrapper {
      display: none;
    }
    padding-bottom: 30px;
  }
`;

export const FooterLogoContainer = styled.div`
  text-align: center;
  grid-column: span 3;
  margin-top: -10px;
  > *:first-child {
    width: 245px;
    height: 108px;
  }
  @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
    > *:first-child {
      width: 245px;
      height: 108px;
      margin-left: 8px;
    }
  }
  .FooterImageWrapper {
    width: 245px;
    height: 108px;
    position: relative;
  }
  .footerNavSecondaryLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
    .footerNavSecondaryLink {
      align-items: center;
      margin-left: 0px;
      width: 100%;
      .footerBottomLink {
        font-family: ${(props) => props.theme.font.sansRough} !important;
        color: ${(props) => props.theme.color.secondary.pantone290} !important;
        font-size: 12px !important;
        font-weight: 500;
        line-height: 209%;
        text-align: center;
      }
    }
  }
`;

export const FooterLogoLinks = styled(Link)`
  font-family: ${(props) => props.theme.font.serifRegular};
  display: flex;
  color: ${(props) => props.theme.color.core.white};
  text-decoration-skip-ink: none !important;
  font-size: 14px;
  font-weight: 400;
  text-align: center !important;
  justify-content: center;
  margin-top: 8px;
  line-height: 20px;
  @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
    font-family: ${(props) => props.theme.font.sansRough};
    color: ${(props) => props.theme.color.secondary.pantone290};
    font-size: 12px;
    font-weight: 500;
    line-height: 209%;
    text-align: center;
  }
`;

export const FooterLinks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px 63px;
  height: 210px;
  flex-wrap: wrap;
  @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
    gap: 10px;
    height: 100%;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    width: 100%;
  }
`;

export const FooterNavLinks = styled(Link)`
  text-decoration: underline !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  font-family: ${(props) => props.theme.font.sansRough};
  color: ${(props) => props.theme.color.core.white};
`;

type styledParagaraph = {
  footerLogo: "frachise" | "zaxbys";
};

export const FrachisorCopyrightNotice = styled.p<styledParagaraph>`
  font-weight: 500;
  font-size: 12px;
  color: ${(props) => props.theme.color.core.white};
  text-align: center;
  display: none;
  font-family: ${(props) => props.theme.font.serifRegular};
  ${(props) => props.footerLogo === "zaxbys" && `display: block; font-weight: 600;`};
  @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
    margin-left: 16px;
    font-family: ${(props) => props.theme.font.sansRough};
    display: block;
  }
  margin-bottom: 20px;
`;
