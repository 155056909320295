import styled from "styled-components";
import { ButtonProps } from "../types";

const BaseButtonStyled = styled.button<ButtonProps>`
  width: 184px;
  text-align: center;
  padding: 12px 0;
  line-height: 17px;
  font-size: 14px;
  font-family: ${(props) => props.theme.font.sansRegular};
  border-radius: 38px;
  text-transform: uppercase;
  cursor: pointer;
  @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
    width: 160px;
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.2;
    cursor: not-allowed;
  `}
`;

export default BaseButtonStyled;
