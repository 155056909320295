import styled, { css } from "styled-components";

const fullScreen = css<{ hideMobileBackground?: boolean }>`
  ${(props) => props.theme.responsive?.max.lg} {
    .modal-style {
      min-width: auto;
      width: 100vw;
      height: 100vh;
      border-radius: 0px;
      background-color: ${({ theme, hideMobileBackground }) => (hideMobileBackground ? "transparent" : theme.color?.primary.coleslawWhite)};
    }
  }
`;

const croppedVariant = css<{ hideMobileBackground?: boolean }>`
  ${(props) => props.theme.responsive?.max.lg} {
    .modal-style {
      min-width: 272px;
      min-height: auto;
      width: auto;
      background-color: ${({ theme, hideMobileBackground }) => (hideMobileBackground ? "transparent" : theme.color?.primary.coleslawWhite)};
    }
  }
`;

export const ModalStyled = styled.div<{
  isOpen: boolean;
  mobileVariant: "fullScreen" | "croppedVariant" | string;
  closeIconVariant: "blackVariant" | "whiteVariant" | string;
  hideMobileBackground?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* stylelint-disable no-descending-specificity */
  .modal-style {
    border-radius: 8px;
    position: relative;
  }
  .show-backbutton {
    position: absolute;
    top: 30px;
    ${(props) => props.theme.responsive?.min.lg} {
      left: 28px;
      button {
        width: 82px;
        height: 22px;
      }
    }
  }
  .image-wrapper {
    cursor: pointer;
    position: absolute;
    height: 24px;
    width: 24px;
    right: 28px;
    top: 28px;
    ${(props) => props.closeIconVariant === "whiteVariant" && `height:14px;width:14px;`}
  }
  ${(props) => props.theme.responsive?.max.lg} {
    .image-wrapper {
      right: 24px;
      top: 24px;
    }

    .show-backbutton {
      top: 24px;
    }
  }
  ${(props) =>
    ({
      fullScreen,
      croppedVariant,
    }[props.mobileVariant])}
`;
