import { useRouter } from "next/router";
import { useEffect } from "react";
import { getTokenFromWebStorage } from "utils/helper";
import useModal from "hooks/useModal";

export const useRedirectURL = () => {
  const router = useRouter();
  const { setModal } = useModal();

  useEffect(() => {
    let timeoutId: any = null;
    // closes any open modal when route changes
    router.events.on("routeChangeStart", () => {
      setModal("");
    });

    const updateModal = (value: any) => {
      const token = getTokenFromWebStorage();
      if (token) {
        // Do nothing
      } else {
        timeoutId = setTimeout(() => {
          setModal(value);
        }, 1000);
      }
    };

    // This will be used for deep linking, if token exists, then user will go to the home page, if not it will open the login modal
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search);
      if (params.has("page")) {
        const page = params.get("page");
        switch (page) {
          case "signin":
            updateModal("LOGIN");
            break;
          case "signup":
            updateModal("SIGNUP");
            break;
          default:
            break;
        }
      }
    }

    return () => {
      router.events.off("routeChangeStart", () => {
        // do nothing
      });
      clearTimeout(timeoutId);
    };
  }, []);
};
