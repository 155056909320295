import { ReactNode } from "react";
import { Card, CardBackButton, CardCloseButton } from "./AuthCardStyled";

interface Props {
  body: ReactNode;
  footer?: ReactNode;
  isCloseRequired?: boolean;
  isCloseRequiredInMobile?: boolean;
  isCardMobile?: boolean;
  isBackVisible?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  className?: string;
  isFaveConfirm?: boolean;
  isButtonTextRed?: boolean;
  isButtonTextVisible?: boolean;
}

const AuthCard = ({
  body,
  footer,
  isCloseRequired = true,
  isCloseRequiredInMobile = true,
  isCardMobile = false,
  isBackVisible = false,
  onClose = () => {
    // OnClose Method
  },
  onBack = () => {
    // OnBack Method
  },
  isFaveConfirm,
  className,
  isButtonTextRed,
  isButtonTextVisible = false,
}: Props) => {
  const buttonTextVariant = isButtonTextRed ? "seventh" : "first";
  const variant = isButtonTextVisible ? "ninth" : buttonTextVariant;

  return (
    <Card isCardMobile={isCardMobile} isBackVisible={isBackVisible} className={className}>
      {isBackVisible && (
        <CardBackButton type="button" variant="first" onClick={onBack} className="backButton">
          {isFaveConfirm ? "BACK TO CART" : "Back"}
        </CardBackButton>
      )}
      {isCloseRequired && (
        <CardCloseButton
          type="button"
          className="authCardClose"
          variant={variant}
          onClick={onClose}
          isCloseRequiredInMobile={isCloseRequiredInMobile}
        >
          {isButtonTextVisible ? " " : "CLOSE"}
        </CardCloseButton>
      )}
      {body && <div className="cardBody">{body}</div>}
      {footer && <div className="cardFooter">{footer}</div>}
    </Card>
  );
};

export default AuthCard;
