import styled, { css } from "styled-components";

import { Template } from "../types";

const violatorTemplateOneDark = css`
  .subTitle,
  .ctaText {
    color: ${(props) => props.theme.color.primary.foundersBlue};
  }
`;

const violatorTemplateOneLight = css`
  .subTitle,
  .ctaText,
  .title {
    color: ${(props) => props.theme.color.core.white} !important;
  }
`;

const violatorTemplateTwoDark = css`
  .subTitle,
  .ctaText {
    color: ${(props) => props.theme.color.primary.foundersBlue};
  }
`;

const violatorTemplateTwoLight = css`
  .subTitle,
  .ctaText,
  .title {
    color: ${(props) => props.theme.color.core.white} !important;
  }
`;

const templates = {
  OneDark: violatorTemplateOneDark,
  OneLight: violatorTemplateOneLight,
  TwoDark: violatorTemplateTwoDark,
  TwoLight: violatorTemplateTwoLight,
};

export const ViolatorBarTemplatesStyled = styled.div<{ template: Template }>`
  display: flex;
  padding: 24px 240px 24px 272px;
  position: relative;

  .closeButton {
    align-self: flex-start;
    z-index: 20;
    position: relative;
    border: 2px solid transparent;
    margin-left: auto;
  }

  .violatorContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    text-align: center;

    .violatorText {
      .title {
        font-size: 18px;
        text-transform: uppercase;
        color: ${(props) => props.theme.color.primary.tongueTorchRed};
      }

      .desktop {
        font-family: ${(props) => props.theme.font.barlow};
        font-weight: 700;
      }

      .subTitle {
        font-family: ${(props) => props.theme.font.barlow};
        font-weight: 500;
        font-size: 16px;
      }

      .ctaText {
        font-family: ${(props) => props.theme.font.barlow};
        font-weight: 700;
        font-size: 16px;
        text-decoration: underline;
        text-transform: uppercase;
      }
    }
  }
  ${(props) => templates[props.template]};

  .image-wrapper {
    cursor: pointer;
    position: absolute;
    height: 20px;
    width: 20px;
    right: 28px;
    top: 26px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    padding: 24px 24px 20px;
    flex-direction: column-reverse;
    justify-content: flex-start;
    .violatorContent {
      text-align: start;
      .violatorText {
        padding-right: 30px;
        .title {
          font-size: 16px;
          padding-bottom: 7px;
        }
        .subTitle {
          font-family: ${(props) => props.theme.font.barlow};
          font-weight: 500;
          font-size: 14px;
        }
        .ctaText {
          font-size: 14px;
        }
      }
    }
    .image-wrapper {
      height: 15px;
      width: 15px;
      top: 25px;
    }
  }
`;
