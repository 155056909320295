import styled from "styled-components";

export const Buttons = styled.div`
  display: grid;
  row-gap: 12px;
  margin-top: 20px;
  > button {
    width: 180px;
  }
`;
