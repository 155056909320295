import styled from "styled-components";
import { responsive } from "theme/responsive";

export default styled.div`
  color: ${(props) => props.theme.color.primary.tongueTorchRed};
  font-family: ${(props) => props.theme.font.sourceSansPro};
  font-size: 10px;
  line-height: 22px;

  ${(props) =>
    responsive.min.lg(`
      color: ${props.theme.color.primary.tongueTorchRed};
  `)}
`;
