import styled from "styled-components";

export const IconWrapper = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
`;

export const NavButtonStyled = styled.div<{ swapIcon?: boolean }>`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
      props?.swapIcon &&
      `
    flex-direction: row-reverse;
    `}
    .iconWrapper {
      width: 16px;
      height: 16px;
      position: relative;
      margin-top: 3px;
      margin-right: 5px;
      ${(props) =>
        props?.swapIcon &&
        `
      transform: rotate(180deg);
      `}
      ${(props) => props.theme.responsive?.max.lg} {
        width: 8px;
        height: 12px;
        margin-top: 0px;
      }
    }
  }
`;
