import styled from "styled-components";
import OrderNowModal from "../OrderNowModal/OrderNowModal";

export const AddressModalStyled = styled(OrderNowModal)`
  .addressModal > .modalCloseButon {
    display: none;
  }
  .modalContent {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    border-radius: 20px;
    padding: 100px 120px 60px 120px;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .mobileCloseButton {
      display: none;
    }
    .modalContent {
      .addressForm {
        .textInputGroupClass {
          &:focus-within {
            transform: scale(1.4, 1);
          }
        }
      }
    }
  }
`;
