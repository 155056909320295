import styled, { css } from "styled-components";
import BaseButton from "../BaseButton/BaseButton";
import { ButtonProps } from "../types";

const first = css`
  border: 2px solid ${(props) => props.theme.color.core.blue};
  color: ${(props) => props.theme.color.core.blue};
`;

const second = first;

const third = css`
  color: ${(props) => props.theme.color.core.white};
  border: 2px solid ${(props) => props.theme.color.core.white};
`;

const fourth = css`
  border: 2px solid ${(props) => props.theme.color.secondary.pantone151};
  color: ${(props) => props.theme.color.secondary.pantone151};
`;

const fifth = css`
  border: 2px solid ${(props) => props.theme.color.secondary.orange};
  color: ${(props) => props.theme.color.core.white};
`;

export const SecondaryButtonStyled = styled(BaseButton)<ButtonProps>`
  background: transparent;

  ${(props) => ({ first, second, third, fourth, fifth }[props.variant])}
`;
