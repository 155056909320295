import { ButtonProps } from "./types";
import { ButtonStyled } from "./StyledButton";

const Button = ({ variant, children, className, underline, ...rest }: ButtonProps) => {
  return (
    <ButtonStyled className={`${className} button-wrapper`} variant={variant} underline={underline} {...rest}>
      {children}
    </ButtonStyled>
  );
};

export default Button;
