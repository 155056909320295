import { css } from "styled-components";

export const mobileLayoutRebrandScreen = css`
  .content {
    padding: 0;
  }

  .childrenMobile {
    width: 100vw;
    height: 100vh;
    position: relative;

    & > * {
      z-index: 59;
    }

    .mobileCloseButton {
      top: 24px;
      right: 26px;
      position: absolute;
      padding: 5px 0;
      z-index: 99;
    }
  }
`;
