import styled from "styled-components";

export const TextFieldWrapper = styled.div<{ error: boolean }>`
  height: 60px;
  width: 392px;
  border-radius: 4px;
  border: 2px solid ${(props) => props.theme.color.secondary.coolGray1};
  background: ${(props) => props.theme.color.core.white};
  position: relative;

  .search-icon {
    position: relative;
    height: 14px;
    width: 14px;
    padding-right: 10px;
  }
  label {
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 19.2px;
    text-transform: uppercase;
    position: absolute;
    top: 30px;
    left: 16px;
    transition: 300ms ease all;
    pointer-events: none;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &.input-wrapper input:focus ~ label,
  &.input-wrapper input:valid ~ label {
    top: 8px;
    font-size: 12px;
    color: ${(props) => props.theme.color.secondary.coolGray7};
    font-family: ${(props) => props.theme.font.barlow};
    font-weight: 700;
    line-height: 14px;
  }
  :focus {
    border: 2px solid ${(props) => props.theme.color.primary.foundersBlue} !important;
  }
  .show-icon {
    position: absolute;
    right: 16px;
    top: 20px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  ${(props) => props.theme.responsive.max.lg} {
    height: 44px;
    width: 326px;
    /* stylelint-disable-next-line no-descending-specificity */
    label {
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      top: 12px;
      left: 12px;
    }

    &.input-wrapper input:focus ~ label,
    &.input-wrapper input:valid ~ label {
      top: 4px;
      font-size: 10px;
      font-weight: 700;

      .search-icon {
        height: 20px;
        width: 20px;
        transition: none;
        top: 6px;
      }
    }
    .show-icon {
      top: 8px;
    }
    /* stylelint-disable-next-line no-descending-specificity */
    .search-icon {
      margin-right: 6px;
      height: 20px;
      width: 20px;
      transition: none;
      top: 0px;
      left: 7px;
    }
  }
  &.input-wrapper input:focus {
    ${(props) => !props.error && `outline: 2px solid ${props.theme.color.primary.foundersBlue}`}
  }
  ${(props) => props.error && `border: 2px solid ${props.theme.color.primary.tongueTorchRed}`}
`;

export const StyledInput = styled.input<{ isSearch?: boolean }>`
  background: none;
  color: ${(props) => props.theme.color.primary.foundersBlue};
  padding: 16px 36px 0px 16px;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  white-space: nowrap;
  font-family: ${(props) => props.theme.font.barlow};
  /* font-size must be >= 16px to prevent zooming feature of mobile browsers
    @see https://zaxbys.atlassian.net/browse/ZP-6995 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4px;

  ${({ isSearch }) =>
    isSearch &&
    `
    padding-left: 40px;
  
  `}
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
  ${(props) => props.theme.responsive.max.lg} {
    padding: 17px 40px 0px 12px;
    font-family: ${(props) => props.theme.font.barlow};
    font-weight: 500;
    line-height: normal;
    ${({ isSearch }) =>
      isSearch &&
      `
    padding-left: 42px;
  
  `}
  }
`;

export const StyledError = styled.div`
  color: ${(props) => props.theme.color.primary.tongueTorchRed};
  font-family: ${(props) => props.theme.font.sourceSansPro};
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 2px;
  max-width: 300px;
  text-align: left;
  ${(props) => props.theme.responsive.max.lg} {
    font-size: 10px;
  }
`;

export const StyledMessage = styled.div`
  text-align: right;
  color: ${(props) => props.theme.color.secondary.coolGray10};
  font-family: ${(props) => props.theme.font.sourceSansPro};
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 11px;
  text-transform: uppercase;
  margin-top: 2px;
  ${(props) => props.theme.responsive.max.lg} {
    font-size: 10px;
  }
`;
