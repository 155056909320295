import React, { Component, ErrorInfo, ReactNode } from "react";
import DataDogAnalytics from "analytics/Datadog";
import Error500 from "components/Errors/Error500/Error500";
import PageWrapper from "components/global/PageWrapper/PageWrapper";

/*
React has not added support for error boundary function component
yet, so this must be a class-based component */

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    DataDogAnalytics.getInstance().datadogAddError(error, errorInfo);
  }

  render() {
    if (this.state.hasError)
      return (
        <PageWrapper>
          <Error500 />
        </PageWrapper>
      );

    return this.props.children;
  }
}

export default ErrorBoundary;
