import Image from "next/image";
import { Media } from "types/cms/cms-types";
import { FooterRewardsStyled } from "./FooterRewardsStyled";

interface Props {
  image: Media;
}

const FooterRewards = ({ image }: Props) => {
  const altText = image?.alternativeText || "Zaxby footer logo";
  return <FooterRewardsStyled>{image?.url && <Image src={image.url} unoptimized layout="fill" alt={altText} />}</FooterRewardsStyled>;
};

export default FooterRewards;
