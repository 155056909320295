import styled from "styled-components";
import { responsive } from "theme/responsive";

interface Props {
  isCheckoutModal?: boolean;
}
export const ModalStyled = styled.div<Props>`
  margin: 0 auto;
  border-radius: 40px;
  position: relative;

  .sub-heading {
    font-style: italic;
  }
  &.SignUpForm {
    > div {
      display: flex;
      flex-direction: column;
      .cardFooter {
        flex-grow: 1;
        margin-top: -15px;
      }
      .cardBody {
        margin: 8px;
      }
    }
    .authCardClose {
      height: 44px;
    }
  }
  &.loginModal,
  &.itemAlreadyAddedAlert,
  &.accountCreatedModal {
    > div {
      .cardFooter {
        height: 60%;
        padding-top: 49px;
        position: relative;
        top: -12px;
        .buttons {
          margin-top: 15px;
          gap: 25px;
        }
      }
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    &.accountCreatedModal {
      height: inherit;
      > div {
        .cardFooter,
        .cardBody {
          .buttons {
            gap: 10px;
            margin-top: 10px;
          }
        }
      }
    }
    &.checkinModal {
      display: flex;
      justify-content: flex-start;
    }
    width: 100%;
    height: 100vh;
    border-radius: 0;
    overflow-y: scroll;
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    ${(props) =>
      props.isCheckoutModal &&
      `
      width: 92%;
      height: 40%;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      margin-top:170px;
      h1{
        font-size: 24px;
      }
      p{
        font-size:12px;
      }
    `}
  }

  @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
    width: 632px;
    border-radius: 40px;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  &.signupFormOverlay,
  &.loginModalOverlay {
    .SignUpForm,
    .loginModal {
      height: 100vh;
    }
  }
  &.CheckinOverlay {
    display: flex;
    align-items: center;
  }
  .closeBtn {
    z-index: 9;
  }
  ${responsive.min.lg(`
    padding: 50px 0;
  `)}

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    height: 100%;
    min-height: 100%;
    overflow-y: auto;
  }
`;
