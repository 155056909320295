import styled, { css } from "styled-components";
import BaseButton from "./BaseButton/BaseButton";
import { ButtonProps } from "./types";

const primaryRed = css<{ disabled?: boolean }>`
  color: ${(props) => props.theme.color?.limitedUse.pureWhite};
  background: ${(props) => props.theme.color?.primary.tongueTorchRed};
  border: 2px solid transparent;
`;
const primaryBlue = css<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? props.theme.color?.limitedUse.pureWhite + "b2" : props.theme.color?.limitedUse.pureWhite)};
  background: ${(props) => props.theme.color?.primary.foundersBlue};
  border: 2px solid transparent;
`;

const primaryWhite = css<{ disabled?: boolean }>`
  background: ${(props) => props.theme.color?.primary.coleslawWhite};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  border: 2px solid ${(props) => (props.disabled ? props.theme.color?.secondary.coolGray1 : props.theme.color?.limitedUse.pureWhite)};
`;

const primaryPureWhite = css<{ disabled?: boolean }>`
  background: ${(props) => props.theme.color?.limitedUse.pureWhite};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  border: 2px solid ${(props) => (props.disabled ? props.theme.color?.secondary.coolGray1 : props.theme.color?.limitedUse.pureWhite)};
`;

const secondaryBlue = css<{ disabled?: boolean }>`
  background: transparent;
  color: ${(props) => (props.disabled ? props.theme.color?.secondary.coolGray1 : props.theme.color?.primary.foundersBlue)};
  border: 3px solid ${(props) => props.theme.color?.primary.foundersBlue};
`;

const secondaryWhite = css`
  background: transparent;
  color: ${(props) => props.theme.color?.limitedUse.pureWhite};
  border: 2px solid ${(props) => props.theme.color?.limitedUse.pureWhite};
`;

const secondaryPelletBlue = css`
  background: ${(props) => props.theme.color?.limitedUse.pureWhite};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  border: 2px solid ${(props) => props.theme.color?.primary.pelletIceBlue};
`;

const secondaryRed = css`
  background: transparent;
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  border: 2px solid ${(props) => props.theme.color?.primary.tongueTorchRed};
`;

const ghostBlue = css<{ underline?: boolean }>`
  border: none;
  background: none;
  text-decoration-line: ${(props) => (props.underline ? "underline" : null)};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
`;

const ghostRed = css<{ underline?: boolean }>`
  border: none;
  background: transparent;
  text-decoration-line: ${(props) => (props.underline ? "underline" : null)};
  color: ${(props) => props.theme.color?.primary.tongueTorchRed};
`;

const ghostWhite = css<{ underline?: boolean }>`
  border: none;
  background: transparent;
  text-decoration-line: ${(props) => (props.underline ? "underline" : null)};
  color: ${(props) => props.theme.color?.core.white};
`;
const outlinedWhite = css<{ underline?: boolean }>`
  border: 3px solid ${(props) => props.theme.color.core.white};
  background: inherit;
  width: 121px;
  height: 36px;
  text-decoration-line: ${(props) => (props.underline ? "underline" : null)};
  color: ${(props) => props.theme.color?.core.white};
`;
const modalOutlineButton = css`
  background: transparent;
  border: 3px solid ${(props) => props.theme.color.primary.foundersBlue};
  color: ${(props) => props.theme.color.primary.foundersBlue};
  width: 162px;
  font-weight: 700;
  ${(props) => props.theme.responsive?.max.lg} {
    height: 36px;
    width: 148px;
    font-size: 15px;
    line-height: 18px;
  }
`;

export const ButtonStyled = styled(BaseButton)<ButtonProps>`
  ${(props) =>
    ({
      primaryRed,
      primaryBlue,
      primaryWhite,
      primaryPureWhite,
      secondaryBlue,
      secondaryWhite,
      secondaryPelletBlue,
      secondaryRed,
      ghostRed,
      ghostBlue,
      ghostWhite,
      modalOutlineButton,
      outlinedWhite,
    }[props.variant])}
`;
