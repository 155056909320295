import styled from "styled-components";
import { responsive } from "theme/responsive";

const GenericError = styled.div<{ fontSize?: any; textAlign?: string; variant?: string; fontWeight?: string; errorMargin?: boolean }>`
  color: ${(props) => props.theme.color.primary.tongueTorchRed};
  font-family: ${(props) => props.theme.font.sourceSansPro};
  font-weight: ${(props: any) => (props.fontWeight ? `${props.fontWeight}` : "700")};
  font-size: ${(props: any) => (props.fontSize ? `${props.fontSize}px` : "14px")};
  line-height: 14px;
  text-align: ${(props: any) => (props.textAlign ? props.textAlign : "left")};
  margin: 5px auto 0;
  padding-bottom: 1px;
  display: block;
  margin-bottom: ${(props: any) => (props.errorMargin ? `30px` : "0px")};

  ${(props) =>
    responsive.max.lg(`
      color: ${props.variant === "loginError" ? props.theme.color.primary.tongueTorchRed : props.theme.color.primary.tongueTorchRed};
      margin-bottom: ${(props: any) => (props.errorMargin ? `20px` : "0px")};
     
  `)}
  .link {
    display: inherit;
    text-decoration: underline;
  }
`;

export default GenericError;
