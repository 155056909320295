import React, { useState, FormEvent } from "react";
import { passwordOptions } from "api/users/password-options";
import { getLocalFieldError } from "utils/validation";
import ForgotPasswordContext from "../ForgotPasswordContext";

export const useForgotPasswordPrompt = (passwordLimitExceeded?: boolean) => {
  const { setStep, setOptions, setUsername } = React.useContext(ForgotPasswordContext);

  const [values, setValues] = useState({ username: "" });
  const [errors, setErrors] = useState({ username: "" });

  const { username } = values;

  const errs = {
    username: getLocalFieldError("email", username, passwordLimitExceeded),
  };

  const handleBlur = (name: string, value: string) => {
    setErrors((prev) => ({ ...prev, [name]: value ? "" : "Required Field" }));
    if (errs.username) setErrors(errs);
  };

  const handleChange = (name: string, value: string) => {
    setErrors((prev) => ({ ...prev, [name]: "" }));
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (errs.username) {
      setErrors(errs);
      return;
    }

    passwordOptions({ username })
      .then((res: any) => {
        setUsername(username);
        setOptions(res.response);
        res.response.status === "UNCONFIRMED" ? setStep("ENTER_CODE") : setStep("SEND_CODE");
      })
      .catch((err) => {
        setErrors((prev) => ({
          ...prev,
          username: err.response ? err.response.data.message : "Something Went Wrong..!",
        }));
      });
  };

  return {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  };
};

export default useForgotPasswordPrompt;
