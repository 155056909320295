// @ts-nocheck
import { createGlobalStyle } from "styled-components";
import theme from "theme/theme";

// zaxsans-outline
import ZaxSansOutlineWoff from "../../../public/fonts/zaxsans-outline.woff";
import ZaxSansOutlineWoff2 from "../../../public/fonts/zaxsans-outline.woff2";
import ZaxSansOutlineTtf from "../../../public/fonts/zaxsans-outline.ttf";

// zaxsans-regular
import ZaxSansRegularWoff from "../../../public/fonts/zaxsans-regular.woff";
import ZaxSansRegularWoff2 from "../../../public/fonts/zaxsans-regular.woff2";
import ZaxSansRegularTtf from "../../../public/fonts/zaxsans-regular.ttf";

// zaxcsript-caps
import ZaxScriptCapsWoff from "../../../public/fonts/zaxscript-caps.woff";
import ZaxScriptCapsWoff2 from "../../../public/fonts/zaxscript-caps.woff2";
import ZaxScriptCapsTtf from "../../../public/fonts/zaxscript-caps.ttf";

// zaxcsript-regular
import ZaxScriptRegularWoff from "../../../public/fonts/zaxscript-regular.woff";
import ZaxScriptRegularWoff2 from "../../../public/fonts/zaxscript-regular.woff2";
import ZaxScriptRegularTtf from "../../../public/fonts/zaxscript-regular.ttf";

// zaxscript-rough
import ZaxScriptRoughWoff from "../../../public/fonts/zaxscript-rough.woff";
import ZaxScriptRoughWoff2 from "../../../public/fonts/zaxscript-rough.woff2";
import ZaxScriptRoughTtf from "../../../public/fonts/zaxscript-rough.ttf";

// zaxscript-roughcaps
import ZaxScriptRoughCapsWoff from "../../../public/fonts/zaxscript-roughcaps.woff";
import ZaxScriptRoughCapsWoff2 from "../../../public/fonts/zaxscript-roughcaps.woff2";
import ZaxScriptRoughCapsTtf from "../../../public/fonts/zaxscript-roughcaps.ttf";

// zaxserif-bold
import ZaxSerifBoldWoff from "../../../public/fonts/zaxserif-bold.woff";
import ZaxSerifBoldWoff2 from "../../../public/fonts/zaxserif-bold.woff2";
import ZaxSerifBoldTtf from "../../../public/fonts/zaxserif-bold.ttf";

// zaxserif-demibold
import ZaxSerifDemiBoldWoff from "../../../public/fonts/zaxserif-demibold.woff";
import ZaxSerifDemiBoldWoff2 from "../../../public/fonts/zaxserif-demibold.woff2";
import ZaxSerifDemiBoldTtf from "../../../public/fonts/zaxserif-demibold.ttf";

// zaxserif-extralight
import ZaxSerifExtraLightWoff from "../../../public/fonts/zaxserif-extralight.woff";
import ZaxSerifExtraLightWoff2 from "../../../public/fonts/zaxserif-extralight.woff2";
import ZaxSerifExtraLightTtf from "../../../public/fonts/zaxserif-extralight.ttf";

// zaxserif-heavy
import ZaxSerifHeavyWoff from "../../../public/fonts/zaxserif-heavy.woff";
import ZaxSerifHeavyWoff2 from "../../../public/fonts/zaxserif-heavy.woff2";
import ZaxSerifHeavyTtf from "../../../public/fonts/zaxserif-heavy.ttf";

// zaxserif-italic
import ZaxSerifItalicWoff from "../../../public/fonts/zaxserif-italic.woff";
import ZaxSerifItalicWoff2 from "../../../public/fonts/zaxserif-italic.woff2";
import ZaxSerifItalicTtf from "../../../public/fonts/zaxserif-italic.ttf";

// zaxserif-light
import ZaxSerifLightWoff from "../../../public/fonts/zaxserif-light.woff";
import ZaxSerifLightWoff2 from "../../../public/fonts/zaxserif-light.woff2";
import ZaxSerifLightTtf from "../../../public/fonts/zaxserif-light.ttf";

// zaxserif-medium
import ZaxSerifMediumWoff from "../../../public/fonts/zaxserif-medium.woff";
import ZaxSerifMediumWoff2 from "../../../public/fonts/zaxserif-medium.woff2";
import ZaxSerifMediumTtf from "../../../public/fonts/zaxserif-medium.ttf";

// zaxserif-regular
import ZaxSerifRegularWoff from "../../../public/fonts/zaxserif-regular.woff";
import ZaxSerifRegularWoff2 from "../../../public/fonts/zaxserif-regular.woff2";
import ZaxSerifRegularTtf from "../../../public/fonts/zaxserif-regular.ttf";

/* New optimized fonts below */

import ZaxSansRoughOptimizedEot from "../../../public/fonts/Zaxsans-Rough/ZaxSansRough.eot";
import ZaxSansRoughOptimizedWoff2 from "../../../public/fonts/Zaxsans-Rough/ZaxSansRough.woff2";
import ZaxSansRoughOptimizedWoff from "../../../public/fonts/Zaxsans-Rough/ZaxSansRough.woff";
import ZaxSansRoughOptimizedOtf from "../../../public/fonts/Zaxsans-Rough/ZaxSansRough.otf";
import ZaxSansRoughOptimizedTtf from "../../../public/fonts/Zaxsans-Rough/ZaxSansRough.ttf";

/* Source sans Pro fonts */

import SourceSansProBlack from "../../../public/fonts/SourceSansPro/SourceSansPro-Black.ttf";
import SourceSansProBlackItalic from "../../../public/fonts/SourceSansPro/SourceSansPro-BlackItalic.ttf";
import SourceSansProBold from "../../../public/fonts/SourceSansPro/SourceSansPro-Bold.ttf";
import SourceSansProBoldItalic from "../../../public/fonts/SourceSansPro/SourceSansPro-BoldItalic.ttf";
import SourceSansProSemiBold from "../../../public/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf";
import SourceSansProSemiBoldItalic from "../../../public/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.ttf";
import SourceSansProRegular from "../../../public/fonts/SourceSansPro/SourceSansPro-Regular.ttf";
import SourceSansProRegularItalic from "../../../public/fonts/SourceSansPro/SourceSansPro-Italic.ttf";
import SourceSansProLight from "../../../public/fonts/SourceSansPro/SourceSansPro-Light.ttf";
import SourceSansProLightItalic from "../../../public/fonts/SourceSansPro/SourceSansPro-LightItalic.ttf";
import SourceSansProExtraLight from "../../../public/fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf";
import SourceSansProExtraLightItalic from "../../../public/fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.ttf";

/* Barlow fonts */

import BarlowBlack from "../../../public/fonts/Barlow/Barlow-Black.ttf";
import BarlowBlackItalic from "../../../public/fonts/Barlow/Barlow-BlackItalic.ttf";
import BarlowExtraBold from "../../../public/fonts/Barlow//Barlow-ExtraBold.ttf";
import BarlowBold from "../../../public/fonts/Barlow/Barlow-Bold.ttf";
import BarlowBoldItalic from "../../../public/fonts/Barlow/Barlow-BoldItalic.ttf";
import BarlowSemiBold from "../../../public/fonts/Barlow/Barlow-SemiBold.ttf";
import BarlowSemiBoldItalic from "../../../public/fonts/Barlow/Barlow-SemiBoldItalic.ttf";
import BarlowRegular from "../../../public/fonts/Barlow/Barlow-Regular.ttf";
import BarlowRegularItalic from "../../../public/fonts/Barlow/Barlow-Italic.ttf";
import BarlowLight from "../../../public/fonts/Barlow/Barlow-Light.ttf";
import BarlowLightItalic from "../../../public/fonts/Barlow/Barlow-LightItalic.ttf";
import BarlowExtraLight from "../../../public/fonts/Barlow/Barlow-ExtraLight.ttf";
import BarlowExtraLightItalic from "../../../public/fonts/Barlow/Barlow-ExtraLightItalic.ttf";
import BarlowMedium from "../../../public/fonts/Barlow/Barlow-Medium.ttf";

/* Barlow Semi Condensed fonts */

import BarlowSemiCondensedBlack from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Black.ttf";
import BarlowSemiCondensedBlackItalic from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BlackItalic.ttf";
import BarlowSemiCondensedBold from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Bold.ttf";
import BarlowSemiCondensedExtraBold from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBold.ttf";
import BarlowSemiCondensedExtraBoldItalic from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBoldItalic.ttf";
import BarlowSemiCondensedBoldItalic from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BoldItalic.ttf";
import BarlowSemiCondensedSemiBold from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBold.ttf";
import BarlowSemiCondensedSemiBoldItalic from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBoldItalic.ttf";
import BarlowSemiCondensedRegular from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Regular.ttf";
import BarlowSemiCondensedRegularItalic from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Italic.ttf";
import BarlowSemiCondensedLight from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Light.ttf";
import BarlowSemiCondensedLightItalic from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-LightItalic.ttf";
import BarlowSemiCondensedExtraLight from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLight.ttf";
import BarlowSemiCondensedExtraLightItalic from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLightItalic.ttf";
import BarlowSemiCondensedMedium from "../../../public/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Medium.ttf";

/* Barlow Condensed fonts */

import BarlowCondensedBlack from "../../../public/fonts/BarlowCondensed/BarlowCondensed-Black.ttf";
import BarlowCondensedBlackItalic from "../../../public/fonts/BarlowCondensed/BarlowCondensed-BlackItalic.ttf";
import BarlowCondensedExtraBold from "../../../public/fonts/BarlowCondensed/BarlowCondensed-ExtraBold.ttf";
import BarlowCondensedBold from "../../../public/fonts/BarlowCondensed/BarlowCondensed-Bold.ttf";
import BarlowCondensedBoldItalic from "../../../public/fonts/BarlowCondensed/BarlowCondensed-BoldItalic.ttf";
import BarlowCondensedSemiBold from "../../../public/fonts/BarlowCondensed/BarlowCondensed-SemiBold.ttf";
import BarlowCondensedSemiBoldItalic from "../../../public/fonts/BarlowCondensed/BarlowCondensed-SemiBoldItalic.ttf";
import BarlowCondensedRegular from "../../../public/fonts/BarlowCondensed/BarlowCondensed-Regular.ttf";
import BarlowCondensedRegularItalic from "../../../public/fonts/BarlowCondensed/BarlowCondensed-Italic.ttf";
import BarlowCondensedLight from "../../../public/fonts/BarlowCondensed/BarlowCondensed-Light.ttf";
import BarlowCondensedLightItalic from "../../../public/fonts/BarlowCondensed/BarlowCondensed-LightItalic.ttf";
import BarlowCondensedExtraLight from "../../../public/fonts/BarlowCondensed/BarlowCondensed-ExtraLight.ttf";
import BarlowCondensedExtraLightItalic from "../../../public/fonts/BarlowCondensed/BarlowCondensed-ExtraLightItalic.ttf";
import BarlowCondensedMedium from "../../../public/fonts/BarlowCondensed/BarlowCondensed-Medium.ttf";

/* Zax Casual fonts */
import ZaxCasualOtf from "../../../public/fonts/ZaxCasual/ZaxCasual-Bold.otf";
import ZaxCasualWoff from "../../../public/fonts/ZaxCasual/ZaxCasual-Bold.woff";
import ZaxCasualWoff2 from "../../../public/fonts/ZaxCasual/ZaxCasual-Bold.woff2";

// Legacy fonts, remove after all rebrand effort
// TODO refactor this into the new compileFonts function
const generateFontFace = ({ name, woff2, woff, ttf, isItalic, weight }) => `
  @font-face {
    font-family: '${name}';
    src: url(${woff2}) format('woff2'),
    url(${woff}) format('woff'),
    url(${ttf}) format('ttf');
    font-weight: ${weight ?? "normal"};
    font-style: ${isItalic ? "italic" : "normal"};
    font-display: swap;
  }
`;

const fonts = [
  {
    name: "ZaxSansRegular",
    woff2: ZaxSansRegularWoff2,
    woff: ZaxSansRegularWoff,
    ttf: ZaxSansRegularTtf,
  },
  {
    name: "ZaxSansOutline",
    woff2: ZaxSansOutlineWoff2,
    woff: ZaxSansOutlineWoff,
    ttf: ZaxSansOutlineTtf,
  },
  {
    name: "ZaxScriptCaps",
    woff2: ZaxScriptCapsWoff2,
    woff: ZaxScriptCapsWoff,
    ttf: ZaxScriptCapsTtf,
  },
  {
    name: "ZaxScriptRegular",
    woff2: ZaxScriptRegularWoff2,
    woff: ZaxScriptRegularWoff,
    ttf: ZaxScriptRegularTtf,
  },
  {
    name: "ZaxScriptRough",
    woff2: ZaxScriptRoughWoff2,
    woff: ZaxScriptRoughWoff,
    ttf: ZaxScriptRoughTtf,
  },
  {
    name: "ZaxScriptRoughCaps",
    woff2: ZaxScriptRoughCapsWoff2,
    woff: ZaxScriptRoughCapsWoff,
    ttf: ZaxScriptRoughCapsTtf,
  },
  {
    name: "ZaxSerifRegular",
    woff2: ZaxSerifRegularWoff2,
    woff: ZaxSerifRegularWoff,
    ttf: ZaxSerifRegularTtf,
  },
  {
    name: "ZaxSerifBold",
    woff2: ZaxSerifBoldWoff2,
    woff: ZaxSerifBoldWoff,
    ttf: ZaxSerifBoldTtf,
  },
  {
    name: "ZaxSerifDemiBold",
    woff2: ZaxSerifDemiBoldWoff2,
    woff: ZaxSerifDemiBoldWoff,
    ttf: ZaxSerifDemiBoldTtf,
  },
  {
    name: "ZaxSerifExtraLight",
    woff2: ZaxSerifExtraLightWoff2,
    woff: ZaxSerifExtraLightWoff,
    ttf: ZaxSerifExtraLightTtf,
  },
  {
    name: "ZaxSerifHeavy",
    woff2: ZaxSerifHeavyWoff2,
    woff: ZaxSerifHeavyWoff,
    ttf: ZaxSerifHeavyTtf,
  },
  {
    name: "ZaxSerifItalic",
    woff2: ZaxSerifItalicWoff2,
    woff: ZaxSerifItalicWoff,
    ttf: ZaxSerifItalicTtf,
  },
  {
    name: "ZaxSerifLight",
    woff2: ZaxSerifLightWoff2,
    woff: ZaxSerifLightWoff,
    ttf: ZaxSerifLightTtf,
  },
  {
    name: "ZaxSerifMedium",
    woff2: ZaxSerifMediumWoff2,
    woff: ZaxSerifMediumWoff,
    ttf: ZaxSerifMediumTtf,
  },
  {
    name: "ZaxCasual",
    woff2: ZaxCasualWoff2,
    woff: ZaxCasualWoff,
    otf: ZaxCasualOtf,
    isItalic: true,
    weight: 700,
  },
];

const generateOptimizedFontFace = ({ name, eot, svg, woff2, woff, otf, ttf }) => `
  @font-face {
    font-family: '${name}';
    src: url("${eot}");
    src: url("${eot}") format("embedded-opentype"),
      url("${svg}#${name}") format("svg"),
      url("${woff2}") format("woff2"),
      url("${woff}") format("woff"),
      url("${otf}") format("otf"),
      url("${ttf}") format("truetype");
      font-weight: normal;
      font-style: normal;
      speak: none;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
  }
`;

const optimizedFonts = [
  {
    name: "ZaxSansRough",
    eot: ZaxSansRoughOptimizedEot,
    svg: "/fonts/Zaxsans-Rough/ZaxSansRough.svg" /* This still needs to be fixed */,
    woff2: ZaxSansRoughOptimizedWoff2,
    woff: ZaxSansRoughOptimizedWoff,
    otf: ZaxSansRoughOptimizedOtf,
    ttf: ZaxSansRoughOptimizedTtf,
  },
];

const sourceSansProFonts = [
  {
    ttfFile: SourceSansProBlack,
    weight: 900,
  },
  {
    ttfFile: SourceSansProBlackItalic,
    weight: 900,
    isItalic: true,
  },
  {
    ttfFile: SourceSansProBold,
    weight: 700,
  },
  {
    ttfFile: SourceSansProBoldItalic,
    weight: 700,
    isItalic: true,
  },
  {
    ttfFile: SourceSansProSemiBold,
    weight: 600,
  },
  {
    ttfFile: SourceSansProSemiBoldItalic,
    weight: 600,
    isItalic: true,
  },
  {
    ttfFile: SourceSansProRegular,
    weight: 400,
  },
  {
    ttfFile: SourceSansProRegularItalic,
    weight: 400,
    isItalic: true,
  },
  {
    ttfFile: SourceSansProLight,
    weight: 300,
  },
  {
    ttfFile: SourceSansProLightItalic,
    weight: 300,
    isItalic: true,
  },
  {
    ttfFile: SourceSansProExtraLight,
    weight: 200,
  },
  {
    ttfFile: SourceSansProExtraLightItalic,
    weight: 200,
    isItalic: true,
  },
];
// End legacy

const compileFontFamily = (familyName, { ttfFile, weight, isItalic }) => `
  @font-face {
    font-family: '${familyName}';
    src: url("${ttfFile}");
    font-style: ${isItalic ? "italic" : "normal"};
    font-weight: ${weight};
  }
`;

const barlowFonts = [
  {
    name: "BarlowBlack",
    ttfFile: BarlowBlack,
    weight: 900,
  },
  {
    name: "BarlowBlackItalic",
    ttfFile: BarlowBlackItalic,
    weight: 900,
    isItalic: true,
  },
  {
    name: "BarlowExtraBold",
    ttfFile: BarlowExtraBold,
    weight: 800,
  },
  {
    name: "BarlowBold",
    ttfFile: BarlowBold,
    weight: 700,
  },
  {
    name: "BarlowBoldItalic",
    ttfFile: BarlowBoldItalic,
    weight: 700,
    isItalic: true,
  },
  {
    name: "BarlowSemiBold",
    ttfFile: BarlowSemiBold,
    weight: 600,
  },
  {
    name: "BarlowSemiBoldItalic",
    ttfFile: BarlowSemiBoldItalic,
    weight: 600,
    isItalic: true,
  },
  {
    name: "BarlowMedium",
    ttfFile: BarlowMedium,
    weight: 500,
  },
  {
    name: "Barlow",
    ttfFile: BarlowRegular,
    weight: 400,
  },
  {
    name: "BarlowItalic",
    ttfFile: BarlowRegularItalic,
    weight: 400,
    isItalic: true,
  },
  {
    name: "BarlowLight",
    ttfFile: BarlowLight,
    weight: 300,
  },
  {
    name: "BarlowLightItalic",
    ttfFile: BarlowLightItalic,
    weight: 300,
    isItalic: true,
  },
  {
    name: "BarlowExtraLight",
    ttfFile: BarlowExtraLight,
    weight: 200,
  },
  {
    name: "BarlowExtraLightItalic",
    ttfFile: BarlowExtraLightItalic,
    weight: 200,
    isItalic: true,
  },
];

const barlowSemiCondensedFonts = [
  {
    name: "BarlowSemiCondensedBlack",
    ttfFile: BarlowSemiCondensedBlack,
    weight: 900,
  },
  {
    name: "BarlowSemiCondensedBlackItalic",
    ttfFile: BarlowSemiCondensedBlackItalic,
    weight: 900,
    isItalic: true,
  },
  {
    name: "BarlowSemiCondensedExtraBold",
    ttfFile: BarlowSemiCondensedExtraBold,
    weight: 800,
  },
  {
    name: "BarlowSemiCondensedExtraBoldItalic",
    ttfFile: BarlowSemiCondensedExtraBoldItalic,
    weight: 800,
    isItalic: true,
  },
  {
    name: "BarlowSemiCondensedBold",
    ttfFile: BarlowSemiCondensedBold,
    weight: 700,
  },
  {
    name: "BarlowSemiCondensedBoldItalic",
    ttfFile: BarlowSemiCondensedBoldItalic,
    weight: 700,
    isItalic: true,
  },
  {
    name: "BarlowSemiCondensedSemiBold",
    ttfFile: BarlowSemiCondensedSemiBold,
    weight: 600,
  },
  {
    name: "BarlowSemiCondensedSemiBoldItalic",
    ttfFile: BarlowSemiCondensedSemiBoldItalic,
    weight: 600,
    isItalic: true,
  },
  {
    name: "BarlowSemiCondensedMedium",
    ttfFile: BarlowSemiCondensedMedium,
    weight: 500,
  },
  {
    name: "BarlowSemiCondensedRegular",
    ttfFile: BarlowSemiCondensedRegular,
    weight: 400,
  },
  {
    name: "BarlowSemiCondensedRegularItalic",
    ttfFile: BarlowSemiCondensedRegularItalic,
    weight: 400,
    isItalic: true,
  },
  {
    name: "BarlowSemiCondensedLight",
    ttfFile: BarlowSemiCondensedLight,
    weight: 300,
  },
  {
    name: "BarlowSemiCondensedLightItalic",
    ttfFile: BarlowSemiCondensedLightItalic,
    weight: 300,
    isItalic: true,
  },
  {
    name: "BarlowSemiCondensedExtraLight",
    ttfFile: BarlowSemiCondensedExtraLight,
    weight: 200,
  },
  {
    name: "BarlowSemiCondensedExtraLightItalic",
    ttfFile: BarlowSemiCondensedExtraLightItalic,
    weight: 200,
    isItalic: true,
  },
];

const barlowCondensedFonts = [
  {
    name: "BarlowCondensedBlack",
    ttfFile: BarlowCondensedBlack,
    weight: 900,
  },
  {
    name: "BarlowCondensedBlackItalic",
    ttfFile: BarlowCondensedBlackItalic,
    weight: 900,
    isItalic: true,
  },
  {
    name: "BarlowCondensedExtraBold",
    ttfFile: BarlowCondensedExtraBold,
    weight: 800,
  },
  {
    name: "BarlowCondensedBold",
    ttfFile: BarlowCondensedBold,
    weight: 700,
  },
  {
    name: "BarlowCondensedBoldItalic",
    ttfFile: BarlowCondensedBoldItalic,
    weight: 700,
    isItalic: true,
  },
  {
    name: "BarlowCondensedSemiBold",
    ttfFile: BarlowCondensedSemiBold,
    weight: 600,
  },
  {
    name: "BarlowCondensedSemiBoldItalic",
    ttfFile: BarlowCondensedSemiBoldItalic,
    weight: 600,
    isItalic: true,
  },
  {
    name: "BarlowCondensedMedium",
    ttfFile: BarlowCondensedMedium,
    weight: 500,
  },
  {
    name: "BarlowCondensedRegular",
    ttfFile: BarlowCondensedRegular,
    weight: 400,
  },
  {
    name: "BarlowCondensedRegularItalic",
    ttfFile: BarlowCondensedRegularItalic,
    weight: 400,
    isItalic: true,
  },
  {
    name: "BarlowCondensedLight",
    ttfFile: BarlowCondensedLight,
    weight: 300,
  },
  {
    name: "BarlowCondensedLightItalic",
    ttfFile: BarlowCondensedLightItalic,
    weight: 300,
    isItalic: true,
  },
  {
    name: "BarlowCondensedExtraLight",
    ttfFile: BarlowCondensedExtraLight,
    weight: 200,
  },
  {
    name: "BarlowCondensedExtraLightItalic",
    ttfFile: BarlowCondensedExtraLightItalic,
    weight: 200,
    isItalic: true,
  },
];

export default createGlobalStyle`
  ${barlowFonts.map((font) => compileFontFamily(theme.font.barlow, font))}
  ${barlowSemiCondensedFonts.map((font) => compileFontFamily(theme.font.barlowSemiCondensed, font))}
  ${barlowCondensedFonts.map((font) => compileFontFamily(theme.font.barlowCondensed, font))}
  ${sourceSansProFonts.map((font) => compileFontFamily(theme.font.sourceSansPro, font))}
  ${fonts.map((font) => generateFontFace(font))}
  ${optimizedFonts.map((font) => generateOptimizedFontFace(font))}
`;
