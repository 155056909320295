import NavigationButton from "components/global/Buttons/NavigationButton/NavigationButton";
import {
  IC_CROSS,
  IC_CROSS_DARKRED,
  IC_CROSS_THICK_RED,
  IC_CROSS_WHITE,
  IC_CROSS_LIGHT,
  IC_CROSS_LIGHT_WHITE,
  IC_CROSS_FOUNDERS_BLUE,
  CLOSE_X,
} from "assets/images";
import { NavigationButtonProps } from "../types";

interface CloseButtonProps extends NavigationButtonProps {
  showText?: boolean;
}

const CloseButton = ({ variant, children, showText = true, ...rest }: CloseButtonProps) => {
  const crossIcon = {
    first: IC_CROSS,
    second: IC_CROSS,
    third: IC_CROSS_WHITE,
    fourth: IC_CROSS_WHITE,
    fifth: IC_CROSS,
    sixth: IC_CROSS_WHITE,
    seventh: IC_CROSS_DARKRED,
    eighth: IC_CROSS_THICK_RED,
    ninth: IC_CROSS_LIGHT,
    tenth: IC_CROSS_LIGHT_WHITE,
    eleventh: IC_CROSS_FOUNDERS_BLUE,
    twelveth: CLOSE_X,
  }[variant];

  return (
    <NavigationButton imgPosition="right" imgSrc={crossIcon} variant={variant} data-testid="closeButton" {...rest}>
      {showText ? children || "CLOSE" : null}
    </NavigationButton>
  );
};

export default CloseButton;
