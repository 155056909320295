import styled from "styled-components";

export const CheckinSuccessModalStyled = styled.div`
  .CheckinSuccessModal {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    padding-block: 24px;
    gap: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .headerButtons {
      margin-bottom: 48px;
      padding-right: 24px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      .closeBtn {
        padding: 0;
      }
    }
    .modalContent {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      width: 294px;
      .subContent {
        color: ${(props) => props.theme.color.core.blue};
        text-align: center;
      }
      .hr {
        width: 100%;
        margin: 25px 0;
      }
      .visitCaption {
        color: ${(props) => props.theme.color.core.blue};
        margin-top: 30px;
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
      }
      .zaxLink {
        margin-top: 22px;
        color: ${(props) => props.theme.color.core.blue};
        font-weight: 600;
        font-family: ${(props) => props.theme.font.serifMedium};
      }
      .contactUsFooter {
        position: absolute;
        bottom: 40px;
      }
    }
  }

  .headings {
    max-width: 294px;
  }

  input::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }
  input::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
  }
`;
