import { DualCallouts, Nullable } from "types/cms/cms-types";

export const formattedCMSErrorMessage = (message: any, isSpaceRequired: boolean = false) => {
  const link = `${isSpaceRequired ? "&nbsp;" : ""}<a href=tel:${message?.ErrorMessageLink}>${message?.ErrorMessageLinkText}</a>`;
  return message?.ErrorMessage?.replace(message?.ErrorMessageKey, `${link}`)?.replace("<br>", "<br><br><br>");
};

export const formattedCMSHelpUsText = (message: any, linkTo: string, linkText: string, replacableKey: string) => {
  if (message && message.length > 0) {
    const link = `<a href=tel:${linkTo}>${linkText}</a>`;
    return message?.replace(replacableKey, `${link}`);
  }
  return " ";
};

export const formattedMessageBody = (key: string, replacableText: string, message?: string) => {
  return message?.replace(key, replacableText);
};

interface LogisticTypes {
  title: string;
  description: string;
  CTA: string;
  CTALink: string;
}
export const formattedLogisticFooter = (cmsData: Nullable<DualCallouts>, position: string): LogisticTypes => {
  if (position === "left") {
    return {
      title: cmsData?.Left?.Title ?? "",
      description: cmsData?.Left?.Body ?? "",
      CTA: cmsData?.Left?.CTAText ?? "",
      CTALink: cmsData?.Left?.CTALink ?? "",
    };
  }
  return {
    title: cmsData?.Right?.Title ?? "",
    description: cmsData?.Right?.Body ?? "",
    CTA: cmsData?.Right?.CTAText ?? "",
    CTALink: cmsData?.Right?.CTALink ?? "",
  };
};

export const formattedUrlToMedia = (url: string | null | undefined) => {
  if (url) {
    const newUrl: Nullable<string> = url ?? null;
    return {
      id: 0,
      name: "",
      alternativeText: "",
      caption: "",
      width: 0,
      height: 0,
      formats: null,
      hash: "",
      ext: "",
      mime: "",
      size: 0,
      url: newUrl,
      previewUrl: "",
      provider: "",
      provider_metadata: null,
      created_at: "",
      updated_at: "",
    };
  }
  return null;
};
