import styled from "styled-components";

export const GpsIconStyled = styled.div`
  width: 24px;
  height: 24px;
  display: grid;
  place-items: center;
  background: white;
  cursor: pointer;
`;
