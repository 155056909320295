import { createContext, PropsWithChildren, useMemo } from "react";

interface Context {
  isGmapScriptLoaded: boolean;
}

interface Props {
  isGmapScriptLoaded: boolean;
}

export const GMapsContext = createContext({} as Context);

export const GMapsProvider = ({ isGmapScriptLoaded, children }: PropsWithChildren<Props>) => {
  const isLoaded = useMemo(() => {
    return isGmapScriptLoaded;
  }, [isGmapScriptLoaded]);

  return <GMapsContext.Provider value={{ isGmapScriptLoaded: isLoaded }}>{children}</GMapsContext.Provider>;
};
