import PhoneUpdated from "components/global/PhoneUpdated/PhoneUpdated";
import Modal from "components/global/Modal/Modal";
import useModal from "hooks/useModal";
import { ProfilePhoneUpdatedStyled } from "./ProfilePhoneUpdatedStyled";

const ProfilePhoneUpdated = () => {
  const { modal, setModal } = useModal();
  const handleBack = () => {
    setModal("");
  };

  return (
    <ProfilePhoneUpdatedStyled>
      <Modal isOpen={modal === "UPDATED"} className="customModal">
        <PhoneUpdated onClose={handleBack} actionType="verified" />
      </Modal>
    </ProfilePhoneUpdatedStyled>
  );
};

export default ProfilePhoneUpdated;
