import Image from "next/image";

import { IC_STAR_EMPTY, IC_STAR_EMPTY_WHITE, IC_STAR_SOLID } from "assets/images";

type StarIconStyle = "BLUE" | "WHITE";

interface Props {
  isActive: boolean;
  variant: StarIconStyle;
  className?: string;
}

const StarIcon = ({ isActive, variant, className }: Props) => {
  const iconSrc = {
    BLUE: IC_STAR_EMPTY,
    WHITE: IC_STAR_EMPTY_WHITE,
  }[variant];

  return (
    <div className={className}>
      {isActive ? <Image src={IC_STAR_SOLID} alt="star icon solid" /> : <Image src={iconSrc} alt="star icon empty" />}
    </div>
  );
};

export default StarIcon;
