import { DEFAULT_LAT, DEFAULT_LNG, StoreAction } from "./types";

export const initialState = {
  isLoading: false,
  userLocation: { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
  storeLocation: null,
  storeList: [],
  filteredStoreList: [],
  favouriteStoreList: [],
  storeLocationName: null,
  filteredItems: [],
  filteredRadius: 30,
  storeDetail: {},
  deleteFavStore: [],
  favouriteStore: null,
  selectedStoreId: null,
  selectedStoreName: null,
  selectedStoreLocations: null,
  direction: null,
  fullMenuFilePath: "",
  cateringMenuFilePath: "",
  fromStorePage: false,
  recentStoreList: [],
  pickupOrderType: null,
  showStoreDetailsPin: false,
  famousMealFlag: false,
  deliveryAvailableStores: [],
  storeCalendarData: [],
  storeTiming: [],
  deliveryLocationName: "",
  pickupLocationName: "",
  deliveryLocationCoordinates: {},
  isStoreAvailable: true,
  fromUserSavedCard: false,
  corporateStoreId: null,
  isZipSearch: false,
};

export const storeReducer = (state = initialState, action: StoreAction) => {
  switch (action.type) {
    case "SET_STORE_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload || false,
      };
    case "SET_USER_LOCATION":
      return {
        ...state,
        userLocation: action.payload,
      };
    case "SET_STORE_LOCATION":
      return {
        ...state,
        storeLocation: action.payload,
      };
    case "SET_STORE_LIST":
      return {
        ...state,
        storeList: action.payload,
      };
    case "SET_FILTERED_STORE_LIST":
      return {
        ...state,
        filteredStoreList: action.payload,
      };
    case "SET_FAVOURITE_STORE_LIST":
      return {
        ...state,
        favouriteStoreList: action.payload,
      };
    case "SET_STORE_LOCATION_NAME":
      return {
        ...state,
        storeLocationName: action.payload,
      };
    case "SET_PICKUP_LOCATION_NAME":
      return {
        ...state,
        pickupLocationName: action.payload,
      };
    case "SET_DELIVERY_LOCATION_NAME":
      return {
        ...state,
        deliveryLocationName: action.payload,
      };
    case "SET_FILTERED_LIST":
      return {
        ...state,
        filteredItems: action.payload,
      };
    case "SET_FILTERED_RADIUS":
      return {
        ...state,
        filteredRadius: action.payload,
      };
    case "SET_STORE_DETAIL":
      return {
        ...state,
        storeDetail: action.payload,
      };
    case "SET_STORE_TIMING":
      return {
        ...state,
        storeTiming: action.payload,
      };
    case "SET_SELECTED_STORE":
      return {
        ...state,
        selectedStoreId: action.payload,
      };
    case "DELETE_FAVOURITE_STORE":
      return {
        ...state,
        deleteFavStore: action.payload,
      };
    case "SET_FAVOURITE_STORE":
      return {
        ...state,
        favouriteStore: action.payload,
      };
    case "SET_SELECTED_STORE_NAME":
      return {
        ...state,
        selectedStoreName: action.payload,
      };
    case "SET_SELECTED_STORE_LOCATION":
      return {
        ...state,
        selectedStoreLocations: action.payload,
      };
    case "SET_DIRECTIONS":
      return {
        ...state,
        direction: action.payload,
      };

    case "SET_MENU_FILES_PATH":
      return {
        ...state,
        fullMenuFilePath: action.payload,
      };

    case "SET_CATERINGMENU_FILES_PATH":
      return {
        ...state,
        cateringMenuFilePath: action.payload,
      };

    case "SET_FROM_STORE_PAGE":
      return {
        ...state,
        fromStorePage: action.payload,
      };

    case "RECENT_STORE_LIST":
      return {
        ...state,
        recentStoreList: action.payload,
      };

    case "SET_PICKUP_ORDER_TYPE":
      return {
        ...state,
        pickupOrderType: action.payload,
      };
    case "SHOW_STORE_PIN":
      return {
        ...state,
        showStoreDetailsPin: action.payload,
      };

    case "SET_FAMOUS_MEAL_FLAG":
      return {
        ...state,
        famousMealFlag: action.payload,
      };
    case "SET_DELIVERY_AVAILABLE_STORES":
      return {
        ...state,
        deliveryAvailableStores: action.payload,
      };

    case "SET_STORE_CALENDAR":
      return {
        ...state,
        storeCalendarData: action.payload || [],
      };
    case "SET_DELIVERY_LOCATION_COORDINATES":
      return {
        ...state,
        deliveryLocationCoordinates: action.payload,
      };
    case "SET_IS_STORE_AVAILABLE":
      return {
        ...state,
        isStoreAvailable: action.payload,
      };
    case "SET_FROM_USER_SAVED_CARD":
      return {
        ...state,
        fromUserSavedCard: action.payload,
      };
    case "SET_CORPORATE_STORE_ID":
      return {
        ...state,
        corporateStoreId: action.payload,
      };
    case "SET_IS_ZIP_SEARCH":
      return {
        ...state,
        isZipSearch: action.payload || false,
      };
    default:
      return state;
  }
};
