import { ChangeEvent, useState, ReactNode } from "react";
import { CheckboxStyled, StyledError } from "./CheckboxStyled";

interface Props {
  name: string;
  value: boolean;
  disabled?: boolean;
  rounded?: boolean;
  error?: string;
  className?: string;
  children?: ReactNode;
  variant: "primaryGreenFilled" | "primaryBlueOutlined" | "primaryBlueFilled" | "primaryGreenFilledCustomized" | string;
  onChange?: (name: string, value: boolean, e?: ChangeEvent<HTMLInputElement | undefined>) => void;
}

const Checkbox = ({
  children,
  name,
  disabled,
  value,
  error,
  onChange,
  className,
  variant = "primaryBlueOutlined",
  rounded = false,
}: Props) => {
  const [isChecked, setIsChecked] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      const checkedState = !isChecked;
      setIsChecked(checkedState);
      const { name: inputName, checked } = e.target;
      onChange?.(inputName, checked, e);
    }
  };

  return (
    <CheckboxStyled variant={variant} className={`${className} checkbox-wrapper`} rounded={rounded}>
      <input type="checkbox" className="input-checkbox" id={name} disabled={disabled} checked={value} onChange={handleChange} name={name} />
      <label className="checkbox-label" htmlFor={name}>
        {children}
      </label>
      {error && <StyledError>{error}</StyledError>}
    </CheckboxStyled>
  );
};

export default Checkbox;
