import { useState, useEffect } from "react";

export default function useWindowDimensions() {
  // Hook to check the window dimension
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width: number = hasWindow ? window.innerWidth : 0;
    const height: number = hasWindow ? window.innerHeight : 0;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }
  useEffect(() => {
    if (hasWindow) {
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}
