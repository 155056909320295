import styled from "styled-components";

export const OrderNowModalStyled = styled.div`
  position: absolute;

  .overlay {
    z-index: 60;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    .overlay {
      z-index: 99;
      top: 0;
      > div {
        height: 100vh;
      }
    }
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    overflow: hidden;
  }

  .modalCloseButon {
    position: absolute;
    top: 28px;
    right: 28px;
    padding: 0;
  }

  .modalBackButton {
    position: absolute;
    top: 29px;
    left: 28px;
    padding: 0 2px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 6px;
  }
`;
