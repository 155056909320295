import styled from "styled-components";
import Paragraph from "../../typography/Paragraphs/Paragraph";

export const ParagraphStyled = styled(Paragraph)`
  strong,
  em,
  u {
    font-family: ${(props) => props.theme.font?.barlow};
    font-style: none;
  }
`;
