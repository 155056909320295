import { ReactNode } from "react";
import Image from "next/image";
import { CURVE } from "assets/images";
import { Card, CardBackButton, CardCloseButton } from "./SignInSignUpCardStyled";

interface Props {
  body: ReactNode;
  footer?: ReactNode;
  isCloseRequired?: boolean;
  isCloseRequiredInMobile?: boolean;
  isCardMobile?: boolean;
  isAccountCreateModal?: boolean;
  isBackVisible?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  className?: string;
  isFaveConfirm?: boolean;
  isButtonTextRed?: boolean;
  isButtonTextVisible?: boolean;
  isSignUp?: boolean;
  curve?: boolean;
}

const SignInSignUpCard = ({
  body,
  footer,
  isCloseRequired = true,
  isCloseRequiredInMobile = true,
  isAccountCreateModal = false,
  isCardMobile = false,
  isBackVisible = false,
  isSignUp = false,
  curve,
  onClose = () => {
    // OnClose Method
  },
  onBack = () => {
    // OnBack Method
  },
  isFaveConfirm,
  className,
}: Props) => {
  return (
    <Card
      isSignUp={isSignUp}
      isCardMobile={isCardMobile}
      isBackVisible={isBackVisible}
      className={className}
      isAccountCreateModal={isAccountCreateModal}
    >
      <div className="back-close-button-wrapper">
        {isBackVisible && (
          <CardBackButton type="button" variant="twelveth" onClick={onBack} className="backButton">
            {isFaveConfirm ? "BACK TO CART" : "Back"}
          </CardBackButton>
        )}
        {isCloseRequired && (
          <CardCloseButton
            type="button"
            className="authCardClose"
            variant="twelveth"
            onClick={onClose}
            isCloseRequiredInMobile={isCloseRequiredInMobile}
            showText={false}
          />
        )}
      </div>

      {body && <div className="cardBody">{body}</div>}
      {curve && (
        <div className="curve">
          <Image src={CURVE} alt="Curve" />
        </div>
      )}
      {footer && <div className="cardFooter">{footer}</div>}
    </Card>
  );
};

export default SignInSignUpCard;
