export const USER_GENERIC_ERROR_CODE = 1100;
export const USER_NOT_FOUND_CODE = 1101;
export const INVALID_CREDENTIALS_CODE = 1102;
export const ACCESS_TOKEN_INVALID_CODE = 1103;
export const USER_NOT_CONFIRMED_CODE = 1104;
export const PASSWORD_RESET_REQUIRED_CODE = 1105;
export const MIGRATED_USER_PASSWORD_RESET_REQUIRED_CODE = 1106;
export const USER_EMAIL_ALREADY_EXIST_CODE = 1107;
export const USER_PHONE_ALREADY_EXIST_CODE = 1108;
export const ITEM_NOT_FOUND_ERROR_CODE = 1001;
export const USER_EXCEEDED_PASSWORD_RESET_LIMIT_ERROR_CODE = 1113;
export const USER_PROFILE_PHONE_VERIFICATION_LIMIT_EXCEEDED = 5204;
export const NO_SAVED_VEHICLES_FOUND_ERROR_CODE = 6001;
