import styled from "styled-components";

export const GetStartedComfirmationStyled = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 100px;
  .getStartedStyled {
    border-radius: 40px;
    @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
      height: auto;
    }
  }
  .notAvailableForDelivery {
    background: ${(props) => props.theme.color.core.white};
    position: relative;
    .modalNavigatiomButton {
      position: absolute;
      top: 30px;
      right: 30px;
      width: auto;
    }
    .heading-container {
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
      padding-top: 50px;
      margin-top: 150px;
      padding-bottom: 25px;
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 41px;
      .sub-heading {
        margin-bottom: 25px;
        font-family: ${(props) => props.theme.font.scriptRough};
        color: ${(props) => props.theme.color.core.blue};
      }
      .describtion {
        padding-left: 1px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: ${(props) => props.theme.color.core.blue};
      }
    }
  }
  .notAvailableForDeliveryContainer {
    @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
      margin-top: -70px;
    }
  }
`;
