import DeliveryCardOutsideRadius from "components/OrderNow/AddressSelectedCard/DeliveryCardOutsideRadius/DeliveryCardOutsideRadius";
import OrderPageContainer from "../OrderPageContainer/OrderPageContainer";

const AddressOutOfRadius = () => {
  return (
    <OrderPageContainer mobileLayout="REBRAND_SCREEN">
      <DeliveryCardOutsideRadius />
    </OrderPageContainer>
  );
};

export default AddressOutOfRadius;
