import styled from "styled-components";

import { responsive } from "theme/responsive";
import { mobileLayoutRebrandScreen } from "./OrderPageContainerMobileLayoutStyled";

import { MobileLayout } from "./types";

interface Props {
  mobileLayout: MobileLayout;
}

export const OrderPageContainerStyled = styled.div<Props>`
  position: relative;
  top: 0px;
  left: 0px;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  scrollbar-width: none;
  .circle {
    cursor: pointer;
    z-index: 9999;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.color.primary.foundersBlue};
  }
  &::-webkit-scrollbar {
    display: none;
  }

  .childrenMobile {
    display: none;

    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      display: block;

      & > div {
        padding: 24px;
        background-color: ${(props) => props.theme.color.core.white};
      }
    }
  }

  .childrenDesktop {
    display: block;

    & > * {
      position: relative;
      z-index: 58;
    }

    .deliveryOutOfRadius,
    .deliveryCardWrapper {
      box-shadow: none;
    }
    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      display: none;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .childrenDesktop {
      display: none;
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .zoomButtons {
      display: none;
    }
  }

  .content {
    position: relative;
    display: grid;
    padding: 24px;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;

    .closeButton {
      align-self: flex-start;
      z-index: 100;
      position: fixed;
      right: 24px;
      top: 24px;
      cursor: pointer;

      @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
        display: none;
      }
    }

    .zoomButtons {
      grid-column: 2;
      align-self: flex-end;
      justify-self: flex-end;
      z-index: 57;
      position: fixed;
      bottom: 24px;
      right: 30px;
    }

    & > .backButton {
      display: none;
    }

    .gpsIcon {
      position: fixed;
      bottom: 249px;
      right: 42px;
      z-index: 57;
    }
  }

  ${(props) => props.mobileLayout === "REBRAND_SCREEN" && responsive.max.lg(`${mobileLayoutRebrandScreen}`)}

  .nearbyWrapper {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: 190px;
  }

  .map {
    z-index: 57;
    position: fixed !important;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    .gmnoprint {
      display: none;
    }
  }

  .nearByStoreAllDetails {
    .selectedCardContainer {
      margin: auto;
    }
  }
`;
