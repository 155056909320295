import styled from "styled-components";
import LabelWithStar from "components/Account/AccountCard/LabelWithStar/LabelWithStar";
import NavButton from "components/atomic-components/atoms/button/NavButton/NavButton";

export const BackButtonStyled = styled(NavButton)`
  justify-content: flex-start !important;
  height: 22px;
  font-size: 15px;

  .iconWrapper {
    height: 12px !important;
    width: 8px !important;
  }
`;

export const AddressDeliveryCardStyled = styled.div`
  height: 100%;
  width: 100%;

  .addressLine {
    font-family: ${(props) => props.theme.font.serifHeavy};
    color: ${(props) => props.theme.color.core.blue};
    font-size: 14px;
    text-decoration: underline;
    align-self: flex-start;
    margin-bottom: 4px;
    &.large {
      font-size: 18px;
    }
  }

  .address-wrapper {
    margin-top: 10px;
    display: flex;
    align-items: end;
    width: 100%;
  }

  .backButton {
    margin-bottom: 30px;
    align-self: flex-start;
  }

  .cardBody {
    .outOfRangeCardWrapper {
      .locationCardTile {
        .milesAndDetails {
          width: 100%;
          .faveAddress {
            width: 100%;
            .iconWrapper {
              width: 100%;
              padding-left: 16px;
              padding-right: 0;
            }
          }
        }

        .addressWrapper {
          padding-bottom: 10px;
        }
      }
    }
  }

  .select-address {
    width: 112px;
  }
  .underlinedHeadingSelected {
    align-self: flex-start;
  }
  .underlinedHeading {
    position: absolute;
    left: 11px;
    top: -14px;
    margin-top: 0;
  }

  ${(props) => props.theme.responsive.max.lg} {
    border-radius: 0;
    box-shadow: none;

    &.deliveryCardWrapper,
    &.deliveryOutOfRadius {
      > div {
        border-radius: 0;
        box-shadow: none;
      }
    }
  }
`;

export const FavouriteMessage = styled(LabelWithStar)<{
  disableFavourite: boolean;
}>`
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-bottom: 20px;
  opacity: ${(props) => props.disableFavourite && "0.5"};
  cursor: pointer;
  pointer-events: ${(props) => props.disableFavourite && "none"};
`;
