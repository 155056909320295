import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";

interface CustomRumInitConfiguration extends RumInitConfiguration {
  sessionSampleRate: number;
}

export default class DataDogAnalytics {
  static instance: DataDogAnalytics;
  public static getInstance(): DataDogAnalytics {
    if (!DataDogAnalytics.instance) {
      DataDogAnalytics.instance = new DataDogAnalytics();
    }
    return DataDogAnalytics.instance;
  }

  public initDataDog() {
    // https://docs.datadoghq.com/real_user_monitoring/browser/
    const config: CustomRumInitConfiguration = {
      applicationId: `${process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID}`,
      clientToken: `${process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN}`,
      site: "datadoghq.com",
      service: "zaxbys-web",
      env: `${process.env.NEXT_PUBLIC_APP_ENV}`,
      version: `${process.env.NEXT_PUBLIC_APP_VERSION}`,
      trackInteractions: true,
      sessionSampleRate: Number(process.env.NEXT_PUBLIC_DATADOG_SAMPLE_RATE),
      allowedTracingOrigins: [
        "https://auth.zaxbys.com",
        "https://cms.zaxbys.com",
        "https://medley.zaxbys.com",
        "https://zapi.zaxbys.com",
        /https:\/\/.*\.qa\.zaxbys\.com/,
        /https:\/\/.*\.uat\.zaxbys\.com/,
        /https:\/\/.*\.dev\.zaxbys\.com/,
      ],
    };
    datadogRum.init(config);
  }

  public datadogAddError(error: object, errorInfo: any) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    datadogRum.addError(error, errorInfo);
  }
}
