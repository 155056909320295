import { useRouter } from "next/router";
import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";

interface Context {
  isMobileNavOpen?: boolean;
  toggleMobileNav?: () => void;
  isDropdownExpanded?: boolean;
  toggleDropdownExpanded?: () => void;
}

export const MobileMenuContext = createContext({} as Context);

export const useMobileMenu = () => useContext(MobileMenuContext);

export const MobileMenuProvider = ({ children }: PropsWithChildren) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
  const { pathname } = useRouter();

  const toggleMobileNav = () => {
    setMobileNavOpen((prev) => !prev);
  };

  const toggleDropdownExpanded = () => {
    setIsDropdownExpanded((prev) => !prev);
  };

  useEffect(() => {
    setMobileNavOpen(false);
    setIsDropdownExpanded(false);
  }, [pathname]);

  useEffect(() => {
    if (isMobileNavOpen) {
      document.body.classList.add("mobileNavOpen");
    } else {
      document.body.classList.remove("mobileNavOpen");
    }
  }, [isMobileNavOpen]);

  const value = {
    isMobileNavOpen,
    toggleMobileNav,
    isDropdownExpanded,
    toggleDropdownExpanded,
  };

  return <MobileMenuContext.Provider value={value}>{children}</MobileMenuContext.Provider>;
};
