import Image from "next/image";
import { PropsWithChildren } from "react";
import { NavigationButtonProps } from "../types";
import { NavigationButtonStyled } from "./NavigationButtonStyled";

type NavigationButtonPropsExtended = NavigationButtonProps & {
  imgPosition: "left" | "right";
  imgSrc: string;
};

const NavigationButton = ({ variant, imgPosition, imgSrc, children, ...rest }: PropsWithChildren<NavigationButtonPropsExtended>) => {
  return (
    <NavigationButtonStyled variant={variant} {...rest}>
      {imgPosition === "left" && <Image src={imgSrc} alt="thick arrow" />}
      {children}
      {imgPosition === "right" && <Image src={imgSrc} alt="thick arrow" />}
    </NavigationButtonStyled>
  );
};

export default NavigationButton;
