import { ChangeEvent } from "react";
import { HiddenInput, ToggleInputStyled } from "./ToggleInputStyled";

interface ToggleInputProps {
  optionFalse: string;
  optionTrue: string;
  name: string;
  value: boolean;
  className?: string;
  onChange: (name: string, value: boolean) => void;
  id?: string;
  isFromCommunication?: boolean;
}

const ToggleInput = ({
  optionFalse,
  optionTrue,
  name,
  id,
  value,
  onChange,
  isFromCommunication = false,
  className,
  ...rest
}: ToggleInputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, checked } = e.target;
    onChange(inputName, checked);
  };

  return (
    <ToggleInputStyled className={className} isFromCommunication={isFromCommunication}>
      <HiddenInput type="checkbox" onChange={handleChange} checked={value} name={name} id={id} {...rest} />
      <label htmlFor={id} className="label">
        <span>{optionFalse}</span>
        <span className="activeOption">{optionTrue}</span>
      </label>
    </ToggleInputStyled>
  );
};

export default ToggleInput;
