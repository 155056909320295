import { createContext, Dispatch, SetStateAction } from "react";
import { CodeDeliveryMedium, CodeDeliveryOptions, ForgotPasswordStep } from "./types";

interface Context {
  step: ForgotPasswordStep;
  code: string;
  medium: CodeDeliveryMedium;
  options: CodeDeliveryOptions;
  username: string;
  setStep: Dispatch<SetStateAction<ForgotPasswordStep>>;
  setCode: Dispatch<SetStateAction<string>>;
  setMedium: Dispatch<SetStateAction<CodeDeliveryMedium>>;
  setOptions: Dispatch<SetStateAction<CodeDeliveryOptions>>;
  setUsername: Dispatch<SetStateAction<string>>;
}

export default createContext<Context>({} as Context);
