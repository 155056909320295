import styled from "styled-components";

export const LocationCardTileStyled = styled.div<{ isSelectable?: boolean; hasTitle?: boolean }>`
  cursor: ${({ isSelectable }) => (isSelectable ? "pointer" : "default")};
  width: 342px;
  min-height: ${({ hasTitle }) => (hasTitle ? "120px" : "unset")};
  margin-top: 20px;
  background: ${({ theme }) => theme.color.primary.coleslawWhite};
  position: relative;

  .horizontalLine {
    width: auto;
    margin: 8px 20px 0 16px;
    height: 1px;
    background: ${(props) => props.theme.color.secondary.coolGray7};
  }

  ${(props) => props.theme.responsive?.max.lg} {
    width: 100%;
  }
`;

export const MilesAndDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TotalMilesWrapper = styled.div`
  min-width: 96px;
  width: fit-content;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.primary.foundersBlue};
  .mileText {
    color: ${({ theme }) => theme.color.core.white};
  }

  ${(props) => props.theme.responsive?.max.lg} {
    .mileText {
      font-weight: 700;
      font-size: 16px;
    }
  }
`;

export const FaveAddressWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  .detailText {
    text-decoration: underline;
    cursor: pointer;
    padding-right: 20px;
  }
  .noFavouriteIcon {
    padding-right: 0;
    font-size: 14px;
    line-height: 17px;
  }
  .iconWrapper {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: 20px;
    .favIconWrapper {
      width: 18.13px;
      height: 16px;
      position: relative;
      margin-top: 12px;
    }
  }

  .addressTypeWrapper {
    width: 100%;
    padding-top: 20px;
    line-height: 12px;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    .detailText {
      font-weight: 700;
      font-size: 14px;
    }
  }
`;

export const AddressWrapper = styled.div<{ isDeliveryAddress?: boolean; hasTitle?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  padding: ${({ isDeliveryAddress }) => (isDeliveryAddress ? "10px 20px 20px 16px" : "17px 20px 20px 16px")};
  padding-top: ${({ hasTitle }) => !hasTitle && "0"};
  .addressSub {
    padding-top: 6px;
  }

  .mt-minus-8 {
    margin-top: -8px;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    .addressSub {
      font-weight: 500;
      font-size: 14px;
    }
  }
`;

export const AddressDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .addressLineOne {
    line-height: normal;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    .addressLineOne {
      font-size: 20px;
      font-weight: 600;
    }
    .addressSub {
      font-weight: 500;
      font-size: 14px;
    }
  }
`;

export const AddressTimeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .selectButton {
    width: 124px;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    .addressSub {
      font-weight: 500;
      font-size: 14px;
    }

    .selectButton {
      width: 96px;
    }
  }
`;
