import { CareersAction, CareersState } from "./types";

const initialState: CareersState = {
  careersStore: null,
  clearCache: true,
};

export const CareersReducer = (state = initialState, action: CareersAction) => {
  switch (action.type) {
    case "SET_CAREERS_STORE":
      return { ...state, careersStore: action.payload };

    case "RESET_CAREERS_STORE":
      return { ...state, careersStore: null };

    case "SET_CLEAR_CACHE":
      return { ...state, clearCache: action.payload };

    default:
      return state;
  }
};
