import styled from "styled-components";

export const ViolatorBarStyled = styled.div<{ role?: string; isHomePage?: boolean; noOffsetTop?: boolean; pathname?: string }>`
  display: block;
  background-size: cover;
  position: relative;

  ${(props) =>
    props.noOffsetTop &&
    `
    padding-top: 0 !important;
  `};

  .responsivePictures {
    height: max-content;
    position: relative;
    top: 50%;
    z-index: 3;
    transform: translateY(-50%);

    .responsivePictureImage {
      margin: 0 auto;
    }
  }

  .responsivePicturesBg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    .responsivePictureImage {
      width: 100%;
      height: 100%;
    }
  }

  .link {
    text-decoration: none;
    text-transform: none;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    padding-top: ${(props) => (props.isHomePage ? "26px" : 0)};
    border-top: 1px solid ${(props) => props.theme.color.secondary.coolGray4};
    border-bottom: 1px solid ${(props) => props.theme.color.secondary.coolGray4};
  }
`;
