import { Dispatch } from "redux";
import { getHomeCMSData } from "api/cms/home/get-home-cms-data";
import { getSigninSignupMessage } from "api/cms/home/get-signin-signup-message";
import { getCheckoutCMSData } from "api/cms/checkout/get-checkout-cms";
import { getFooterCMSData } from "api/cms/footer/get-footer-cms";
import { getStoreDetailsCMSData } from "api/cms/store-details/get-store-details-cms-data";
import { getMenuCMSData } from "api/cms/menu/get-menu-cms";
import { getViolatorCMSData } from "api/cms/violator/get-violator-info";
import { getRetiredMenuCMSData } from "api/cms/retired-menu/get-retired-menu";
import { getRestaurantOpportunitiesData } from "api/restaurant-oppotunities/get-restaurant-opportunities-cms";
import { getArticlesCMSData } from "api/cms/articles/get-articles-cms";
import { getNewsCMSData } from "api/cms/news/get-news-cms";
import { getContactUsCMSData } from "api/cms/contact-us/get-contact-us-cms";
import { getErrorDownCMSData } from "api/cms/error-down/get-error-down-cms";
import { getError404CMSData } from "api/cms/error-404/get-error-404-cms";
import { getCorporateCMSData } from "api/cms/corporate/get-corporate-cms";
import { getLocationVerificationCheckoutCMSData } from "api/cms/checkout/get-location-verification-checkout-cms";
import { getCMSDataForCheckout } from "api/cms/checkout/get-cms-checkout-data";
import { getCMSDataForWebCart } from "api/cms/webcart/get-cms-webcart-data";
import { Action } from "./types";

import {
  SET_HOME_DATA,
  SET_CHECKOUT_DATA,
  SET_FOOTER_DATA,
  SET_MENU_DATA,
  SET_STORE_DETAILS_DATA,
  SET_VIOLATOR_DATA,
  CLOSE_VIOLATOR_BAR,
  SET_RETIRED_MENU,
  SET_RESTAURANT_OPPORTUNITIES_DATA,
  SET_IS_ARTICLES_LOADED,
  PROLIFERATE_ARTICLES_DATA,
  SET_NEWS_PAGE_DATA,
  SET_ALL_ARTICLES_LOADED,
  SET_INITIAL_ARTICLES_DATA,
  SET_CONTACT_US_DATA,
  SET_DOWN_DATA,
  SET_404_DATA,
  SET_CORPORATE_DATA,
  CMS_IS_LOADING,
  SET_SIGNUP_SIGNIN_MESSAGE,
  SET_LOCATION_VERIFICATION_CHECKOUT_POPUP_DATA,
  SET_CHECKOUT_CMS_DATA,
  SET_EXCLUDE_TAX_TEXT,
} from "./CMSConst";

export const setHomeCMSData = () => (dispatch: Dispatch<Action>) => {
  getHomeCMSData()
    .then((res) => dispatch({ type: SET_HOME_DATA, payload: res.response }))
    .catch(() => dispatch({ type: SET_HOME_DATA, payload: null }));
};

export const setSigninSignupMessage = () => (dispatch: Dispatch<Action>) => {
  getSigninSignupMessage()
    .then((res) => dispatch({ type: SET_SIGNUP_SIGNIN_MESSAGE, payload: res.response }))
    .catch(() => dispatch({ type: SET_SIGNUP_SIGNIN_MESSAGE, payload: null }));
};

export const setCheckoutCMSData = () => (dispatch: Dispatch<Action>) => {
  getCheckoutCMSData()
    .then((res) => dispatch({ type: SET_CHECKOUT_DATA, payload: res.response }))
    .catch(() => dispatch({ type: SET_CHECKOUT_DATA, payload: null }));
};

export const setCMSDataForCheckout = () => (dispatch: Dispatch<Action>) => {
  getCMSDataForCheckout()
    .then((res) => {
      dispatch({ type: SET_CHECKOUT_CMS_DATA, payload: res.response });
    })
    .catch(() => dispatch({ type: SET_CHECKOUT_CMS_DATA, payload: null }));
};

export const setExcludeTaxText = () => (dispatch: Dispatch<Action>) => {
  getCMSDataForWebCart()
    .then((res) => {
      dispatch({ type: SET_EXCLUDE_TAX_TEXT, payload: res.response });
    })
    .catch(() => dispatch({ type: SET_EXCLUDE_TAX_TEXT, payload: null }));
};

export const setFooterCMSData = () => (dispatch: Dispatch<Action>) => {
  getFooterCMSData()
    .then((res) => dispatch({ type: SET_FOOTER_DATA, payload: res.response }))
    .catch(() => dispatch({ type: SET_FOOTER_DATA, payload: null }));
};

export const setStoreDetailsCMSData = () => (dispatch: Dispatch<Action>) => {
  getStoreDetailsCMSData()
    .then((res) => dispatch({ type: SET_STORE_DETAILS_DATA, payload: res.response }))
    .catch(() => dispatch({ type: SET_STORE_DETAILS_DATA, payload: null }));
};

export const setMenuCMSData = () => (dispatch: Dispatch<Action>) => {
  getMenuCMSData()
    .then((res) => dispatch({ type: SET_MENU_DATA, payload: res.response }))
    .catch(() => dispatch({ type: SET_MENU_DATA, payload: null }));
};

export const setViolatorCMSData = () => (dispatch: Dispatch<Action>) => {
  getViolatorCMSData()
    .then((res) => dispatch({ type: SET_VIOLATOR_DATA, payload: res.response }))
    .catch(() => dispatch({ type: SET_VIOLATOR_DATA, payload: null }));
};

export const closeViolatorBar = () => (dispatch: Dispatch<Action>) => dispatch({ type: CLOSE_VIOLATOR_BAR });

export const setRetiredMenuData = () => (dispatch: Dispatch<Action>) =>
  getRetiredMenuCMSData()
    .then((res) => dispatch({ type: SET_RETIRED_MENU, payload: res.response }))
    .catch(() => dispatch({ type: SET_RETIRED_MENU, payload: null }));

export const setRestaurantOpportunitiesData = () => (dispatch: Dispatch<Action>) =>
  getRestaurantOpportunitiesData()
    .then((res) => dispatch({ type: SET_RESTAURANT_OPPORTUNITIES_DATA, payload: res.response }))
    .catch(() => dispatch({ type: SET_RESTAURANT_OPPORTUNITIES_DATA, payload: null }));

export const setContactUsData = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: CMS_IS_LOADING, payload: true });
    getContactUsCMSData()
      .then((res) => dispatch({ type: SET_CONTACT_US_DATA, payload: res.response }))
      .catch(() => dispatch({ type: SET_CONTACT_US_DATA, payload: null }));
  };
};

export const proliferateArticlesData = (pageNumber: number, itemsPerPage: number) => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: SET_IS_ARTICLES_LOADED, payload: false });
  try {
    const articlesData = await getArticlesCMSData(pageNumber, itemsPerPage);
    if (articlesData.response.length === 0) {
      dispatch({ type: SET_ALL_ARTICLES_LOADED });
    } else {
      dispatch({
        type: PROLIFERATE_ARTICLES_DATA,
        payload: articlesData.response,
      });
    }
  } catch {
    dispatch({ type: PROLIFERATE_ARTICLES_DATA, payload: null });
  }
};

export const setNewsPageData = () => async (dispatch: Dispatch<Action>) => {
  try {
    const newsPageCMSResponse = await getNewsCMSData();
    dispatch({
      type: SET_NEWS_PAGE_DATA,
      payload: newsPageCMSResponse.response,
    });
  } catch {
    dispatch({ type: SET_NEWS_PAGE_DATA, payload: null });
  }
};

export const setInitialArticlesData = (articlesPerPage: number) => async (dispatch: Dispatch<Action>) => {
  try {
    const initialArticlesResponse = await getArticlesCMSData(0, articlesPerPage);
    dispatch({
      type: SET_INITIAL_ARTICLES_DATA,
      payload: initialArticlesResponse.response,
    });
  } catch {
    dispatch({ type: SET_INITIAL_ARTICLES_DATA, payload: null });
  }
};

export const setErrorDownData = () => async (dispatch: Dispatch<Action>) => {
  try {
    const errorDownData = await getErrorDownCMSData();
    dispatch({ type: SET_DOWN_DATA, payload: errorDownData.response });
  } catch {
    dispatch({ type: SET_DOWN_DATA, payload: null });
  }
};

export const setError404Data = () => async (dispatch: Dispatch<Action>) => {
  try {
    const error404Data = await getError404CMSData();
    dispatch({ type: SET_404_DATA, payload: error404Data.response });
  } catch {
    dispatch({ type: SET_404_DATA, payload: null });
  }
};

export const setCorporateData = () => async (dispatch: Dispatch<Action>) => {
  try {
    const corporateData = await getCorporateCMSData();
    dispatch({ type: SET_CORPORATE_DATA, payload: corporateData.response });
  } catch {
    dispatch({ type: SET_CORPORATE_DATA, payload: null });
  }
};

export const setLocationVerificationCheckoutData = () => async (dispatch: Dispatch<Action>) => {
  try {
    const LocationVerificationCheckoutPopupData = await getLocationVerificationCheckoutCMSData();
    dispatch({ type: SET_LOCATION_VERIFICATION_CHECKOUT_POPUP_DATA, payload: LocationVerificationCheckoutPopupData.response });
  } catch {
    dispatch({ type: SET_LOCATION_VERIFICATION_CHECKOUT_POPUP_DATA, payload: null });
  }
};
