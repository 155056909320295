import Image from "next/image";
import { IC_GPS_ICON } from "assets/images";
import { GpsIconStyled } from "./GpsIconStyled";

interface Props {
  className?: string;
  onClick?: () => void;
}

const GpsIcon = ({ className, onClick }: Props) => {
  return (
    <GpsIconStyled className={className} onClick={onClick}>
      <Image src={IC_GPS_ICON} alt="Gps Icon" />
    </GpsIconStyled>
  );
};

export default GpsIcon;
