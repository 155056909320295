import styled from "styled-components";

export const NearByCardStyled = styled.div`
  .carousel-slider {
    > button {
      bottom: 0;
      z-index: 60;
      background: ${(props) => props.theme.color.secondary.pantone290};
    }
  }
  .slider-wrapper {
    width: auto;
    .slide {
      margin-right: 20px;
      text-align: unset;
    }
  }
`;
