import styled from "styled-components";
import { ModalStyled } from "../Modal/ModalStyled";

export const ScrollableModalStyled = styled(ModalStyled)`
  .modal-style {
    border-radius: 20px;
    overflow: hidden;
  }
  .head {
    background-color: ${(props) => props.theme.color?.primary.foundersBlue};
    position: relative;
    height: 56px;
    display: flex;
    align-items: center;

    .show-backbutton {
      position: relative;
      margin-left: 28px;
      margin-right: auto;
      top: unset;
      left: unset;
    }

    .close-button {
      position: relative;
      margin-left: auto;
      margin-right: 28px;
      top: unset;
      right: unset;
    }
  }
`;
