// Default radius search
export const defaultRadiusRange = "30";
export const DEFAULT_RADIUS_IN_METRE = 48280;
export const DEFAULT_PRODUCT_URL = "/menu-category/products";
export const initialState = {
  delivery: false,
  curbsidePickUp: false,
  driveThruPickUp: false,
  openLate: false,
  freeWifi: false,
  cateringManagerOnsite: false,
  cateringDelivery: false,
};
export const DEFAULT_LAT = 33.909037349837035;
export const DEFAULT_LNG = -83.45438362904083;
export const DEFAULT_ZOOM_LEVEL = 15;
export const tipPercentage = [15, 18, 20];
export const defaultActiveTip = tipPercentage[0]; // Default tip can be set a number or array index
export const otherTipAmount = "otherTip";
export const tipCustomAmount = "tipCustomAmount";
export const faveRecentConfirmation = "Faves";

export const ORDER_STATUS_API_DELAY = 10000;
export const pickupOptionConst = ["drivethru", "curbside", "instore"];
export const successOrderStatus = ["order-placed"];
export const errorOrderStatus = ["validation-error", "submission-error"];
export const progressOrderStatus = ["validating", "submitting"];
export const orderReceivedNotificationTime = "RECEIVED_PROGRESS_SHOW_UNTIL";
export const DEFAULT_NUMBER_OF_VEHICLES = 10;
export const DEFAULT_VEHICLE_NAME_LENGTH_MOBILE = 23;
export const DEFAULT_VEHICLE_NAME_LENGTH_DESKTOP = 37;

export const APP_STORE_LOGO = "https://apps.apple.com/us/app/zaxbys/id480603264";
export const GOOGLE_STORE_LOGO = "https://play.google.com/store/apps/details?id=com.snapfinger.mobile.zaxbys&hl=en_IN&gl=US";

export const TWITTER_URL = "https://twitter.com/Zaxbys";
export const FACEBOOK_URL = "https://www.facebook.com/Zaxbys";
export const INSTAGRAM_URL = "https://www.instagram.com/realzaxbys";
export const YOUTUBE_URL = "https://www.youtube.com/user/zaxbysvideo";
export const GOOGLE_BUSINESS_URL = "https://www.google.com";
export const TIK_TOK_URL = "https://www.tiktok.com/@zaxbys";
export const DELIVERY = "Delivery";
export const PICKUP = "Pickup";
export const PICKUP_ORDER_TYPE = "PICKUP";
export const DELIVERY_ORDER_TYPE = "DELIVERY";

export const prefixText = "Please Use Contactless Delivery.";
export const INVALID_TIME = "Invalid Time";
export const driverNoteMaxLength = 96;
export const PURCHASE_EGIFT_CARD_URL = "https://www.buyatab.com/custom/Zaxbys/?page=egift&accessible=null%20%5bbuyatab.com%5d";
export const PURCHASE_GIFT_CARD_URL = "https://www.buyatab.com/custom/zaxbys/?page=physical";
export const GIFT_CARD_BALANCE_URL = "https://zaxbys.myguestaccount.com/guest/nologin/account-balance";
export const GIFT_CARD_CONTACT_US = "https://app.smartsheet.com/b/form/2c1aadb85cf04e4e8ba4f6461b02c2a2";
export const GIFT_CARD_SECTION = "giftcards";
export const SELECT_GIFT_CARD = "Select Gift Card";
export const SAVED_CREDIT_CARD = "saved-creditcard";
export const CREDIT_CARD = "creditcard";
export const SAVED_GIFT_CARD = "saved-giftcard";
export const SMARTY_STREET_MAX_RESULTS = 10;
export const ORDER_MOVED_TO_DRIVETHRU = "Based on your food prep time, your order has been moved to Drive-Thru";
export const ORDER_UPDATE_MESSAGE = "The next available pickup time may be different than expected.";
export const defaultOffsetTime = 0;
export const APPLY_REWARD = "Apply Reward";
export const SCAN_INSTORE = "Scan In Store";
export const VIEW_TERMS = "View Terms";
export const REWARDS_DOWNLOAD_APP_MESSAGE = "Download the Zaxbys App to redeem!";
export const CHANGE_REWARD_SELECTION_MESSAGE =
  "Only one reward is allowed per order, but you can switch to a different reward in the cart.";
export const ONLINE_ONLY = "online_only";
export const OFFLINE_ONLY = "offline_only";
export const ALL = "all";
export const CURBSIDE = "curbside";
export const ORDERPLACED = "orderplaced";
export const VALID_PHONE_NUMBER = "We’ll text a verification code to this number";
export const PARKING_SPACE_ERROR = "PLEASE ENTER A PARKING SPACE";
export const DEFAULT_PRIOR_CURBSIDE_READY_TIME_IN_SECONDS = 300;
export const DEFAULT_ADDITIONAL_CURBSIDE_READY_TIME_IN_SECONDS = 1800;
export const DEFAULT_INITIAL_CURBSIDE_EVENT_TIME_INTERVAL = 60;
export const DEFAULT_APICALL_CURBSIDE_EVENT_TIME_INTERVAL = 300;
export const HOME_PAGE_RIBBONBANNER_TEXT_LENGTH = 32;
export const HOME_PAGE_RIBBON_BANNER_DELAY = 2000;

export const REWARD_CARD = {
  DESKTOP_TITLE_MAX_LENGTH: 43,
  DESKTOP_DESCRIPTION_MAX_LENGTH: 48,
  MOBILE_TITLE_MAX_LENGTH: 20,
  MOBILE_DESCRIPTION_MAX_LENGTH: 29,
  REWARD_DESCRIPTION_MAX_LINES_TO_SHOW: 2,
  REWARD_FLAG_MAX_LENGTH: 24,
  CART_PAGE_DESKTOP_TITLE_MAX_LENGTH: 22,
  CART_PAGE_MOBILE_TITLE_MAX_LENGTH: 29,
  CART_PAGE_DESCRIPTION_MAX_LENGTH: 25,
};
export const REWARD_CARD_POP_UP = {
  DESKTOP_TITLE_MAX_LENGTH: 18,
  DESKTOP_DESCRIPTION_MAX_LENGTH: 34,
  MOBILE_TITLE_MAX_LENGTH: 18,
  MOBILE_DESCRIPTION_MAX_LENGTH: 34,
  REWARD_FLAG_MAX_LENGTH: 24,
  REWARD_TITLE_MAX_LINES_TO_SHOW: 3,
  REWARD_DESCRIPTION_MAX_LINES_TO_SHOW: 4,
};
export const FORBIDDEN_ERROR_MESSAGE = "THERE IS AN ISSUE WITH YOUR CREDENTIALS. PLEASE RESET YOUR PASSWORD.";

export const MENU_CARD_TYPE = {
  SPOTLIGHT_CARD: "SPOTLIGHT_CARD",
  NON_SPOTLIGHT_CARD: "NON_SPOTLIGHT_CARD",
};

export const SLIDER_CARD_TYPE = {
  ZAX_CARD: "ZAX_CARD",
  RECENT_ORDER_CARD: "RECENT_ORDER",
};

export const MENU_TAB_ITEMS = {
  MENU: "Menu",
  FAVES: "Faves",
  RECENTS: "Recent",
};

export const PICKUP_TABS = [
  {
    id: "search",
    name: "SEARCH",
  },
  {
    id: "faveStores",
    name: "FAVE STORES",
  },
  {
    id: "recent",
    name: "RECENT",
  },
];
export const DELIVERY_TABS = [
  {
    id: "search",
    name: "SEARCH",
  },
  {
    id: "faveAddresses",
    name: "FAVE ADDRESSES",
  },
];

export const ACCOUNTS_FOOTER_LINKS = [
  {
    accountsfooterName: "FAQ",
    accountsfooterLink: "/faq",
  },
  {
    accountsfooterName: "REWARDS POLICY",
    accountsfooterLink: "/legal/rewards-policy",
  },
  {
    accountsfooterName: "MOBILE TEXT TERMS OF USE",
    accountsfooterLink: "/legal/mobile-text-terms-of-use",
  },
  {
    accountsfooterName: "PRIVACY POLICY",
    accountsfooterLink: "/legal/privacy-policy",
  },
  {
    accountsfooterName: "TERMS OF USE",
    accountsfooterLink: "/legal/terms-of-use",
  },
];

export const milesInArray = [
  {
    id: "10",
    value: "10",
  },
  {
    id: "15",
    value: "15",
  },
  {
    id: "20",
    value: "20",
  },
  {
    id: "25",
    value: "25",
  },
  {
    id: "30",
    value: "30",
  },
];

export const amenitiesArray = [
  {
    id: "delivery",
    value: "delivery",
    label: "Delivery",
  },
  {
    id: "curbsidePickUp",
    value: "curbsidePickUp",
    label: "Curbside (Pickup)",
  },
  {
    id: "driveThruPickUp",
    value: "driveThruPickUp",
    label: "Drive-Thru",
  },
  {
    id: "openLate",
    value: "openLate",
    label: "Open Late",
  },
  {
    id: "freeWifi",
    value: "freeWifi",
    label: "Free WIFI",
  },
  {
    id: "cateringManagerOnsite",
    value: "cateringManagerOnsite",
    label: "Catering Manager",
  },
  {
    id: "cateringDelivery",
    value: "cateringDelivery",
    label: "Catering Delivery",
  },
];

export const birthMonths = [
  { value: "01", label: "JANUARY" },
  { value: "02", label: "FEBRUARY" },
  { value: "03", label: "MARCH" },
  { value: "04", label: "APRIL" },
  { value: "05", label: "MAY" },
  { value: "06", label: "JUNE" },
  { value: "07", label: "JULY" },
  { value: "08", label: "AUGUST" },
  { value: "09", label: "SEPTEMBER" },
  { value: "10", label: "OCTOBER" },
  { value: "11", label: "NOVEMBER" },
  { value: "12", label: "DECEMBER" },
];

export const ADD_NEW_DELIVERY_ADDRESS = "ADD A NEW DELIVERY";
export const EDIT_THIS_DELIVERY_ADDRESS = "EDIT THIS DELIVERY";
export const ADD_DELIVERY_ADDRESS_TEXT = "The start of something tasty.";
export const EDIT__DELIVERY_ADDRESS_TEXT = "Home is where the Zax goes.";

export const CONTENT_LOCATION = {
  HERO: "hero",
  LOYALTY: "loyalty",
  MARKETING: "marketing",
};

export const CONTENT_TYPES = {
  BRAZE: "Braze",
  PUNCH: "Punchh",
  CMS: "CMS",
};

export const SOMETHING_WENT_WRONG = "Something went wrong";
