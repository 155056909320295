import styled from "styled-components";

import { IMG_TEXTURED_BKGD_DARK_BLUE, IMG_TEXTURED_BKGD_DARK_BLUE_MOBILE } from "assets/images";

import BackButton from "components/global/Buttons/BackButton/BackButton";
import CloseButton from "components/global/Buttons/CloseButton/CloseButton";
interface CardCloseButtonProps {
  isCloseRequiredInMobile: boolean;
}
export const CardCloseButton = styled(CloseButton)<CardCloseButtonProps>`
  &.authCardClose {
    > div {
      width: 14px;
      height: 15px;
    }
  }
  position: absolute;
  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    top: 20px;
    display: ${(props: CardCloseButtonProps) => (props.isCloseRequiredInMobile ? "grid" : "none")};
    right: 20px;
    position: relative;
    margin-left: auto;
  }

  @media only screen and (min-width: ${(props) => props.theme.size.md}) {
    top: 30px;
    right: 30px;
    display: flex;
    position: absolute;
  }
`;
export const Card = styled.div<{
  isCardMobile: boolean;
  isBackVisible: boolean;
}>`
  height: 100%;
  position: relative;
  border-radius: ${(props) => (props.isCardMobile ? "40px" : "0")};
  margin: ${(props) => props.isCardMobile && "20px"};
  .cardBody {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.color.secondary.lightBlue};
    @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
      border-top-right-radius: 40px;
      border-top-left-radius: 40px;
    }
  }
  .cardFooter {
    padding: 50px 30px;
    @media only screen and (min-width: ${(props) => props.theme.size.md}) {
      background: url(${IMG_TEXTURED_BKGD_DARK_BLUE.src}),
        linear-gradient(
          to bottom,
          ${(props) => props.theme.color.secondary.lightBlue} 0%,
          ${(props) => props.theme.color.secondary.lightBlue} 15%,
          ${(props) => props.theme.color.core.blue} 15%
        );
      background-size: cover;
      background-repeat: no-repeat;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
    }
    @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
      background: url(${IMG_TEXTURED_BKGD_DARK_BLUE_MOBILE.src});
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &.loginModalCard {
    display: flex;
    flex-direction: column;
    .cardFooter {
      flex-grow: 1;
    }
  }
  &.verify-modal {
    .cardBody {
      border-radius: 40px;
      > div {
        > {
          & *:last-child {
            margin-top: 35px;
          }
        }
      }
    }
    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      .cardBody {
        padding-top: 20px;
      }
      &.verify-modal {
        .cardBody {
          > div {
            > {
              & *:last-child {
                margin-top: 50px;
              }
            }
          }
        }
      }
      > button {
        position: relative;
        margin-top: 20px;
        margin-left: 20px;
        top: 0;
        left: 0;
        &.authCardClose {
          display: none;
        }
      }
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
    border-radius: 40px;
  }
`;

export const CardBackButton = styled(BackButton)`
  position: absolute;
  grid-gap: 8px;

  @media only screen and (min-width: ${(props) => props.theme.size.xs}) {
    display: flex;
    left: 20px;
    top: 30px;
  }

  @media only screen and (min-width: ${(props) => props.theme.size.md}) {
    display: flex;
  }
`;
