import styled from "styled-components";

interface LabelWithStarStyledProps {
  isActive: boolean;
  variant: "WHITE" | "BLUE";
  isSelectable: boolean;
}

export const LabelWithStarStyled = styled.div<LabelWithStarStyledProps>`
  display: flex;
  align-items: center;
  &:hover,
  & *:hover {
    cursor: ${(props) => props.isSelectable && "pointer"};
  }

  .label {
    font-size: 12px;
    line-height: 14px;
    font-family: ${(props) => props.theme.font.sansRegular};
    margin-right: 10px;
  }

  color: ${(props) => (props.variant === "BLUE" ? props.theme.color.core.blue : "white")};
  color: ${(props) => props.isActive && props.theme.color.secondary.red};
`;
