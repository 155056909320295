import AddressSelectedCardDelivery from "components/OrderNow/AddressSelectedCard/AddressSelectedCardDelivery/AddressSelectedCardDelivery";
import OrderPageContainer from "../OrderPageContainer/OrderPageContainer";
import useAddressSelected from "./useAddressSelected";
import { AddressSelectedStyled } from "./AddressSelectedStyled";

const AddressSelected = () => {
  const { handleBack, handleConfirmAddress, handleDeliverHere } = useAddressSelected();

  return (
    <AddressSelectedStyled>
      <OrderPageContainer mobileLayout="REBRAND_SCREEN">
        <AddressSelectedCardDelivery
          etaMin={20}
          etaMax={40}
          onBack={handleBack}
          onConfirmAddress={handleConfirmAddress}
          onDeliverHere={handleDeliverHere}
        />
      </OrderPageContainer>
    </AddressSelectedStyled>
  );
};

export default AddressSelected;
