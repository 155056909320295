import { PropsWithChildren } from "react";
import { ParagraphProps, ParagraphStyled } from "./ParagraphStyled";

const Paragraph = ({ children, ...rest }: PropsWithChildren<ParagraphProps>) => {
  return (
    <ParagraphStyled {...rest} tabIndex={0}>
      {children}
    </ParagraphStyled>
  );
};

export default Paragraph;
