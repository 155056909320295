import Link from "next/link";
import styled from "styled-components";

const LinkContainerStyled = styled.span`
  cursor: pointer;
  a {
    &.underlineContactUs {
      text-decoration: underline;
    }
    text-decoration: none;
    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      &.headerLogo {
        height: 33px;
        > div {
          height: 33px;
        }
      }
    }
  }
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
`;

export { LinkStyled, LinkContainerStyled };
