import { useState } from "react";
import Image from "next/image";
import theme from "theme/theme";
import { FranchiseNavLink } from "types/cms/footer";
import { FooterLogoContainer, FooterLogoLinks, FrachisorCopyrightNotice } from "./FrachiseFooterStyled";

interface Props {
  copyrightText?: string;
  showLinks?: boolean;
  footerLogo: "frachise" | "zaxbys";
  secondayLinks?: FranchiseNavLink[];
  logo?: string | null;
}

const FranchiseFooterLogo = ({ copyrightText, showLinks = false, footerLogo, logo, secondayLinks = [] }: Props) => {
  const [isPopped, setPop] = useState(0);
  const onButtonClick = () => {
    setPop(isPopped + 1);
    if (isPopped >= 1) {
      window.location.reload();
    }
  };
  return (
    <FooterLogoContainer>
      {footerLogo === "frachise" ? (
        <div className="FooterImageWrapper">
          {logo && <Image id="zax-footer-logo" src={logo} alt={footerLogo} loader={() => `${logo}`} layout="fill" />}
        </div>
      ) : (
        <div id="zax-footer-logo"></div>
      )}

      {copyrightText && <FrachisorCopyrightNotice footerLogo={footerLogo}>{copyrightText}</FrachisorCopyrightNotice>}
      <div className="footerNavSecondaryLink">
        {showLinks &&
          secondayLinks?.map((link, idx) => {
            return (
              <FooterLogoLinks key={idx} to={link?.LinkURL}>
                {link?.LinkText}
              </FooterLogoLinks>
            );
          })}
        {showLinks && (
          <button
            onClick={onButtonClick}
            id="ot-sdk-btn"
            className="ot-sdk-show-settings footerBottomLink"
            style={{
              background: "none",
              color: "#ffffff",
              border: "none",
              padding: 0,
              fontSize: "14px",
              textAlign: "left",
              marginTop: 8,
              font: theme.font.serifMedium,
              cursor: "pointer",
              outline: "inherit",
            }}
          >
            Cookie Settings
          </button>
        )}
      </div>
    </FooterLogoContainer>
  );
};

export default FranchiseFooterLogo;
