import styled, { css } from "styled-components";

export interface SubHeadingProps {
  variant:
    | "primarySubHeading1"
    | "primarySubHeading2"
    | "secondarySubHeading1"
    | "secondarySubHeading2"
    | "secondarySubHeading3"
    | "rewardTilePoint"
    | "secondarySubHeading4"
    | "secondarySubHeading5"
    | "mobileWelcomeMessage"
    | "mobileWelcomeHighlight"
    | "rewardErrorMessage"
    | "productTitle"
    | string;
  fontSize?: number;
  fontWeight?: number;
  className?: string;
  isTextUpperCase?: boolean;
  mobileFontSize?: number;
  mobileFontWeight?: number;
  tabIndex?: number;
}

const primarySubHeading1 = css<SubHeadingProps>`
  font-size: ${({ fontSize }) => fontSize ?? 38}px;
  font-family: ${(props) => props.theme.font?.ZaxCasual};
  font-style: italic;
  font-weight: 700;
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 21px;
    line-height: 23px;
  }
`;

const primarySubHeading2 = css<SubHeadingProps>`
  font-size: ${({ fontSize }) => fontSize ?? 44}px;
  font-family: ${(props) => props.theme.font?.barlowSemiCondensed};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 24}px;
  }
`;

const secondarySubHeading1 = css<SubHeadingProps>`
  font-size: ${({ fontSize }) => fontSize ?? 18}px;
  font-family: ${(props) => props.theme.font?.barlowSemiCondensed};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 14px;
    line-height: 16px;
  }
`;

const secondarySubHeading2 = css<SubHeadingProps>`
  font-size: ${({ fontSize }) => fontSize ?? 24}px;
  font-family: ${(props) => props.theme.font?.barlowSemiCondensed};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 18px;
    font-weight: ${({ mobileFontWeight }) => mobileFontWeight ?? 700};
    line-height: 20px;
  }
`;

const secondarySubHeading3 = css<SubHeadingProps>`
  font-size: ${({ fontSize }) => fontSize ?? 36}px;
  font-family: ${(props) => props.theme.font?.barlowSemiCondensed};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
  }
`;

const secondarySubHeading4 = css<SubHeadingProps>`
  font-size: ${({ fontSize }) => fontSize ?? 24}px;
  font-family: ${(props) => props.theme.font?.barlowSemiCondensed};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 18px;
    line-height: 20px;
  }
`;

const secondarySubHeading5 = css<SubHeadingProps>`
  font-size: ${({ fontSize }) => fontSize ?? 24}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 18}px;
  }
`;

const rewardTilePoint = css<SubHeadingProps>`
  font-size: ${({ fontSize }) => fontSize ?? 36}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
  }
`;

const mobileWelcomeMessage = css<SubHeadingProps>`
  text-transform: uppercase;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font?.barlowSemiCondensed};
  line-height: 21.6px;
  font-weight: ${({ fontWeight }) => fontWeight ?? 600};
  color: ${({ theme }) => theme.color?.core.black};
`;

const mobileWelcomeHighlight = css<SubHeadingProps>`
  text-transform: uppercase;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font?.ZaxCasual};
  font-weight: 700;
  font-style: italic;
  line-height: 19.58px;
  color: ${({ theme }) => theme.color?.primary.tongueTorchRed};
`;

const productTitle = css<SubHeadingProps>`
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  font-family: ${({ theme }) => theme.font?.barlowSemiCondensed};
  font-size: 14px;
  font-weight: 800;
  line-height: 14.7px;
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 13px;
    line-height: 13.65px;
  }
`;

const rewardErrorMessage = css<SubHeadingProps>`
  color: ${(props) => props.theme.color?.primary.tongueTorchRed};
  font-family: ${({ theme }) => theme.font?.barlow};
  font-size: 20px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: center;

  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 16px;
  }
`;

export const SubHeadingStyled = styled.h4<SubHeadingProps>`
  font-style: normal;
  text-transform: ${({ isTextUpperCase = true }) => (isTextUpperCase ? "uppercase" : "none")};
  ${(props) =>
    ({
      primarySubHeading1,
      primarySubHeading2,
      secondarySubHeading1,
      secondarySubHeading2,
      secondarySubHeading3,
      rewardTilePoint,
      secondarySubHeading4,
      secondarySubHeading5,
      mobileWelcomeMessage,
      mobileWelcomeHighlight,
      productTitle,
      rewardErrorMessage,
    }[props.variant])}
`;
