import styled from "styled-components";
import {
  IC_FACEBOOK,
  IC_FACEBOOK_DARK,
  IC_INSTAGRAM,
  IC_INSTAGRAM_DARK,
  IC_TWITTER,
  IC_TWITTER_DARK,
  IC_YOUTUBE,
  IC_YOUTUBE_DARK,
  IC_GOOGLE_BUSINESS,
  IC_TIKTOK,
  IC_TIKTOK_DARK,
} from "assets/images";

const icons = {
  twitter: IC_TWITTER.src,
  youtube: IC_YOUTUBE.src,
  instagram: IC_INSTAGRAM.src,
  facebook: IC_FACEBOOK.src,
  tiktok: IC_TIKTOK.src,
  googleBusiness: IC_GOOGLE_BUSINESS.src,
};

const darkIcons = {
  twitter: IC_TWITTER_DARK.src,
  youtube: IC_YOUTUBE_DARK.src,
  instagram: IC_INSTAGRAM_DARK.src,
  facebook: IC_FACEBOOK_DARK.src,
  tiktok: IC_TIKTOK_DARK.src,
  googleBusiness: IC_GOOGLE_BUSINESS.src,
};

export interface Props {
  icon: "facebook" | "twitter" | "instagram" | "youtube" | "googleBusiness" | "tiktok";
  mobileStyle: "DEFAULT" | "ALTERNATE";
}

export const SocialMediaIconStyled = styled.a<Props>`
  display: block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: ${(props) => `${props.theme.color.core.red} url(${icons[props.icon]}) no-repeat center`};
  color: ${(props) => props.theme.color.core.white};

  background-size: 60%;
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    ${(props) =>
      props.mobileStyle === "ALTERNATE" &&
      `
    background: ${props.theme.color.secondary.pantone290} url(${darkIcons[props.icon]}) no-repeat center`};
    width: 44px;
    height: 44px;
    background-size: 50% 50%;
  }
`;
