import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Button from "components/atomic-components/atoms/button/Button";
import { setLocationOrderModal } from "redux/reducer/Location/LocationAction";
import { setStoreLocation } from "redux/reducer/Store/actions";
import { Address, Hours, Store, StoreHours } from "src/types/types";
import { matchDayOfWeek } from "utils/helper";
import { DEFAULT_LAT, DEFAULT_LNG } from "constants/constant";
import { sortList } from "utils/objectSorting";
import LocationNoStoreFound from "../LocationNoStoreFound/LocationNoStoreFound";
import LocationDetailTile from "../LocationDetailTile/LocationDetailTile";
import { CreateAddressCardWrapper, LocationTabDisplayListStyled } from "./LocationTabDisplayListStyled";

// Copied the functionalities from TabContainer.tsx file, for any reference please check that file, Updated the files for Pickup recent addresses
const createAddressFromItem = (variant: string, item: any, showTodayMessage = false): Address => {
  const isDelivery = variant === "delivery";

  return {
    ...item,
    addressId: isDelivery ? item.id : item.storeId,
    addressType: isDelivery ? item.addressType : "",
    addressLineOne: isDelivery ? item.addressLineOne : item.address,
    addressLineTwo: isDelivery ? item.addressLineTwo : "",
    city: item.city,
    state: item.state,
    zip: item.zip,
    favorite: isDelivery ? item.favorite : item.default,
    time: isDelivery
      ? ""
      : item.storeHours.map(
          (hours: StoreHours) =>
            hours.type === "business" &&
            hours.ranges.map(
              (time: Hours) =>
                `${dayjs(time.opensAt).format("hh:mmA")} - ${dayjs(time.closeAt).format("hh:mmA")} ${
                  showTodayMessage && matchDayOfWeek(time.day || "") ? "Today" : time.day
                }`
            )
        ),
    latitude: item.latitude,
    longitude: item.longitude,
    variant: variant,
  };
};

interface Props {
  variant: "pickup" | "delivery";
  activeTAB: string;
  className?: string;
}

const LocationTabDisplayList = ({ variant, activeTAB, className }: Props) => {
  const dispatch = useDispatch();
  const { addressList, favouriteStoreList, recentStoreList, device, userCurrentLocation, userLocation } = useSelector((state: any) => ({
    addressList: state?.address?.addressList,
    favouriteStoreList: state?.store?.favouriteStoreList,
    recentStoreList: state?.store?.recentStoreList,
    device: state?.currentDevice.device,
    userCurrentLocation: state.globalReducer?.userCurrentLocation,
    userLocation: state.store?.userLocation,
  }));

  const isDelivery = variant === "delivery";
  let faveStoreList: Store[] = [];
  faveStoreList = sortList(favouriteStoreList, "default", "desc");
  const updatedRecentStoreList = Array.isArray(recentStoreList)
    ? recentStoreList?.map((recentStore: any) => ({
        ...recentStore,
        default:
          faveStoreList?.length > 0
            ? faveStoreList
                .filter((favStore: any) => favStore.default)
                .map((faveStore: any) => String(faveStore?.storeId))
                .includes(String(recentStore.storeId))
            : false,
      }))
    : [];

  let storeList: Store[] = [];
  storeList = sortList(updatedRecentStoreList, "default", "desc");

  useEffect(() => {
    if (activeTAB === "faveStores" && favouriteStoreList?.length && !userCurrentLocation) {
      const favList = favouriteStoreList?.filter((faveStore: any) => faveStore.default)[0];
      const latLongPayload = {
        lat: parseFloat(`${favList?.latitude}`),
        lng: parseFloat(`${favList?.longitude}`),
      };
      dispatch(setStoreLocation(latLongPayload));
    }

    return () => {
      dispatch(setStoreLocation({ lat: userLocation?.lat ?? DEFAULT_LAT, lng: userLocation?.lng ?? DEFAULT_LNG }));
    };
  }, [activeTAB, favouriteStoreList, userCurrentLocation]);

  const createAddressCard = (item: any, index: number) => {
    const address = createAddressFromItem(variant, item, true);

    return (
      <CreateAddressCardWrapper key={index} isDelivery={isDelivery}>
        <LocationDetailTile address={address} isFavourite={isDelivery ? item.favorite : item.default} isDeliveryAddress={isDelivery} />
      </CreateAddressCardWrapper>
    );
  };

  const pickupTypeDisplayList = () => {
    if (activeTAB === "faveStores") {
      return faveStoreList?.length > 0 ? (
        faveStoreList?.map(createAddressCard)
      ) : (
        <LocationNoStoreFound className="noFavoriteStoresText" descriptionLineOne="You have no favorite stores." />
      );
    } else if (activeTAB === "recent") {
      return storeList?.length > 0 ? (
        storeList.map(createAddressCard)
      ) : (
        <LocationNoStoreFound className="noFavoriteStoresText" descriptionLineOne="You haven't ordered from any stores recently." />
      );
    }
  };

  const NoFavLocationAvailableMessage = () => {
    return <LocationNoStoreFound className="noFavoriteStoresText" descriptionLineOne="You have no favorite addresses." width="308" />;
  };

  const deliveryTypeDisplayList = () => {
    if (activeTAB === "faveAddresses") {
      return (
        <div className="faveAddressTab">
          {addressList?.length > 0 ? addressList?.map(createAddressCard) : NoFavLocationAvailableMessage()}
          <Button className="newAddressButton" variant="modalOutlineButton" onClick={() => dispatch(setLocationOrderModal("NEW_ADDRESS"))}>
            NEW ADDRESS
          </Button>
        </div>
      );
    } else {
      return (
        <LocationNoStoreFound
          className="noFavoriteStoresText"
          descriptionLineOne="Where do you want your Zaxbys delivered?"
          width={device === "MOBILE" ? "140" : "200"}
        />
      );
    }
  };

  return (
    <LocationTabDisplayListStyled className={className}>
      {isDelivery ? deliveryTypeDisplayList() : pickupTypeDisplayList()}
    </LocationTabDisplayListStyled>
  );
};

export default LocationTabDisplayList;
