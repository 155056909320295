export const getCryptoRandomNumber = () => {
  if (typeof window !== "undefined") {
    const buf = new Uint8Array(1);
    const bufArray = window?.crypto?.getRandomValues(buf) ? window?.crypto?.getRandomValues(buf) : [0];
    return bufArray?.length > 0 ? bufArray[0] : 0;
  }
  return 0;
};

// create a random xy string with dashes and numbers
export function generateRandomString(randomStringInLength: number = 32) {
  // function used to give template of the UUID that needs to be generated.
  switch (randomStringInLength) {
    case 32:
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxx";
    case 36:
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
    default:
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxx";
  }
}

// Ref. https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
export function generateUUID(UUIDlength: number = 32) {
  // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
  return generateRandomString(UUIDlength).replace(/[xy]/g, function (c) {
    let r = getCryptoRandomNumber();
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}
