import styled, { css } from "styled-components";

const largeLabel = css`
  label {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
  }
`;

const smallLabel = css`
  label {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }
`;

export const TextAreaStyled = styled.div<{ variant?: "largeLabel" | "smallLabel" | string }>`
  textarea {
    outline: none;
    width: 100%;
    padding: 18px 14px;
    border-radius: 5px;
    resize: none;
    margin-top: 4px;
    min-height: 148px;
    /* font-size must be >= 16px to prevent zooming feature of mobile browsers
      @see https://zaxbys.atlassian.net/browse/ZP-6995 */
    font-size: 16px;
    line-height: 17px;
    font-weight: 500;
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    border: 2px solid ${(props) => props.theme.color.secondary.coolGray1};
    min-width: 380px;
    &::-webkit-scrollbar {
      display: flex;
    }
    ::placeholder {
      color: ${(props) => props.theme.color.primary.foundersBlue};
    }
  }
  textarea:focus::placeholder {
    color: transparent;
  }
  label {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    margin-bottom: 20px;
  }
  .totalCharacter {
    text-align: right;
    width: 100%;
    span {
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      font-family: ${(props) => props.theme.font.barlow};
      color: ${(props) => props.theme.color.secondary.coolGray10};
    }
  }
  ${(props) => props.theme.responsive.max.lg} {
    textarea {
      min-width: 294px;
    }
  }
  ${(props) => ({ largeLabel, smallLabel }[props?.variant || "smallLabel"])}
`;

export const StyledError = styled.div`
  position: absolute;
  color: ${(props) => props.theme.color.primary.tongueTorchRed};
  font-family: ${(props) => props.theme.font.sourceSansPro};
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 2px;
  ${(props) => props.theme.responsive.max.lg} {
    font-size: 10px;
  }
`;
