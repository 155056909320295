import styled from "styled-components";

export const ErrorModalWrapper = styled.div`
  .errorMessage {
    display: flex;
    align-items: flex-start;
    padding-top: 135px;
    .heading {
      font-family: ${(props) => props.theme.font.barlowSemiCondensed};
      font-weight: 800;
      font-style: normal;
      line-height: 24px;
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .errorMessageOverlay {
      display: flex;
      align-items: center;
    }
    .errorMessage {
      p {
        font-family: ${(props) => props.theme.font.barlow};
        font-weight: 500;
      }
      img {
        display: none;
      }
    }
  }
`;
