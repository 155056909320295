import styled from "styled-components";

export default styled.div`
  width: min(90%, 386px);
  margin-bottom: 50px;

  & > div:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .requiredText {
    font-family: ${(props) => props.theme.font.serifMedium};
    color: ${(props) => props.theme.color.core.blue};
    font-size: 12px;
    line-height: 14px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
    margin-bottom: 30px;

    .requiredText {
      font-size: 11px;
      line-height: 13px;
    }
  }
`;
