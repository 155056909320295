import NearByCard from "./NearByCard/NearByCard";
import { NearByCardContainerStyled } from "./NearByCardContainerStyled";

const NearByCardContainer = () => {
  return (
    <NearByCardContainerStyled>
      <NearByCard isNearByCard={true} isPinnedLocation={true} />
    </NearByCardContainerStyled>
  );
};

export default NearByCardContainer;
