import Image from "next/image";
import { CHEVRON_BLUE_LEFT, CHEVRON_BLUE_LIGHT_LEFT, CHEVRON_LEFT } from "assets/images";
import Button from "../Button";
import { ButtonProps } from "../types";
import { NavButtonStyled } from "./NavButtonStyled";

const iconVariant = {
  primaryRed: CHEVRON_LEFT,
  ghostBlue: CHEVRON_BLUE_LEFT,
  secondaryBlue: CHEVRON_BLUE_LIGHT_LEFT,
};

const NavButton = ({ variant, children, className, underline, swapIcon, ...rest }: ButtonProps) => {
  return (
    <NavButtonStyled swapIcon={swapIcon}>
      <Button className={className} variant={variant} underline={underline} {...rest}>
        <div className="iconWrapper">
          <Image src={iconVariant[variant as keyof typeof iconVariant] || CHEVRON_LEFT} alt="arrow" layout="fill" />
        </div>
        {children}
      </Button>
    </NavButtonStyled>
  );
};

export default NavButton;
