import { css } from "styled-components";

export const brandedScroll = css`
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: ${({ theme }) => theme?.color.secondary.coolGray1};
  }

  &::-webkit-scrollbar-track-piece {
    border-radius: 8px;
    background-color: ${({ theme }) => theme?.color.secondary.coolGray1};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme?.color.primary.foundersBlue};
    background: ${({ theme }) => theme?.color.primary.foundersBlue};
  }
`;
