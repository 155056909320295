import { css } from "styled-components";

import theme from "./theme";

const media = (type: "min" | "max", size: string, styles: string) => css`
  @media only screen and (${type}-width: ${size}) {
    ${styles}
  }
`;

export const responsive = {
  max: {
    xl: (styles: string) => media("max", theme.size.xl, styles),
    lg: (styles: string) => media("max", theme.size.lg, styles),
    md: (styles: string) => media("max", theme.size.md, styles),
    sm: (styles: string) => media("max", theme.size.sm, styles),
    xs: (styles: string) => media("max", theme.size.xs, styles),
  },
  min: {
    xl: (styles: string) => media("max", theme.size.xl, styles),
    lg: (styles: string) => media("min", theme.size.lg, styles),
    md: (styles: string) => media("min", theme.size.md, styles),
    sm: (styles: string) => media("min", theme.size.sm, styles),
    xs: (styles: string) => media("min", theme.size.xs, styles),
  },
};
