import styled from "styled-components";

export const LocationNoStoreFoundStyled = styled.div<{ width: string }>`
  width: 342px;
  min-height: 107px;
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
  .descriptionContainer {
    padding-bottom: 24px;
  }
  .text {
    width: ${({ width }) => width}px;
    text-align: center;
  }
  ${(props) => props.theme.responsive?.max.lg} {
    width: 100%;
    margin-top: 6px;
    .text {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;
