import {
  applyRewardToBasketResponse,
  deleteRewardFromBasketPayload,
  getRewardUserCodeToScanResponse,
  getRewardsForBasketPayload,
  Rewards,
  SetSelectedRewardIdAction,
  SetAppliedRewardAction,
  SetAppliedRewardPayload,
  redeemInstoreRewardResponse,
  deleteRedemptionTrackingCodeResponse,
  applyPromoCodeToBasketResponse,
  deletePromoCodeFromBasketResponse,
  getRewardsForGuestResponse,
  getPointsWithManualCheckinsResponse,
  getActivateYourOfferCouponsResponse,
  SetSelectedRewardListPayload,
  SetSelectedRewardListAction,
  SetBasketRewardsListAction,
  SetBasketRewardsListPayload,
  SetUserSelectedTier,
  SetUserSelectedCard,
  SetShowPromoCodeInput,
  SetIsPromoCodeApplied,
  SetPromoInput,
  setRewardsListUntilExpiryTime,
  ActiveFeaturedRewardsExpiration,
  SetActiveFeaturedRewardsExpiration,
  rewardsScanErrorResponse,
} from "./types";

export const setRewardsScanErrorAction = (payload: rewardsScanErrorResponse) => ({
  type: "SET_REWARDS_SCAN_ERROR",
  payload,
});
export const setRewardToBasketAction = (payload: applyRewardToBasketResponse | null) => ({
  type: "SET_REWARD_TO_BASKET",
  payload,
});
export const deleteRewardFromBasketAction = (payload: deleteRewardFromBasketPayload) => ({
  type: "DELETE_REWARD_FROM_BASKET",
  payload,
});
export const getRewardsForBasketAction = (payload: getRewardsForBasketPayload) => ({
  type: "GET_REWARDS_FOR_BASKET",
  payload,
});

export const getUserRewardsHistoryAction = (payload: string) => ({
  type: "GET_USER_REWARDS_HISTORY",
  payload,
});
export const setSelectedRewardAction = (payload: Rewards | null) => ({
  type: "SET_SELECTED_REWARD",
  payload,
});
export const getRewardUserCodeToScanAction = (payload: getRewardUserCodeToScanResponse | null) => ({
  type: "GET_REWARD_USER_CODE_TO_SCAN",
  payload,
});
export const setAppliedReward = (payload: SetAppliedRewardPayload): SetAppliedRewardAction => ({
  type: "SET_APPLIED_REWARD",
  payload,
});
export const setSelectedRewardId = (payload: string): SetSelectedRewardIdAction => ({
  type: "SET_SELECTED_REWARD_ID",
  payload,
});
export const redeemInstoreRewardAction = (payload: redeemInstoreRewardResponse | null) => ({
  type: "REDEEM_INSTORE_REWORD",
  payload,
});
export const deleteRedemptionTrackingCode = (payload: deleteRedemptionTrackingCodeResponse | null) => ({
  type: "DELETE_REDEMPTION_TRACKING_CODE",
  payload,
});
export const applyPromoCodeToBasket = (payload: applyPromoCodeToBasketResponse | null) => ({
  type: "APPLY_PROMO_CODE_TO_BASKET",
  payload,
});
export const deletePromoCodeFromBasket = (payload: deletePromoCodeFromBasketResponse | null) => ({
  type: "DELETE_PROMO_CODE_FROM_BASKET",
  payload,
});
export const getRewardsForGuest = (payload: getRewardsForGuestResponse | null) => ({
  type: "GET_REWARDS_FOR_GUEST",
  payload,
});
export const getPointsWithManualCheckins = (payload: getPointsWithManualCheckinsResponse | null) => ({
  type: "GET_POINTS__WITH_MANUAL_CHECKINS",
  payload,
});
export const getActivateYourOfferCoupons = (payload: getActivateYourOfferCouponsResponse | null) => ({
  type: "GET_ACTIVATE_YOUR_OFFER_COUPONS",
  payload,
});
export const setSelectedRewardList = (payload: SetSelectedRewardListPayload): SetSelectedRewardListAction => ({
  type: "SET_SELECTED_REWARD_LIST",
  payload,
});
export const setBasketRewardsList = (payload: SetBasketRewardsListPayload): SetBasketRewardsListAction => ({
  type: "SET_BASKET_REWARD_LIST",
  payload,
});
export const setUserSelectedTier = (payload: Rewards): SetUserSelectedTier => ({
  type: "SET_USER_SELECTED_TIER",
  payload,
});
export const setUserSelectedCard = (payload?: Rewards): SetUserSelectedCard => ({
  type: "SET_USER_SELECTED_CARD",
  payload,
});

export const setShowPromoCodeInput = (payload: boolean): SetShowPromoCodeInput => ({
  type: "SET_SHOW_PROMO_CODE_INPUT",
  payload,
});

export const setIsPromoCodeApplied = (payload: boolean): SetIsPromoCodeApplied => ({
  type: "SET_IS_PROMO_CODE_APPLIED",
  payload,
});

export const setPromoInput = (payload: string | undefined): SetPromoInput => ({
  type: "SET_PROMO_INPUT",
  payload,
});

export const setRewardsListUntilExpiry = (payload: Array<any>): setRewardsListUntilExpiryTime => ({
  type: "SET_REWARDS_LIST_UNTIL_EXPIRY",
  payload,
});

export const setActiveFeaturedRewardsExpiration = (payload: ActiveFeaturedRewardsExpiration): SetActiveFeaturedRewardsExpiration => ({
  type: "SET_ACTIVE_FEATURED_REWARDS_EXPIRATION",
  payload,
});

export const setRewardAppliedNotificationPopup = (payload: boolean) => {
  return { type: "SET_REWARD_APPLIED_NOTIFICATION_POPUP", payload: payload };
};
