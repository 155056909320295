export const GET_ALL_GIFT_CARDS = "GET_ALL_GIFT_CARDS";
export const APPLY_GIFT_CARD_BALANCE = "APPLY_GIFT_CARD_BALANCE";
export const SELECTED_GIFT_CARD = "SELECTED_GIFT_CARD";
export const USE_GIFT_CARD_LATER = "USE_GIFT_CARD_LATER";
export const PREVIOUS_SELECTED_GIFT_CARD = "PREVIOUS_SELECTED_GIFT_CARD";
export const DEFAULT_GIFT_CARD_FLAG = "DEFAULT_GIFT_CARD_FLAG";
export const GIFT_CARD_PAYMENT = "GIFT_CARD_PAYMENT";
export const RESET_GIFT_CARD_PAYMENT = "RESET_GIFT_CARD_PAYMENT";
export const SET_IS_GIFT_CARDS_AVAILABLE = "SET_IS_GIFT_CARDS_AVAILABLE";
export const SET_IS_SHOW_USE_GIFT_CARD = "SET_IS_SHOW_USE_GIFT_CARD";
