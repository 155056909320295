export const SET_USER_STATUS_CONST = "SET_USER_STATUS_CONST";
export const SET_USER_GEOLOCATION_STATUS_CONST = "SET_USER_GEOLOCATION_STATUS_CONST";
export const SET_USER_ORDER_TYPE = "SET_USER_ORDER_TYPE";
export const SET_LOCATIONS_SCREEN_FLAG = "SET_LOCATIONS_SCREEN_FLAG";
export const SET_REMEMBER_STATUS = "SET_REMEMBER_STATUS";
export const SET_USER_PAGE_FLAG = "SET_USER_PAGE_FLAG";
export const SET_USER_SELECTED_TIP_PERCENTAGE = "SET_USER_SELECTED_TIP_PERCENTAGE";
export const SET_CONFIRM_ADDRESS_SAVE_LOGIN_FLAG = "SET_CONFIRM_ADDRESS_SAVE_LOGIN_FLAG";
export const SET_CONTACTLESS_DRIVER_NOTE_FLAG = "SET_CONTACTLESS_DRIVER_NOTE_FLAG";
export const SET_ERROR_MESSAGE_TO_MODAL = "SET_ERROR_MESSAGE_TO_MODAL";
export const SET_ERROR_MESSAGE_TO_MODAL_FLAG = "SET_ERROR_MESSAGE_TO_MODAL_FLAG";
export const SET_USER_FROM_CHECKOUT = "SET_USER_FROM_CHECKOUT";
export const SET_BLACKOUT_ERROR_MESSAGE = "SET_BLACKOUT_ERROR_MESSAGE";
export const SET_MIGRATED_USER_INFO = "SET_MIGRATED_USER_INFO";
export const SET_STORE_CLOSING_FLAG = "SET_STORE_CLOSING_FLAG";
export const SET_SELECT_VALID_DATE = "SET_SELECT_VALID_DATE";
export const SET_IS_OLD_PHONE_NUM_VALIDATED = "SET_IS_OLD_PHONE_NUM_VALIDATED";
export const SET_USER_PROFILE_PHONE_UPDATE_DATA = "SET_USER_PROFILE_PHONE_UPDATE_DATA";
export const SET_IS_USER_EXCEEDED_PASSWORD_RESET_LIMIT = "SET_IS_USER_EXCEEDED_PASSWORD_RESET_LIMIT";
export const SET_IS_SHOW_WARNING_MODAL_FLAG = "SET_IS_SHOW_WARNING_MODAL_FLAG";
export const SET_WARNING_MESSAGE_TO_MODAL = "SET_WARNING_MESSAGE_TO_MODAL";
export const SET_SELECT_STORE_MODAL_FLAG = "SET_SELECT_STORE_MODAL_FLAG";
export const SET_SHOW_ENTER_CODE_MODAL = "SET_SHOW_ENTER_CODE_MODAL";
export const SET_DOUBLE_CHECK_DATE_TIME_BUTTON_CLICKED = "SET_DOUBLE_CHECK_DATE_TIME_BUTTON_CLICKED";
