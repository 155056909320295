import styled from "styled-components";

export const CreateAddressCardWrapper = styled.div<{ isDelivery: boolean }>`
  ${(props) =>
    props.isDelivery &&
    `
  .locationCardTile {
    .milesAndDetails {
      justify-content: flex-end;
      padding-top: 0;
      .faveAddress {
        width: 100%;
        .iconWrapper {
          width: 100%;
          margin: 0 20px 0 16px;
          cursor: default;
          span {
            cursor: pointer;
          }
          .addressType {
            font-weight: 700;
            text-transform: uppercase;
          }
        }
        .noCustomName {
          height: 25px;
        }
      }
    }
    .addressWrapper {
      .addressTime {
        margin-top: -36px;
        .noCustomName {
          margin-top: 0;
        }
      }
    }
  }

  ${props.theme.responsive?.max.lg} {
    .locationCardTile {
      width: 342px;
      .milesAndDetails {
        .faveAddress {
          .iconWrapper {
            .addressType {
              font-size: 20px;
              line-height: 16px;
              margin-bottom: 8px;
            }
          }
          .noCustomName {
            height: 25px;
          }
        }
      }
      .addressWrapper {
        .addressTime {
          margin-top: -43px;
          .noCustomName {
            margin-top: 15px;
          }
        }
      }
    }
  }`}
`;

export const LocationTabDisplayListStyled = styled.div`
  overflow: scroll;
  scrollbar-width: none;
  width: 100%;

  .faveAddressTab {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .newAddressButton {
    width: 200px;
    height: 44px;
    margin: 20px 0 0;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    .newAddressButton {
      width: 166px;
      height: 36px;
      margin: 12px 0 0;
    }
  }
`;
