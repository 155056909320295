import PasswordUpdated from "components/global/PasswordUpdated/PasswordUpdated";

import { ForgotPasswordUpdatedStyled } from "./ForgotPasswordUpdatedStyled";

const ForgotPasswordUpdated = () => {
  return (
    <ForgotPasswordUpdatedStyled>
      <PasswordUpdated />
    </ForgotPasswordUpdatedStyled>
  );
};

export default ForgotPasswordUpdated;
