export const sortList = (list: any, key: string, type: string) => {
  if (list) {
    if (type === "asc") {
      return list.sort((a: any, b: any) => (a[key] >= b[key] ? 1 : -1));
    } else {
      return list.sort((a: any, b: any) => (a[key] <= b[key] ? 1 : -1));
    }
  } else {
    return list;
  }
};
