import { ReactNode } from "react";
import Link from "../../typography/Links/Links";
import { LinkOverrideStyled } from "./LinkOverrideStyled";

interface Props {
  children: ReactNode;
  href: string;
  title: string;
}

const LinkOverride = ({ children, href }: Props) => {
  return (
    <LinkOverrideStyled>
      <Link to={href} variant="inlineBody2" className="linkOverride">
        {children}
      </Link>
    </LinkOverrideStyled>
  );
};

export default LinkOverride;
