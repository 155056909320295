import styled from "styled-components";

export const LocationSearchContainerStyled = styled.div<{ hasPlaceHolder: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 20px;
  gap: 0;
  .input-wrapper input {
    &::placeholder {
      color: ${(props) => props.theme.color.primary.foundersBlue};
    }
  }

  .searchGpsIcon {
    margin: 0 12px;
  }

  .filter-icon {
    cursor: pointer;
  }

  .highlight {
    font-family: ${(props) => props.theme?.font.barlow};
    font-weight: 800;
  }

  ${(props) =>
    props?.hasPlaceHolder &&
    `.input-wrapper label {
    top: 8px;
    font-size: 12px;
    color: ${props.theme.color.secondary.coolGray7};
    font-family: ${props.theme.font.barlow};
    font-weight: 700;
    line-height: 14px;
  }`}

  ${(props) => props.theme.responsive.max.lg} {
    gap: 2px;
    ${(props) =>
      props?.hasPlaceHolder &&
      `.input-wrapper label {
        top: 3px;
        font-size: 10px;
  }`}
  }
`;
