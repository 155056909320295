import styled from "styled-components";

export const AddressSelectedStyled = styled.div`
  position: relative;
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    height: 100%;
    width: 100%;
    .cardFooter {
      background: white;
    }
  }
`;
