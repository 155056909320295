import { useEffect } from "react";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { FranchiseState } from "redux/reducer/Franchise/types";
import { setFranchiseFooterCMSData } from "redux/reducer/Franchise/FranchiseActions";
import { FOOTER_GO_BUTTON } from "assets/images";
import { FooterContainer, FooterLinks, FooterNavLinks } from "./FrachiseFooterStyled";
import FranchiseFooterLogo from "./FranchiseFooterLogo";

type RootState = {
  franchise: FranchiseState;
};

const FranchiseFooter = () => {
  const dispatch = useDispatch();
  const { franchiseFooterCMS, isFooterCMSLoaded } = useSelector((state: RootState) => ({
    franchiseFooterCMS: state.franchise.franchiseFooter,
    isFooterCMSLoaded: state.franchise.isFranchiseFooterLoaded,
  }));

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (typeof isFooterCMSLoaded === "boolean" && !isFooterCMSLoaded) {
      dispatch(setFranchiseFooterCMSData());
    }
  }, [isFooterCMSLoaded]);

  return (
    <FooterContainer id="franchise-footer">
      <div className="footerGoButton">
        <Image src={FOOTER_GO_BUTTON} onClick={scrollToTop} alt="scroll top" />
      </div>
      <div className="footerBottom">
        <div className="footerPrimaryLink">
          <FooterLinks>
            {franchiseFooterCMS?.FranFooterMainLinks?.map((link, idx) => {
              return (
                <FooterNavLinks key={idx} to={link?.LinkURL}>
                  {link?.LinkText}
                </FooterNavLinks>
              );
            })}
          </FooterLinks>
        </div>
        {franchiseFooterCMS?.ZaxbysCopyRight && (
          <FranchiseFooterLogo
            copyrightText={franchiseFooterCMS?.ZaxbysCopyRight}
            showLinks
            footerLogo="frachise"
            secondayLinks={franchiseFooterCMS?.FranFooterSecondaryLinks}
            logo={franchiseFooterCMS?.FooterLogo?.url}
          />
        )}
      </div>
      <div className="logo-wrapper">
        {franchiseFooterCMS?.ZaxbysCopyRight && (
          <FranchiseFooterLogo
            copyrightText={franchiseFooterCMS?.ZaxbysCopyRight}
            footerLogo="zaxbys"
            logo={franchiseFooterCMS?.ZaxbysTextLogo}
          />
        )}
      </div>
    </FooterContainer>
  );
};

export default FranchiseFooter;
