import styled from "styled-components";
import NavButton from "components/atomic-components/atoms/button/NavButton/NavButton";

export const AddressPickUpCardStyled = styled.div`
  .selectedCardContainer {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 12px;
  }

  .readyTimeText {
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
  }

  ${(props) => props.theme.responsive.max.lg} {
    height: 100vh;
    width: 100vw;
    position: relative;
    .selectedCardContainer {
      box-shadow: none;
      border-radius: 0;
    }
  }
`;

export const BackButtonStyled = styled(NavButton)`
  justify-content: flex-start !important;
  height: 22px;
  font-size: 15px;

  .iconWrapper {
    height: 12px !important;
    width: 8px !important;
    margin-top: 3px !important;
  }
`;
