import React, { useEffect, useMemo } from "react";
import { useGoogleMap } from "@react-google-maps/api";
import { DEFAULT_LAT, DEFAULT_LNG } from "constants/constant";

interface MapStyle {
  MOBILE: { height: number | string; width: number | string };
  DESKTOP: { height: number | string; width: number | string };
}

interface MapStyleObject {
  "/checkout": MapStyle;
  "/confirmation": MapStyle;
}

interface MapStyles {
  [pageUrl: string]: {
    MOBILE: MapStyle;
    DESKTOP: MapStyle;
  };
}

export const checkoutMapStyle = {
  width: "100%",
  height: "289px",
};
export const mobileMapStyle = {
  width: "100%",
  height: "200px",
};

export const confirmationMapStyle = {
  width: "100%",
  height: "363px",
};

const mapStyles: MapStyleObject = {
  "/checkout": {
    MOBILE: mobileMapStyle,
    DESKTOP: checkoutMapStyle,
  },
  "/confirmation": {
    MOBILE: mobileMapStyle,
    DESKTOP: confirmationMapStyle,
  },
};

const mapPinSize: MapStyleObject = {
  "/checkout": {
    MOBILE: { height: 44, width: 42 },
    DESKTOP: { height: 75, width: 92 },
  },
  "/confirmation": {
    MOBILE: { height: 40, width: 42 },
    DESKTOP: { height: 75, width: 92 },
  },
};

export const getMapStyle = (pageUrl: keyof MapStyles, device: keyof MapStyle) => {
  switch (pageUrl) {
    case "/checkout":
    case "/confirmation":
      return mapStyles[pageUrl][device];
    default:
      return mapStyles["/checkout"].DESKTOP;
  }
};

export const getMapPinStyle = (pageUrl: keyof MapStyles, device: keyof MapStyle) => {
  switch (pageUrl) {
    case "/checkout":
    case "/confirmation":
      return mapPinSize[pageUrl][device];
    default:
      return mapPinSize["/checkout"].DESKTOP;
  }
};

// Map style object to remove unwanted landmark in it.
export const customStyles = [
  {
    featureType: "poi.business",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.attraction",
    stylers: [{ visibility: "off" }],
  },
];

// create the distance in miles on the map
export function milesPreview(distanceInMeters: number) {
  const distanceInKm = distanceInMeters / 1000;
  const distanceInMiles = (distanceInKm * 0.621371).toFixed(2);
  const milesDiv = document.createElement("div");
  milesDiv.id = "distanceControlDiv";
  milesDiv.innerHTML = `${distanceInMiles} miles`;
  return milesDiv;
}

// Method created for effectively zooms and pans the map so that both userLocation and storeLocation are visible on the map.
export const MapCoordinates: React.FC<{ userLocation: any; storeLocation: any }> = ({ userLocation, storeLocation }) => {
  const map = useGoogleMap();

  useEffect(() => {
    if (map && userLocation && storeLocation) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds?.extend(userLocation);
      bounds?.extend(storeLocation);
      // fitBounds method is called to adjust the visible area
      map?.fitBounds(bounds);
    }
  }, [map, userLocation, storeLocation]);

  return null;
};

// Function used to show the distance from user location to store location
export const MilesToStore: React.FC<{ userLocation: any; storeLocation: any }> = ({ userLocation, storeLocation }) => {
  const map = useGoogleMap();
  const memoizedUserLocation = useMemo(() => userLocation, [userLocation]);
  useEffect(() => {
    if (map && userLocation !== null && storeLocation) {
      const distanceInMeters = window.google.maps?.geometry?.spherical?.computeDistanceBetween(
        new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
        new window.google.maps.LatLng(storeLocation.lat, storeLocation.lng)
      );

      // previews the miles information on map
      map.controls[window?.google?.maps?.ControlPosition?.RIGHT_BOTTOM].push(milesPreview(distanceInMeters));

      // Cleanup function to remove the control when the component unmounts or dependencies change
      return () => {
        // clear all the added items in the list when it's unmounting the page
        map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].clear();
      };
    }
  }, [map, memoizedUserLocation, storeLocation]);

  return null;
};

export const getStoreLocation = (lat: number, lng: number) => {
  if (!lat && !lng) {
    return { lat: DEFAULT_LAT, lng: DEFAULT_LNG };
  }
  return { lat, lng };
};

// A function written to adjust the centering of the map, due to the issue facing for the google api package it needs to alter the coordinates to make the map centered
export const centerMapAdjuster = (coordinates: { lat: number; lng: number }) => {
  // subtracting -0.0005 to align to the center of the map
  const adjustAmount = -0.0005;
  return { lat: coordinates.lat - adjustAmount, lng: coordinates.lng };
};
