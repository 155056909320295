import styled from "styled-components";
import { mobileComponent, desktopComponent } from "theme/globalClasses";

export const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 36px;
  box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.color.gray.boxShadow};

  .footerContactUs {
    background-color: ${(props) => props.theme.color.primary.coleslawWhite};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 63px 0px 57px 120px;
    align-items: start;
    width: 100%;

    .title {
      font-size: 20px;
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-family: ${(props) => props.theme.font.barlow};
      font-weight: 700;
      line-height: 24px;
    }

    .subtitle,
    .bodyCopy {
      font-size: 16px;
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-family: ${(props) => props.theme.font.barlow};
      font-weight: 400;
      line-height: 19px;
    }
  }

  .footerBottom {
    padding: 0 155px 0 155px;
    display: grid;
    background: ${(props) => props.theme.color.core.white};
    grid-template-rows: auto auto;
    grid-template-columns: auto auto auto;
    gap: 32px 32px;

    .caloriesMessage {
      grid-column: span 3;
      margin-bottom: 32px;
      width: 387px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14.4px;
      span {
        font-family: ${(props) => props.theme.font.barlow};
        font-weight: 500;
        color: ${(props) => props.theme.color.primary.foundersBlue};
      }
      a {
        font-family: ${(props) => props.theme.font.barlow};
        font-weight: 700;
        text-decoration: underline;
        color: ${(props) => props.theme.color.primary.foundersBlue};
      }
    }

    .zaxAppAdvertisement {
      text-align: center;
      .footerDownloadApp {
        color: ${(props) => props.theme.color.primary.tongueTorchRed};
        font-family: ${(props) => props.theme.font.ZaxCasual};
        font-style: italic;
        margin-right: 8px;
        font-size: 21px;
        line-height: 24px;
        font-weight: 700;
        text-transform: uppercase;
      }
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-family: ${(props) => props.theme.font.barlowSemiCondensed};
      font-weight: 700;

      font-size: 24px;
      line-height: 24px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .zaxAppAdvertisementMobile {
      display: none;
    }

    .footerContentContainer {
      @media only screen and (max-width: ${(props) => props.theme.size.xl}) {
        display: none;
      }
      margin-left: auto;
    }

    .appStoreLogosMobile {
      margin-bottom: 13px;
      ${mobileComponent};
    }

    .footerBottomLinksContainer {
      display: flex;
      flex-direction: row;
      gap: 25px;
      min-height: 304px;
      .footerBottomLinksContainerPrimary {
        ${desktopComponent}
        width: 183px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        * {
          color: ${(props) => props.theme.color.primary.foundersBlue};
          font-family: ${(props) => props.theme.font.barlowSemiCondensed};
          font-weight: 700;
          line-height: 20px;
          font-size: 18px;
          text-transform: uppercase;
        }
      }

      .footerBottomLinksContainerSecondary {
        width: 183px;
        display: flex;
        flex-direction: column;
        gap: 9.8px;
        * {
          width: 183px;
          color: ${(props) => props.theme.color.primary.foundersBlue};
          font-family: ${(props) => props.theme.font.barlowSemiCondensed};
          font-weight: 500;
          line-height: 20px;
          font-size: 16px;
          text-transform: uppercase;
        }
      }
      .footerBottomLink {
        cursor: pointer;
      }
    }

    .footerLogoCopyrightSection {
      margin-bottom: -40px;

      display: flex;
      align-items: flex-end;
      @media only screen and (max-width: ${(props) => props.theme.size.xl}) {
        margin-bottom: -58px;
      }
    }

    .footerBottomLinksContainerMobile {
      display: none;
    }
  }

  .downloadAppHeader {
    display: flex;
    margin-top: 32px;
  }

  .footerTop {
    padding: 0 155px 0 155px;
    margin-bottom: 48px;
    ${desktopComponent};
    position: relative;
    .footerTopContents {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 88px;
    }
    .backToTopButton {
      position: absolute;
      top: -28px;
      right: 47px;
      cursor: pointer;
      z-index: 1000;
      ${(props) => props.theme.responsive.min.xl} {
        right: -47px;
      }
    }
    .footerTopLinks {
      display: flex;
      gap: 28px;
      align-items: center;

      .link {
        text-decoration: none;
        color: ${(props) => props.theme.color.primary.foundersBlue};
        font-family: ${(props) => props.theme.font.barlowSemiCondensed};
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .footerTopBorder {
      width: 100%;
      height: 2px;
      background: ${(props) => props.theme.color.limitedUse.borderGray};
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.size.xl}) {
    align-items: center;

    .footerBottom {
      padding: 0;
      min-width: ${(props) => props.theme.contentContainerWidth.lg};
    }
    .footerTop {
      min-width: ${(props) => props.theme.contentContainerWidth.lg};
      padding: 0;
    }
  }

  @media only screen and (min-width: 1480px) {
    align-items: center;
    .footerBottom {
      padding: 0;
      min-width: ${(props) => props.theme.contentContainerWidth.xl};
    }
    .footerTop {
      min-width: ${(props) => props.theme.contentContainerWidth.xl};
      padding: 0;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    box-shadow: 0px -2px 3px 0px ${({ theme }) => theme.color.gray.mobileBoxShadow};
    padding-bottom: 0;
    .footerContactUs {
      display: flex;
      flex-direction: column;
      padding: 30px 24px 29px;
      align-items: center;
      align-items: start;
      gap: 4px;

      .title {
        font-size: 16px;
        color: ${(props) => props.theme.color.primary.foundersBlue};
        font-family: ${(props) => props.theme.font.barlow};
        font-weight: 700;
        line-height: 19px;
      }

      .subtitle,
      .bodyCopy {
        font-size: 12px;
        color: ${(props) => props.theme.color.primary.foundersBlue};
        font-family: ${(props) => props.theme.font.barlow};
        font-weight: 400;
        line-height: 14px;
      }
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.xl}) {
    .footerBottom {
      padding: 32px 24px 160px 24px;
      display: flex;
      gap: 0;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 10;
      .zaxAppAdvertisementMobile {
        display: flex;
        font-family: ${(props) => props.theme.font.barlow};
        font-size: 18px;
        line-height: 21.6px;
        font-weight: 500;
      }
      .caloriesMessage {
        font-size: 13px;
        text-align: center;
        padding: 0px;
        line-height: 16px;
        margin-bottom: 0;
        height: 100%;
        width: 100%;
        span {
          font-family: ${(props) => props.theme.font.barlow};
          color: ${(props) => props.theme.color.primary.foundersBlue};
          font-weight: 500;
        }
        a {
          display: block;
          margin-top: 4px;
          font-size: 13px;
          color: ${(props) => props.theme.color.primary.foundersBlue};
          text-underline-offset: 1.5px;
          font-family: ${(props) => props.theme.font.barlowSemiCondensed};
          font-weight: 700;
        }
      }
      .downloadZaxApp {
        margin: 0;
        color: ${(props) => props.theme.color.primary.foundersBlue};
        font-family: ${(props) => props.theme.font.barlowSemiCondensed};
        font-weight: 700;
        font-size: 23px;
        display: flex;
        align-items: baseline;
        gap: 1px;
      }
      .downloadWord {
        color: ${(props) => props.theme.color.primary.tongueTorchRed};
        font-family: ${(props) => props.theme.font.ZaxCasual};
        font-weight: 700;
        font-style: italic;
        font-size: 19px;
      }
      .footerBottomLinksContainer {
        display: none;
      }

      .footerLogoCopyrightSection {
        margin-bottom: 0px;
      }
      .footerLogos {
        margin-top: 10px;
        .AppStoreIconContainer {
          width: 146px;
          height: 43px;
        }
      }
      .appStoreLogosMobile {
        margin-bottom: 0;
      }
      .socialHeader {
        margin-top: 32px;
        font-size: 18px;
      }
      .footerBottomLinksContainerMobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        text-align: center;
        gap: 5px;
        order: 1;
        padding: 0 0px 0px 0px;
        margin-top: 32px;
        .footerBottomLink {
          text-underline-offset: 0.5px;
          text-decoration: underline;
          font-family: ${(props) => props.theme.font.barlowSemiCondensed};
          font-weight: 700;
          line-height: 20px;
          font-size: 13px;
          color: ${(props) => props.theme.color.primary.foundersBlue};
        }
      }
    }
  }
`;
