import { useDispatch } from "react-redux";
import { setLocationOrderModal, setLocationStep } from "redux/reducer/Location/LocationAction";

const useAddressSelected = () => {
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(setLocationStep("LOGGED_IN_SEARCH"));
  };

  const handleDeliverHere = () => {
    if (confirm("Is the order in range?")) {
      alert("Order confirmed");
    } else {
      dispatch(setLocationStep("ADDRESS_OUT_OF_RADIUS"));
    }
  };

  const handleConfirmAddress = () => {
    dispatch(setLocationOrderModal("CONFIRM_ADDRESS"));
  };

  return {
    handleBack,
    handleConfirmAddress,
    handleDeliverHere,
  };
};

export default useAddressSelected;
