import {
  StoreDataRequest,
  StoresFilter,
  StoreCalendarResponse,
  DateAndId,
  StoreListRequest,
  StoreUserId,
  SetStoreId,
  StoreRequest,
} from "api/store/types";
import { Store, Location, FilterInitialState, FavouriteStoreAddress, StoreHours } from "src/types/types";
import { Action } from "../types";

export type SetStoreIsLoadingPayload = boolean;
export type SetStoreIsLoadingAction = Action<"SET_STORE_IS_LOADING", SetStoreIsLoadingPayload>;

export type SetIsZipSearchPayload = boolean;
export type SetIsZipSearchAction = Action<"SET_IS_ZIP_SEARCH", SetIsZipSearchPayload>;

export type SetUserLocationPayload = Location;
export type SetUserLocationAction = Action<"SET_USER_LOCATION", SetUserLocationPayload>;

export type setStoreLocationPayload = Location;
export type setStoreLocationAction = Action<"SET_STORE_LOCATION", setStoreLocationPayload>;

export type setNewStoreListPayload = Store[];
export type setNewStoreListAction = Action<"SET_STORE_LIST", setNewStoreListPayload>;

export type resetStoreListPayload = [];
export type resetStoreListAction = Action<"SET_STORE_LIST", resetStoreListPayload>;

export type resetStoreDetailsPayload = {};
export type resetStoreDetailsAction = Action<"SET_STORE_DETAIL", resetStoreDetailsPayload>;

export type getFilteredStoreListPayload = StoresFilter;
export type getFilteredStoreListAction = Action<"SET_STORE_LIST", getFilteredStoreListPayload>;

export type setFilteredStoreListPayload = Store[];
export type setFilteredStoreListAction = Action<"SET_FILTERED_STORE_LIST", setFilteredStoreListPayload>;

export type setStoreLocationNamePayload = string | undefined;
export type setStoreLocationNameAction = Action<"SET_STORE_LOCATION_NAME", setStoreLocationNamePayload>;

export type setPickupLocationNamePayload = string | undefined;
export type setPickupLocationNameAction = Action<"SET_PICKUP_LOCATION_NAME", setPickupLocationNamePayload>;

export type setDeliveryLocationNamePayload = string | undefined;
export type setDeliveryLocationNameAction = Action<"SET_DELIVERY_LOCATION_NAME", setDeliveryLocationNamePayload>;

export type setDeliveryLocationCoordinatesPayload = Location;
export type setDeliveryLocationCoordinatesAction = Action<"SET_DELIVERY_LOCATION_COORDINATES", setDeliveryLocationCoordinatesPayload>;

export type setFilteredListPayload = Object | undefined;
export type setFilteredListAction = Action<"SET_FILTERED_LIST", setFilteredListPayload>;

export type setFilteredRadiusPayload = number | undefined;
export type setFilteredRadiusAction = Action<"SET_FILTERED_RADIUS", setFilteredRadiusPayload>;

export type setSelectedStorePayload = number;
export type setSelectedStoreAction = Action<"SET_SELECTED_STORE", setSelectedStorePayload>;

export type setSelectedStoreNamePayload = StoreDataRequest;
export type setSelectedStoreNameAction = Action<"SET_SELECTED_STORE_NAME", setSelectedStoreNamePayload>;

export type setSelectedStoreLocationPayload = Location;
export type setSelectedStoreLocationAction = Action<"SET_SELECTED_STORE_LOCATION", setSelectedStoreLocationPayload>;

export type setDirectionsPayload = any;
export type setDirectionsAction = Action<"SET_DIRECTIONS", setDirectionsPayload>;

export type setFromStorePagePayload = boolean;
export type setFromStorePageAction = Action<"SET_FROM_STORE_PAGE", setFromStorePagePayload>;

export type setFromUserSavedCardPayload = boolean;
export type setFromUserSavedCardAction = Action<"SET_FROM_USER_SAVED_CARD", setFromUserSavedCardPayload>;

export type setPickupOrderTypePayload = string | null;
export type setPickupOrderTypeAction = Action<"SET_PICKUP_ORDER_TYPE", setPickupOrderTypePayload>;

export type setStoreDetailsPinPayload = boolean | null;
export type setStoreDetailsPinAction = Action<"SHOW_STORE_PIN", setStoreDetailsPinPayload>;

export type setDeliveryAvailableStoresPayload = Array<Object>;
export type setDeliveryAvailableStoresAction = Action<"SET_DELIVERY_AVAILABLE_STORES", setDeliveryAvailableStoresPayload>;

export type setFamousMealFlagPayload = boolean;
export type setFamousMealFlagAction = Action<"SET_FAMOUS_MEAL_FLAG", setFamousMealFlagPayload>;

export type SetStoreCalendarPayload = StoreCalendarResponse | [];
export type SetStoreCalendarAction = Action<"SET_STORE_CALENDAR", SetStoreCalendarPayload>;

export type getStoreDetailsPayload = {} | undefined;
export type getStoreDetailsAction = Action<"SET_STORE_DETAIL", getStoreDetailsPayload>;

export type getStoreTimingsPayload = DateAndId;
export type getStoreTimingsAction = Action<"SET_STORE_TIMING", []>;

export type getCityBasedStoreListPayload = StoreListRequest;
export type getCityBasedStoreListAction = Action<"SET_STORE_LIST", getCityBasedStoreListPayload>;

export type deleteFavouriteStorePayload = StoreUserId;
export type deleteFavouriteStoreAction = Action<"DELETE_FAVOURITE_STORE", deleteFavouriteStorePayload>;

export type getFavouriteStorePayload = Store[];
export type getFavouriteStoreAction = Action<"SET_FAVOURITE_STORE_LIST", getFavouriteStorePayload>;

export type setFavouriteStorePayload = SetStoreId;
export type setFavouriteStoreAction = Action<"SET_FAVOURITE_STORE", setFavouriteStorePayload>;

export type setCorporateStoreIdPayload = number | string;
export type setCorporateStoreIdAction = Action<"SET_CORPORATE_STORE_ID", setCorporateStoreIdPayload>;

export type getRecentStoreListPayload = string;
export type getRecentStoreListAction = Action<"RECENT_STORE_LIST", getRecentStoreListPayload>;

export type getStoreListPayload = StoreRequest;
export type getStoreListAction = Action<"SET_STORE_LIST", getStoreListPayload>;

export type getFilesToDownloadPayload = string;
export type getFilesToDownloadAction = Action<"SET_MENU_FILES_PATH" | "SET_CATERINGMENU_FILES_PATH", getFilesToDownloadPayload>;

export type setIsStoreAvailablePayload = boolean;
export type setIsStoreAvailableAction = Action<"SET_IS_STORE_AVAILABLE", setIsStoreAvailablePayload>;

export const DEFAULT_LAT = 33.908951432123345;
export const DEFAULT_LNG = -83.45429941402196;

export type StoresState = {
  isLoading: SetStoreIsLoadingPayload;
  userLocation: SetUserLocationPayload;
  storeLocation: setStoreLocationPayload;
  storeList: setNewStoreListPayload;
  filteredStoreList: getFilteredStoreListPayload;
  favouriteStoreList: Store[];
  storeLocationName: setStoreLocationNamePayload;
  pickupLocationName: setPickupLocationNamePayload;
  deliveryLocationName: setDeliveryLocationNamePayload;
  deliveryLocationCoordinates: setDeliveryLocationCoordinatesPayload;
  filteredItems: setFilteredListPayload;
  filteredRadius: setFilteredRadiusPayload;
  storeDetail: string | string[] | undefined;
  deleteFavStore: [];
  favouriteStore: Object;
  selectedStoreId: setSelectedStorePayload;
  selectedStoreName: setSelectedStoreNamePayload;
  selectedStoreLocations: setSelectedStoreLocationPayload;
  direction: setDirectionsPayload;
  fullMenuFilePath: string;
  cateringMenuFilePath: string;
  fromStorePage: setFromStorePagePayload;
  recentStoreList: Store[];
  pickupOrderType: setPickupOrderTypePayload;
  showStoreDetailsPin: setStoreDetailsPinPayload;
  famousMealFlag: setFamousMealFlagPayload;
  deliveryAvailableStores: setDeliveryAvailableStoresPayload;
  storeCalendarData: SetStoreCalendarPayload;
  storeTiming: getStoreTimingsPayload;
  fromUserSavedCard: setFromUserSavedCardPayload;
  corporateStoreId: setCorporateStoreIdPayload;
};

export type StoreAction =
  | SetUserLocationAction
  | setStoreLocationAction
  | setNewStoreListAction
  | resetStoreListAction
  | getFilteredStoreListAction
  | setFilteredStoreListAction
  | setStoreLocationNameAction
  | setPickupLocationNameAction
  | setDeliveryLocationNameAction
  | setFilteredListAction
  | setFilteredRadiusAction
  | setSelectedStoreAction
  | setSelectedStoreNameAction
  | setSelectedStoreLocationAction
  | setDirectionsAction
  | setFromStorePageAction
  | setPickupOrderTypeAction
  | setStoreDetailsPinAction
  | setDeliveryAvailableStoresAction
  | setFamousMealFlagAction
  | SetStoreCalendarAction
  | getStoreDetailsAction
  | getStoreTimingsAction
  | getCityBasedStoreListAction
  | deleteFavouriteStoreAction
  | getFavouriteStoreAction
  | setFavouriteStoreAction
  | getRecentStoreListAction
  | getStoreListAction
  | getFilesToDownloadAction
  | setDeliveryLocationCoordinatesAction
  | SetStoreIsLoadingAction
  | setIsStoreAvailableAction
  | setFromUserSavedCardAction
  | setCorporateStoreIdAction
  | SetIsZipSearchAction;

export type StoreState = {
  selectedStoreId: number;
  storeDetail: Store;
  favouriteStoreList: FavouriteStoreAddress[];
  filteredStoreList: Store[];
  filteredRadius: number;
  favouriteStore: {
    message: string;
  };
  userLocation: Location | any; // latitude and longtitude string-number mismatch error occurs
  storeList: Array<Store>;
  fromStorePage: boolean;
  showStoreDetailsPin: boolean;
  storeLocation: Location | boolean | any; // latitude and longtitude string-number mismatch error occurs
  selectedStoreLocations: Location | any; // latitude and longtitude string-number mismatch error occurs
  direction: any; // type is not known
  filteredItems: FilterInitialState;
  famousMealFlag: boolean;
  storeLocationName: string;
  deleteFavStore: { message: string };
  deliveryAvailableStores: Store[];
  pickupOrderType: string;
  storeTiming: Array<StoreHours>;
  pickupLocationName: string;
  deliveryLocationName: string;
  deliveryLocationCoordinates: Location;
  selectedStoreName: { deliveryType?: string; storeAddress?: string };
  isLoading: boolean;
  isZipSearch: boolean;
  fromUserSavedCard: boolean;
  isStoreAvailable: boolean;
  corporateStoreId: number | string;
};
