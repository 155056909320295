import { LOWERCASE_REGEX, NUMBERS_REGEX, SPECIAL_CHARECTER_REGEX, UPPERCASE_REGEX } from "constants/regexPatterns";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { PasswordCase, StrengthIndicator } from "./PasswordStrengthIndicatorStyled";

const PasswordStrengthIndicator = (props: { password: string; isPasswordFieldoutOfFocus: boolean }) => {
  const { password, isPasswordFieldoutOfFocus } = props;

  const hasUppercase = UPPERCASE_REGEX.test(password);
  const hasLowercase = LOWERCASE_REGEX.test(password);
  const hasSpecialChar = SPECIAL_CHARECTER_REGEX.test(password);
  const hasNumber = NUMBERS_REGEX.test(password);
  const hasLengthOfEight = password.length >= 8;

  const validations = [hasUppercase, hasLowercase, hasNumber, hasSpecialChar, hasLengthOfEight];
  const PasswordCases = ["ABC", "abc", "123", "!@$", "8+ characters"];
  const passwordRequirements = ["upperCase", "lowerCase", "number", "special character", "8+ characters"];

  const checkPasswordRequirements = validations.map((value, index) => {
    if (value === false) {
      return passwordRequirements[index];
    }
    return null;
  });

  const missingConditions = checkPasswordRequirements.filter(Boolean).join(", ");

  const cases = PasswordCases.map((value, index) => {
    return (
      <PasswordCase validation={validations[index]} key={index} isPasswordFieldoutOfFocus={isPasswordFieldoutOfFocus}>
        {value}
      </PasswordCase>
    );
  });

  return (
    <StrengthIndicator className="strengthIndicator">
      <div
        className="strengthWrapper"
        tabIndex={0}
        aria-label={`${
          missingConditions
            ? `password doesn't meet the requirements, entered password is missing ${missingConditions}`
            : `you have entered a strong password`
        }`}
      >
        <Paragraph variant="secondaryParagraph4" className="required">
          Required:{" "}
        </Paragraph>
        {cases}
      </div>
    </StrengthIndicator>
  );
};

export default PasswordStrengthIndicator;
