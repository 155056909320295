import styled from "styled-components";
import { IMG_TEXTURED_BKGD_LIGHT_WHITE, IMG_VECTOR } from "assets/images";

export const NotAvailableForDeliveryStyled = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  .notAvailableForDelivery {
    background: ${(props) => props.theme.color.secondary.lightBlue};
    position: relative;
    .modalNavigatiomButton {
      position: absolute;
      top: 30px;
      right: 30px;
      width: auto;
    }
    .heading-container {
      width: 400px;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 25px;
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 41px;
      .heading {
        margin-bottom: 10px;
        font-family: ${(props) => props.theme.font.scriptRough};
        color: ${(props) => props.theme.color.core.red};
      }
      &::after {
        content: url(${IMG_VECTOR.src});
        display: block;
        position: absolute;
        left: 470px;
        top: 205px;
        bottom: 0;
      }
      .sub-heading {
        margin-bottom: 25px;
        font-family: ${(props) => props.theme.font.scriptRough};
        color: ${(props) => props.theme.color.core.blue};
      }
      .describtion {
        padding-left: 1px;
        font-family: ${(props) => props.theme.font.serifRegular};
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: ${(props) => props.theme.color.core.blue};
      }
    }
    .white-container {
      background: url(${IMG_TEXTURED_BKGD_LIGHT_WHITE.src});
      background-repeat: no-repeat;
      background-size: cover;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
      .footer-container {
        width: 308px;
        margin: 0 auto;
        padding-top: 38px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        text-align: initial;
        .un-image-container {
          display: flex;
          .un-item-image {
            width: 80px;
            height: 80px;
          }
        }
        .item-detail {
          padding-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: start;
          .item-title {
            display: flex;
            font-family: ${(props) => props.theme.font.serifBold};
            line-height: 16px;
            color: ${(props) => props.theme.color.core.blue};
          }
          .item-modifiers {
            font-family: ${(props) => props.theme.font.sansRegular};
            color: ${(props) => props.theme.color.core.red};
          }
          .item-size {
            color: ${(props) => props.theme.color.core.red};
            font-family: ${(props) => props.theme.font.sansRegular};
            font-size: 11px;
            line-height: 16px;
            display: flex;
          }
          .item-cost {
            display: flex;
            color: ${(props) => props.theme.color.core.blue};
            font-family: ${(props) => props.theme.font.serifBold};
          }
        }
        .un-button-cotainer {
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }
`;
