import styled, { css } from "styled-components";
import { NavigationButtonProps } from "../types";

const first = css`
  ${(props) => `
    color: ${props.theme.color.core.blue};
    background: ${props.theme.color.secondary.lightGrey};
    border: 2px solid transparent;  
  `}
`;

const second = css`
  ${(props) => `
    color: ${props.theme.color.core.blue};
    background: ${props.theme.color.core.white};
    border: 2px solid ${props.theme.color.core.blue};
  `}
`;

const third = css`
  ${(props) => `
    color: ${props.theme.color.core.white};
    background: ${props.theme.color.core.blue};
    border: 2px solid ${props.theme.color.core.white};
  `}
`;

const fourth = css`
  ${(props) => `
      color: ${props.theme.color.core.white};
      background: ${props.theme.color.secondary.veryDarkRed};
      border: 2px solid transparent
  `}
`;

const fifth = css`
  color: ${(props) => props.theme.color.core.blue};
  background: ${(props) => props.theme.color.secondary.pantone290};
  border: 2px solid ${(props) => props.theme.color.secondary.pantone290};
`;

const sixth = css`
  color: white;
  background: rgba(0, 0, 0, 0.6);
  border: 2px solid white;
`;

const seventh = css`
  color: ${(props) => props.theme.color.secondary.veryDarkRed};
  background: ${(props) => props.theme.color.secondary.lightGrey};
  border: 2px solid transparent;
`;

const eighth = css`
  color: ${(props) => props.theme.color.core.red};
  background: ${(props) => props.theme.color.secondary.lightGrey};
  border: 2px solid transparent;
`;

const ninth = css`
  color: ${(props) => props.theme.color.secondary.mildRed};
  background: ${(props) => props.theme.color.secondary.lightGrey};
  border: 2px solid transparent;
`;

/* Revisit here for ZP-5690 */
const tenth = css`
  background: transparent;
`;

const eleventh = css`
  background: transparent;
  color: ${(props) => props.theme.color.primary.foundersBlue};
  font-family: ${(props) => props.theme.font.barlow};
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
`;
const twelveth = css`
  background: transparent;
  font-family: ${(props) => props.theme.font.barlow};
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
`;

export const NavigationButtonStyled = styled.button<NavigationButtonProps>`
  padding: 12px 15px;
  border: none;
  outline: none;
  border-radius: 38px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  font-family: ${(props) => props.theme.font.sansRegular};
  text-transform: uppercase;
  cursor: pointer;

  ${(props) => ({ first, second, third, fourth, fifth, sixth, seventh, eighth, ninth, tenth, eleventh, twelveth }[props.variant])};
`;
