export const SET_STORE_MENU = "SET_STORE_MENU";
export const SET_STORE_MENU_WITH_MODIFIERS = "SET_STORE_MENU_WITH_MODIFIERS";
export const GET_CATEGORY_MENU_LIST = "GET_CATEGORY_MENU_LIST";
export const SET_SELECTED_TAB_NAME = "SET_SELECTED_TAB_NAME";
export const SET_SELECTED_CATEGORY_NAME = "SET_SELECTED_CATEGORY_NAME";
export const GET_AN_ITEM_FROM_STORE = "GET_AN_ITEM_FROM_STORE";
export const GET_DEFAULT_ITEM_SELECTED = "GET_DEFAULT_ITEM_SELECTED";
export const GET_ITEM_SECTIONS = "GET_ITEM_SECTIONS";
export const SET_SELECTED_OPTION = "SET_SELECTED_OPTION";
export const SET_OPTION_LIST = "SET_OPTION_LIST";
export const GET_GENERIC_MENU = "GET_GENERIC_MENU";
export const GET_GENERIC_MENU_WITH_MODIFIERS = "GET_GENERIC_MENU_WITH_MODIFIERS";
export const SET_UPDATED_QUANTITY = "SET_UPDATED_QUANTITY";
export const SET_PRICE = "SET_PRICE";
export const GET_MENU_BASED_ON_CATEGORY = "GET_MENU_BASED_ON_CATEGORY";
export const SET_FILES_PATH = "SET_FILES_PATH";
export const IS_REDIRECTED_FROM_HOME = "IS_REDIRECTED_FROM_HOME";
export const SET_PRIMARY_MODIFIERS = "SET_PRIMARY_MODIFIERS";
export const SELECTED_PRODUCT_OPTIONS_LIST = "SELECTED_PRODUCT_OPTIONS_LIST";
export const INCREMENT_ITEM_QUANTITY = "INCREMENT_ITEM_QUANTITY";
export const DECREMENT_ITEM_QUANTITY = "DECREMENT_ITEM_QUANTITY";
export const RESET_CUSTOMIZATION_DATA = "RESET_CUSTOMIZATION_DATA";
export const SET_SELECTED_PRODUCT_TO_MODAL = "SET_SELECTED_PRODUCT_TO_MODAL";
export const ITEM_COST = "ITEM_COST";
export const ITEM_BASE_CALORIE = "ITEM_BASE_CALORIE";
export const ITEM_MAX_CALORIE = "ITEM_MAX_CALORIE";
export const UNSELECTED_GROUPS = "UNSELECTED_GROUPS";
export const UNSELECTED_GROUPS_DETAILS = "UNSELECTED_GROUPS_DETAILS";
export const IS_SHOW_PULSE_EFFECT = "IS_SHOW_PULSE_EFFECT";
export const MENU_IS_LOADING = "MENU_IS_LOADING";
export const SET_CATEGORY_REDIRECT = "SET_CATEGORY_REDIRECT";
export const IS_PRODUCT_AVAILABLE = "IS_PRODUCT_AVAILABLE";
export const SET_BRAND_PRODUCT_ID = "SET_BRAND_PRODUCT_ID";
export const IS_MENU_ERROR = "IS_MENU_ERROR";
export const SET_MENU_ERROR_MESSAGE_TO_MODAL_FLAG = "SET_MENU_ERROR_MESSAGE_TO_MODAL_FLAG";
export const SET_ERROR_MESSAGE_TO_MODAL_FLAG = "SET_ERROR_MESSAGE_TO_MODAL_FLAG";
export const SET_SELECTED_MENU_CATEGORY = "SET_SELECTED_MENU_CATEGORY";
export const SET_SELECTED_LOCATION_PRODUCT = "SET_SELECTED_LOCATION_PRODUCT";
export const SET_PREVIOUS_ROUTE_URL = "SET_PREVIOUS_ROUTE_URL";
export const SET_PRODUCT_NAME_TO_NAVIGATE = "SET_PRODUCT_NAME_TO_NAVIGATE";
export const SET_SELECTED_CATEGORY_PRODUCTS = "SET_SELECTED_CATEGORY_PRODUCTS";
export const SET_FROM_HOME_REORDER_SECTION = "SET_FROM_HOME_REORDER_SECTION";
