import { CareersStore, ResetCareersStoreAction, SetCareersStoreAction, SetClearCacheAction } from "./types";

export const setCareerStore = (payload: CareersStore): SetCareersStoreAction => ({
  type: "SET_CAREERS_STORE",
  payload,
});

export const resetCareerStore = (): ResetCareersStoreAction => ({
  type: "RESET_CAREERS_STORE",
});

export const setClearCache = (payload: boolean): SetClearCacheAction => ({
  type: "SET_CLEAR_CACHE",
  payload,
});
