import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/atomic-components/atoms/layout-elements/Modal/Modal";
import { HorizontalLine } from "components/atomic-components/atoms/HorizontalLineStyled/HorizontalLineStyled";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Button from "components/atomic-components/atoms/button/Button";
import { ErrorMessage } from "redux/reducer/UserStatus/types";
import { SET_ERROR_MESSAGE_TO_MODAL, SET_ERROR_MESSAGE_TO_MODAL_FLAG } from "redux/reducer/UserStatus/UserStatusConst";
import { ErrorModalStyled } from "./ErrorModalStyled";

interface Props {
  isOpen?: boolean;
  heading: string;
  subHeading?: string;
  buttonCTA?: string;
  isBackButton?: boolean;
  isShowCMSErrorMessage?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  className?: string;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  mobileVariant?: "fullScreen" | "croppedVariant" | string;
  hideButtonCTA?: boolean;
  paragraphFontWeight?: number;
  paragraphMobileFontWeight?: number;
  showSubheadingInNextLine?: boolean;
  isHeadingItalic?: boolean;
  errorModalClassName?: string;
  showHorizontalLine?: boolean;
}

type RootState = {
  userStatus: {
    errorMessageToModal: ErrorMessage;
    errorMessageFlag: boolean;
  };
  currentDevice: {
    device: string;
  };
};

const ErrorModal = ({
  isOpen = false,
  onClose,
  heading,
  subHeading,
  buttonCTA = "OK",
  isBackButton,
  isShowCMSErrorMessage = false,
  hideButtonCTA,
  paragraphFontWeight,
  paragraphMobileFontWeight,
  showCloseButton = false,
  showSubheadingInNextLine = false,
  isHeadingItalic = false,
  errorModalClassName = "",
  showHorizontalLine = false,
  ...rest
}: Props) => {
  const dispatch = useDispatch();
  const { errorMessageFlag, device } = useSelector((state: RootState) => ({
    errorMessageFlag: state.userStatus?.errorMessageFlag,
    device: state.currentDevice?.device,
  }));

  const isSubheading = !!subHeading;
  const showTextInNextLine = isSubheading && showSubheadingInNextLine;
  const splittedSubHeading = subHeading?.split(",");

  const closeModal = () => {
    onClose?.();

    dispatch({
      type: SET_ERROR_MESSAGE_TO_MODAL,
      payload: null,
    });
    dispatch({
      type: SET_ERROR_MESSAGE_TO_MODAL_FLAG,
      payload: false,
    });
  };

  return (
    <Modal showCloseButton={showCloseButton || isBackButton} isOpen={errorMessageFlag || isOpen} {...rest} onClose={closeModal}>
      <ErrorModalStyled
        className={errorModalClassName}
        paragraphFontWeight={paragraphFontWeight}
        paragraphMobileFontWeight={paragraphMobileFontWeight}
      >
        <Headings className="heading" variant={isHeadingItalic ? "secondaryHeading1" : "primaryHeading3"}>
          {heading}
        </Headings>
        {device === "MOBILE" && showHorizontalLine && <HorizontalLine className="hr" />}
        {isSubheading && !showSubheadingInNextLine && (
          <>
            {!isShowCMSErrorMessage && (
              <Paragraph className="paragraph" variant="secondaryParagraph3">
                {subHeading}
              </Paragraph>
            )}
            {isShowCMSErrorMessage && <div dangerouslySetInnerHTML={{ __html: subHeading }} className="cmsErrorMessageLink" />}
          </>
        )}
        {showTextInNextLine && device === "DESKTOP" && (
          <Paragraph className="paragraph" variant="secondaryParagraph3">
            <span>{splittedSubHeading?.[0]},</span>
            <br />
            <span>{splittedSubHeading?.[1]}</span>
          </Paragraph>
        )}
        {showTextInNextLine && device === "MOBILE" && (
          <>
            <Paragraph className="paragraph" variant="secondaryParagraph3">
              {subHeading}
            </Paragraph>
            {isShowCMSErrorMessage && <div dangerouslySetInnerHTML={{ __html: subHeading }} className="cmsErrorMessageLink" />}
          </>
        )}
        {!hideButtonCTA && (
          <Button className="cta" variant="modalOutlineButton" onClick={closeModal}>
            {buttonCTA ?? "Close"}
          </Button>
        )}
      </ErrorModalStyled>
    </Modal>
  );
};

export default ErrorModal;
