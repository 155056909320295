import {
  DELETE_USER_ADDRESS,
  SET_ADDRESS_LIST,
  SET_USER_ADDRESS,
  SET_FAVOURITE_ADDRESS,
  SET_USER_ADDRESS_FROM_MODAL,
  USER_ADDRESS_AFTER_UPDATE,
  SET_USER_ADDRESS_FROM_AUTO_COMPLETE,
  SET_USER_ENTERED_DELIVERY_ADDRESS,
  SET_USER_ADDRESS_CONFIRMATION,
  ADDRESS_IS_LOADING,
  SET_ADD_ADDRESS_MODAL,
  EDIT_ADD_ADDRESS_MODAL,
} from "./AddressConst";

const initialState = {
  isLoading: false,
  addressList: [],
  favouriteAddressList: [],
  userAddress: {},
  deleteUserAddress: {},
  favouriteAddress: null,
  userAddressFromModal: {},
  userAddressAfterUpdate: {},
  userAddressFromAutoComplete: {},
  userEnteredDeliveryAddress: {},
  userAddressConfirmation: false,
  isAddressModalOpen: false,
  isAddressEditModalOpen: false,
};

export default function AddressReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case ADDRESS_IS_LOADING:
      return { ...state, isLoading: action.payload || false };

    case SET_ADDRESS_LIST:
      return {
        ...state,
        addressList: action.payload,
        isLoading: false,
      };
    case SET_USER_ADDRESS:
      return {
        ...state,
        userAddress: action.payload,
      };
    case DELETE_USER_ADDRESS:
      return {
        ...state,
        deleteUserAddress: action.payload,
        isLoading: false,
      };
    case SET_FAVOURITE_ADDRESS:
      return {
        ...state,
        favouriteAddress: action.payload,
        isLoading: false,
      };
    case SET_USER_ADDRESS_FROM_MODAL:
      return {
        ...state,
        userAddressFromModal: action.payload,
        userAddress: action.payload,
      };
    case USER_ADDRESS_AFTER_UPDATE:
      return {
        ...state,
        userAddressAfterUpdate: action.payload,
      };
    case SET_USER_ADDRESS_FROM_AUTO_COMPLETE:
      return {
        ...state,
        userAddressFromAutoComplete: action.payload,
      };
    case SET_USER_ENTERED_DELIVERY_ADDRESS:
      return {
        ...state,
        userEnteredDeliveryAddress: action.payload,
      };
    case SET_USER_ADDRESS_CONFIRMATION:
      return {
        ...state,
        userAddressConfirmation: action.payload,
      };
    case SET_ADD_ADDRESS_MODAL:
      return {
        ...state,
        isAddressModalOpen: action.payload,
      };
    case EDIT_ADD_ADDRESS_MODAL:
      return {
        ...state,
        isAddressEditModalOpen: action.payload,
      };
    default:
      return state;
  }
}
