import SecondaryButton from "../Buttons/SecondaryButton/SecondaryButton";
import ValueUpdated from "../ValueUpdated/ValueUpdated";

interface Props {
  onClose: () => void;
  actionType?: string;
}

const PhoneUpdated = ({ onClose, actionType }: Props) => {
  const buttons = (
    <>
      <SecondaryButton variant="first" onClick={onClose}>
        close
      </SecondaryButton>
    </>
  );
  return <ValueUpdated valueName="phone number" buttons={buttons} actionType={actionType} />;
};

export default PhoneUpdated;
