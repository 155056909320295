import styled from "styled-components";

export const AccountPageTitleStyled = styled.h1<{ maxWidth: number }>`
  font-size: 34px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 25px;
  font-family: ${(props) => props.theme.font.scriptRough};
  color: ${(props) => props.theme.color.core.blue};
  width: min(90%, ${(props) => props.maxWidth + "px"});
  text-align: center;
`;
