import Image from "next/image";
import { useSelector } from "react-redux";
import { CHICKEN_PIN_LOGO } from "assets/images";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { LocationNoStoreFoundStyled } from "./LocationNoStoreFoundStyled";

const LocationNoStoreFound = ({
  width = "200",
  title,
  descriptionLineOne,
  descriptionLineTwo,
  className,
}: {
  width?: string;
  title?: string;
  descriptionLineOne: string;
  descriptionLineTwo?: string;
  className?: string;
}) => {
  const { device } = useSelector((state: any) => ({
    device: state?.currentDevice.device,
  }));

  const isMobile = device === "MOBILE";
  return (
    <LocationNoStoreFoundStyled className={className} width={width}>
      <Image src={CHICKEN_PIN_LOGO} alt="chicken logo" />
      {title && (
        <Headings variant="headingMedium" fontSize={20}>
          {title}
        </Headings>
      )}
      <div className="descriptionContainer">
        <Paragraph variant="secondaryParagraph1" fontWeight={isMobile ? 500 : 700} fontSize={isMobile ? 14 : 16} className="text">
          {descriptionLineOne}
        </Paragraph>
        <Paragraph variant="secondaryParagraph1" fontWeight={isMobile ? 500 : 700} fontSize={isMobile ? 14 : 16} className="text">
          {descriptionLineTwo}
        </Paragraph>
      </div>
    </LocationNoStoreFoundStyled>
  );
};

export default LocationNoStoreFound;
