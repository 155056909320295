import { DeviceAction } from "./types";
import { SET_CURRENT_DEVICE } from "./DeviceConst";

const initialState = {
  device: "DESKTOP",
};

export default function DeviceReducer(state = initialState, action: DeviceAction) {
  if (action.type === SET_CURRENT_DEVICE) {
    return {
      ...state,
      device: action.payload,
    };
  }
  return state;
}
