import OtpInput from "react-otp-input";
import { CodeInputStyled, Separator } from "./CodeInputStyled";

interface Props {
  name: string;
  value: string;
  error: boolean;
  length: number;
  placeholderText?: string;
  onChange: (name: string, value: string) => void;
  isDisabled?: boolean;
}

const CodeInput = ({ name, value, error, placeholderText, length = 6, onChange, isDisabled = false }: Props) => {
  const handleChange = (inputValue: string) => {
    onChange(name, inputValue);
  };

  // Add "one-time-code" as autoComplete value here
  // https://developer.apple.com/documentation/security/password_autofill/enabling_password_autofill_on_an_html_input_element
  // Enabling Password AutoFill on an HTML input element | Apple Developer Documentation

  return (
    <CodeInputStyled error={error} valueLength={value.length} isDisabled={isDisabled}>
      <OtpInput
        value={value}
        numInputs={length}
        onChange={handleChange}
        renderSeparator={<Separator />}
        renderInput={(props) => <input {...props} disabled={isDisabled} />}
        inputStyle="codeInputStyle"
        placeholder={placeholderText}
        inputType="tel"
        shouldAutoFocus
      />
    </CodeInputStyled>
  );
};

export default CodeInput;
