import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mParticle from "@mparticle/web-sdk";
import dayjs from "dayjs";
import { useBasket } from "hooks/basket/useBasket";
import { useStores } from "hooks/stores/useStores";
import { ANALYTICS_CONST } from "constants/analytics";
import { setBasketStoreId } from "redux/reducer/basket/actions";
import {
  setDeliveryLocationCoordinates,
  setDeliveryLocationName,
  setFavouriteStore,
  setPickupLocationName,
  setPickupOrderType,
  setSelectedStore,
  setSelectedStoreName,
  setStoreLocation,
  setIsStoreAvailable,
  setCorporateStoreId,
  getStoreDetails,
  setFavouriteStoreList,
} from "redux/reducer/Store/actions";
import { apiCall } from "src/apis/api";
import Button from "components/atomic-components/atoms/button/Button";
import Analytics from "analytics/Analytics";
import { MENU, ORDER_DETAILS } from "api/endpoints";
import { DEFAULT_PRODUCT_URL, PICKUP, PICKUP_ORDER_TYPE } from "constants/constant";
import { urlTrim } from "utils/cleaning";
import { escapeCategoryName } from "utils/helper";
import { createProductForMparticle, getPrice } from "utils/mparticle/mparticleHelper";
import { getLocalStorageData, setLocalStorageData, setSessionStorageData, setDataToCookie } from "utils/storage";
import { MENU_URL_REGEX } from "constants/regexPatterns";
import { setClearCache } from "redux/reducer/Careers/CareersAction";
import { setLocationStep, setPinsDisableOnLocation } from "redux/reducer/Location/LocationAction";
import {
  isRedirectedFromHome,
  getStoreMenu,
  setSelectedTabName,
  resetCustomizationData,
  setUnselectedGroups,
  setUnselectedGroupsDetails,
  setFromHomeReorderSection,
} from "redux/reducer/Menu/MenuAction";
import { setErrorMessageToModal, setErrorMessageToModalFlag, setPresentUserOrderType } from "redux/reducer/UserStatus/UserStatusAction";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import useReorder from "hooks/useReorder";
import { CommonLayerResponseData, Menu, Product, RecentOrderResponse, RootState, Store } from "src/types/types";
import AddressSelectedCardBody from "../AddressSelectedCardBody/AddressSelectedCardBody";
import AddressSelectedCardContainer from "../AddressSelectedCardContainer/AddressSelectedCardContainer";
import { Buttons } from "../AddressSelectedPickupCard/AddressSelectedPickupCardStyled";
import { AddressPickUpCardStyled, BackButtonStyled } from "./AddressPickupCardStyled";

interface Props {
  store?: Store;
  deviceType?: boolean;
}

const AddressPickupCard = ({ store, deviceType }: Props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const reorder = useReorder();

  const {
    itemsNotTransferred,
    famousMealFlag,
    basket,
    details,
    tokens,
    selectedProduct,
    selectedStoreId,
    fromCheckout,
    selectedTabName,
    filteredStoreList,
    storeDetail,
    storeId,
    isAlternativeFlow,
    favouriteStoreList,
    previousRouteURL,
    selectedLocationProducts,
    fromHomeReorderSection,
    recentOrdersList,
    careersStore,
  } = useSelector((state: RootState) => ({
    itemsNotTransferred: state.basket?.itemsNotTransferred,
    famousMealFlag: state.store?.famousMealFlag,
    basket: state.basket?.basket,
    details: state.user.details,
    tokens: state.user.tokens,
    selectedProduct: state.menu?.selectedProduct,
    selectedStoreId: state.store?.selectedStoreId,
    fromCheckout: state.userStatus?.fromCheckout,
    selectedTabName: state.menu?.selectedTabName,
    filteredStoreList: state.store?.filteredStoreList,
    storeDetail: state.store?.storeDetail,
    storeId: state.store?.selectedStoreId,
    isAlternativeFlow: state.location.isAlternativeFlow,
    favouriteStoreList: state?.store?.favouriteStoreList,
    previousRouteURL: state.menu?.previousRouteURL,
    selectedLocationProducts: state?.menu?.selectedLocationProducts,
    fromHomeReorderSection: state.menu?.fromHomeReorderSection,
    recentOrdersList: state?.placeOrder?.recentOrdersList,
    careersStore: state.careers?.careersStore,
  }));

  const { createBasketId, transferBasketToAnotherStore } = useBasket();
  const { getStoreETADetails } = useStores();
  const routerUrl = getLocalStorageData("previousURL") ?? "";

  // saving store details in same variable type.
  let storeData = storeDetail;
  const [transferBasketActionCalled, setTransferBasketActionCalled] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [earliestReadyTime, setEarliestReadyTime] = useState(storeData?.eta);
  const [callMparticleEvent, setCallMparticleEvent] = useState(false);

  useEffect(() => {
    const hasProducts = basket && basket?.products?.length > 0;
    const noItemsToTransfer = itemsNotTransferred?.length === 0;
    const hasItemsToTransfer = itemsNotTransferred?.length !== 0;
    const moreItemsNotTransferredThanProducts = itemsNotTransferred?.length > basket?.products?.length;

    const redirectToCheckout = () => router.push("/checkout");
    const redirectToCart = () => router.push("/cart");
    const redirectToMenu = () => router.push({ pathname: "/menu", query: { storeId: storeData?.storeId } }, "/menu");

    if (fromCheckout && transferBasketActionCalled) {
      if (hasProducts && noItemsToTransfer) {
        redirectToCheckout();
      } else {
        if (hasProducts && hasItemsToTransfer) {
          redirectToCart();
        }
        if (moreItemsNotTransferredThanProducts) {
          redirectToMenu();
        }
        if (itemsNotTransferred.length === basket.products.length) {
          redirectToMenu();
        }
      }
    }
  }, [itemsNotTransferred, fromCheckout, transferBasketActionCalled, basket, storeData?.storeId, router]);

  useEffect(() => {
    // itemsNotTransferred is a redux value and it will be having value even when this component unmounts
    // when user open this pop up from diffrent pages the remove action will be triggered as itemsTrasferred is having value
    // we don't need to trigger this event each time this component is mounted.
    // so maintaing a local state which will be set to true on successful basket transfer
    if (itemsNotTransferred?.length > 0 && callMparticleEvent) {
      const mparticleProductList: mParticle.Product[] = itemsNotTransferred?.map((product: any) => {
        return createProductForMparticle(product?.title, product?.productId, getPrice(product), product?.quantity, {
          currency: "USD",
          store_id: selectedStoreId,
          modifiers: JSON.stringify(product?.choices),
        });
      });
      // remove from cart event in mParticle and Amplitude
      mParticle.eCommerce.logProductAction(mParticle.ProductActionType.RemoveFromCart, mparticleProductList);
      Analytics.getInstance().logEvent(
        ANALYTICS_CONST.ORDER.REMOVE_ITEM_FROM_CART,
        {
          ecommerce: {
            ...itemsNotTransferred,
            store_id: selectedStoreId,
          },
        },
        false
      );
    }
  }, [itemsNotTransferred?.length]);

  const redirectOnButtonClick = async (selectedStore: number, corporateStoreId: string, orderSubtype: string, storeName: string) => {
    const event = {
      curbside: ANALYTICS_CONST.STORE.CLICK_CURBSIDE,
      instore: ANALYTICS_CONST.STORE.CLICK_INSTORE,
      drivethru: ANALYTICS_CONST.STORE.CLICK_DRIVETHROUGH,
    }[orderSubtype];

    if (event) Analytics.getInstance().logEvent(event, { ecommerce: { store_id: corporateStoreId } });
    let basketId = "";
    let recentOrdersRefreshed: RecentOrderResponse[];
    let productAvailable: Product;
    const handoffMode = orderSubtype === "instore" ? "pickup" : orderSubtype;
    if (!basket?.basketId) {
      await createBasketId({
        storeId: selectedStore,
        accessToken: tokens?.accessToken ?? "",
        handoffMode,
      }).then((data) => {
        basketId = data?.response?.basketId;
      });
    }

    if (previousRouteURL && selectedLocationProducts.productId) {
      await apiCall({
        requestType: "GET",
        apiEndPoint: `${MENU}/${storeId}?includePrimaryModifiers=true`,
      }).then(({ response }) => {
        productAvailable = response
          ?.find((category: Menu) => escapeCategoryName(category?.name) === selectedLocationProducts?.categoryName)
          ?.products?.find(
            (product: Product) => product?.title?.toLocaleLowerCase() === selectedLocationProducts?.productName?.toLocaleLowerCase()
          );
      });
    }

    // Refresh the recent orders if the flow is from home and no store was previously selected
    if (fromHomeReorderSection?.isProduct) {
      await apiCall({
        requestType: "POST",
        apiEndPoint: `${ORDER_DETAILS}recents?basedOnStore=true`,
        apiPostData: {
          accessToken: tokens?.accessToken,
          storeId: selectedStore,
        },
      }).then((response) => {
        recentOrdersRefreshed = response.response;
      });
    }

    if (basket?.basketId || basketId) {
      await transferBasketToAnotherStore({
        storeId: selectedStore,
        basketId: basket?.basketId ?? basketId,
        handoffMode,
      }).then((res: CommonLayerResponseData) => {
        if (res.success === false) {
          dispatch(setErrorMessageToModal(res.error.message));
          dispatch(setErrorMessageToModalFlag(true));
        } else if (res.success) {
          setCallMparticleEvent(true);
          dispatch(setIsStoreAvailable(storeData.available));
          dispatch(getStoreDetails(selectedStore));
          dispatch(setSelectedTabName(selectedTabName ?? "Menu"));

          // Updating the Store Menu Details
          dispatch(getStoreMenu(selectedStore.toString()));
          dispatch(isRedirectedFromHome(false));
          dispatch(setPickupOrderType(orderSubtype));
          dispatch(setPresentUserOrderType("PICKUP"));
          dispatch(setPickupLocationName(""));
          dispatch(setDeliveryLocationName(""));
          dispatch(setDeliveryLocationCoordinates({}));
          dispatch(setCorporateStoreId(storeData?.corporateStoreId));

          dispatch(setBasketStoreId(selectedStore));
          dispatch(setSelectedStore(selectedStore));
          dispatch(setSelectedStoreName({ deliveryType: "Pickup", storeAddress: storeName }));

          // Update the basketId in the cookie storage
          setDataToCookie("basketId", res.response.basket?.basketId);

          // Remove the unSelectedGroups from the previous store
          dispatch(resetCustomizationData());
          dispatch(setUnselectedGroups([]));
          dispatch(setUnselectedGroupsDetails([]));

          // Show Reward Not Applicable Modal
          if (res.response.basket?.basketValidationMessages?.length > 0) {
            setSessionStorageData("REWARD_NOT_APPLICABLE", "true");
            dispatch(setErrorMessageToModal(res?.response?.basket?.basketValidationMessages[0]));
          }

          // As the Same code is duplicated in the condition so removed & re-used below
          const orderData = {
            presentUserOrderType: PICKUP_ORDER_TYPE,
            pickupOrderType: orderSubtype,
            selectedStoreId: selectedStore,
            selectedStoreDetail: {
              deliveryType: PICKUP,
              storeAddress: storeName,
            },
          };

          setLocalStorageData("store", JSON.stringify(orderData));

          if (fromCheckout) {
            setTransferBasketActionCalled(true);
          } else if (fromHomeReorderSection) {
            const product = ((recentOrdersRefreshed || recentOrdersList) as any)
              .find(({ products }: { products: { productId: string }[] }) =>
                products.find(({ productId }: { productId: string }) => productId === fromHomeReorderSection.id)
              )
              ?.products.find(({ productId }: { productId: string }) => productId === fromHomeReorderSection.id);
            if (fromHomeReorderSection.isProduct && product) {
              router.push(
                {
                  pathname: "/menu/[categoryName]/[productTitle]",
                  query: { storeId: selectedStoreId, productId: fromHomeReorderSection.id },
                },
                `/menu/${urlTrim(product?.category)}/${urlTrim(product?.name as string)}`
              );
            } else if (fromHomeReorderSection.isProduct && !product) {
              dispatch(setFromHomeReorderSection({ id: fromHomeReorderSection.id, notFound: true }));
              return router.push("/");
            } else {
              reorder(fromHomeReorderSection.id, handoffMode, selectedStore, true);
              dispatch(setFromHomeReorderSection());
            }
          } else {
            if (productAvailable?.title) {
              router.push(
                {
                  pathname: "/menu/[categoryName]/[productTitle]",
                  query: { storeId: selectedStore, productId: productAvailable?.productId },
                },
                `/menu/${selectedLocationProducts?.categoryName}/${urlTrim(productAvailable?.title)}`
              );
            } else if (MENU_URL_REGEX.test(routerUrl?.split("?")?.[0])) {
              router.push(
                {
                  pathname: "/menu/[categoryName]/[productTitle]",
                  query: { productId: undefined },
                },
                routerUrl
              );
            } else if (routerUrl?.split("?")?.[0] === DEFAULT_PRODUCT_URL) {
              router.push(
                {
                  pathname: "/menu-category/products",
                  query: { storeId: selectedStore, previousRoute: router?.asPath },
                },
                `/menu-category/products`
              );
            } else if (famousMealFlag) {
              router.push("/");
            } else {
              router.push(
                {
                  pathname: "/menu",
                  query: { storeId: selectedStore, previousRoute: router?.asPath },
                },
                `/menu`
              );
              // removed the code for routing to item customization page ZS-1021
            }
          }
        }
      });
    }

    if (selectedProduct.catering) {
      if (selectedStoreId) {
        router.push(
          {
            pathname: "/menu",
            query: { storeId: selectedStoreId },
          },
          "/menun#cateringContainer"
        );
      } else {
        router.push("/menu#cateringContainer");
      }
    }
  };

  const redirectToCareers = async () => {
    /* Commented the below code as the OLO API is not used in the Careers Flow */
    dispatch(setClearCache(false));

    router.push("/careers/application");
  };

  if (+storeId !== 0 && filteredStoreList && filteredStoreList.length > 0) {
    const matchStore = filteredStoreList.find((sId: Store) => sId.storeId === storeId);
    if (matchStore) {
      storeData = matchStore;
    }
  }

  const isDriveThru = deviceType ? store?.supportsDriveThru : storeData?.supportsDriveThru;
  const isCrubSide = deviceType ? store?.supportsCurbside : storeData?.supportsCurbside;
  const isPickUpAvailable = deviceType ? store?.supportsPickup : storeData?.supportsPickup;
  useEffect(() => {
    setEarliestReadyTime(() => storeData?.eta ?? earliestReadyTime);
  }, [storeData?.eta]);

  const onBack = () => {
    dispatch(setSelectedStore(0));
    dispatch(setPinsDisableOnLocation(false));
    dispatch(setLocationStep("LOGGED_IN_SEARCH"));
  };

  useEffect(() => {
    if (storeData?.currentlyOpen === false) {
      getStoreETADetails(storeData?.storeId).then((apiResponse) => {
        if (apiResponse.success) {
          const businessHours = apiResponse.response.find((ele: any) => ele.type === "business");
          if (businessHours?.eta) {
            setEarliestReadyTime(dayjs(businessHours.eta).format("MMM DD @ hh:mm A"));
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    const storeList = favouriteStoreList?.find((sId: any) => sId.storeId.toString() === storeData?.storeId?.toString() && !!sId.default);
    setIsFavourite(!!storeList?.storeId);
    dispatch(
      setStoreLocation({
        lat: storeData?.latitude,
        lng: storeData?.longitude,
      })
    );
  }, [favouriteStoreList, storeData]);

  const handleUpdateFavoriteStore = () => {
    setIsFavourite(!isFavourite);
    dispatch(
      setFavouriteStore({
        storeId: storeData?.storeId,
        default: !isFavourite,
        idToken: tokens?.idToken ?? "",
        externalId: details?.externalId ?? "",
      })
    );

    const favStoreList = favouriteStoreList?.map((favouStore: any) => ({
      ...favouStore,
      default: Number(favouStore?.storeId) === Number(storeData?.storeId) ? !isFavourite : favouStore?.default,
    }));

    dispatch(setFavouriteStoreList(favStoreList));
  };

  const primaryFooter = (
    <>
      <Paragraph variant="paragraphHeading1" className="readyTimeText" mobileFontSize={20}>
        READY IN:{earliestReadyTime}{" "}
      </Paragraph>
      <Paragraph variant="paragraphHeading1" mobileFontSize={20}>
        CHOOSE a Pickup METHOD{" "}
      </Paragraph>
      <Buttons>
        <Button
          variant="primaryRed"
          disabled={!isPickUpAvailable}
          onClick={() => redirectOnButtonClick(storeData?.storeId, storeData.corporateStoreId, "instore", storeData.address)}
        >
          IN STORE
        </Button>
        <Button
          variant="primaryRed"
          disabled={!isDriveThru}
          onClick={() => redirectOnButtonClick(storeData?.storeId, storeData.corporateStoreId, "drivethru", storeData.address)}
        >
          DRIVE-THRU
        </Button>
        <Button
          variant="primaryRed"
          disabled={!isCrubSide}
          onClick={() => redirectOnButtonClick(storeData?.storeId, storeData.corporateStoreId, "curbside", storeData.address)}
        >
          CURBSIDE
        </Button>
      </Buttons>
    </>
  );

  const alternativeFooter = (
    <Button className="selectStoreButton" variant="primaryRed" onClick={redirectToCareers}>
      SELECT STORE
    </Button>
  );

  const Footer = isAlternativeFlow ? alternativeFooter : primaryFooter;

  const cardBackButton = (
    <BackButtonStyled variant="ghostBlue" onClick={onBack} data-testid="backButton">
      BACK
    </BackButtonStyled>
  );

  const selectedStoreData = isAlternativeFlow ? careersStore : storeData;

  const Body = (
    <AddressSelectedCardBody
      storeData={deviceType ? store : selectedStoreData}
      backButton={cardBackButton}
      isFavourite={isFavourite}
      onUpdateFavoriteStore={handleUpdateFavoriteStore}
    />
  );

  return (
    <AddressPickUpCardStyled>
      <AddressSelectedCardContainer footer={Footer} body={Body} className="selectedCardContainer" />
    </AddressPickUpCardStyled>
  );
};

export default AddressPickupCard;
