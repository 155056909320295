import { IC_ARROW_DOUBLE_BACK, IC_ARROW_DOUBLE_WHITE } from "assets/images";
import { CarouselButtonStyled, ArrowIcon } from "./CarouselButtonStyled";

interface Props {
  id?: string;
  onClick?: () => void;
  direction: "left" | "right";
  isArrowWhite?: boolean;
  className?: string;
  type?: string;
}

const CarouselButton = ({ id, direction, isArrowWhite, onClick, className, type }: Props) => {
  const isReversed = direction === "right";

  const arrowImage = isArrowWhite ? IC_ARROW_DOUBLE_WHITE : IC_ARROW_DOUBLE_BACK;
  return (
    <CarouselButtonStyled
      $isReversed={isReversed}
      onClick={onClick}
      isArrowWhite={isArrowWhite}
      className="slickSliderButton"
      buttonType={type}
    >
      <ArrowIcon id={id} alt={isReversed ? "Previous" : "Next"} src={arrowImage} $isReversed={isReversed} className="arrowIcon" />
    </CarouselButtonStyled>
  );
};

export default CarouselButton;
