export const SET_ADDRESS_LIST = "SET_ADDRESS_LIST";
export const SET_FAVOURITE_ADDRESS_LIST = "SET_FAVOURITE_ADDRESS_LIST";
export const SET_USER_ADDRESS = "SET_USER_ADDRESS";
export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS";
export const SET_FAVOURITE_ADDRESS = "SET_FAVOURITE_ADDRESS";
export const SET_USER_ADDRESS_FROM_MODAL = "SET_USER_ADDRESS_FROM_MODAL";
export const USER_ADDRESS_AFTER_UPDATE = "USER_ADDRESS_AFTER_UPDATE";
export const SET_USER_ADDRESS_FROM_AUTO_COMPLETE = "SET_USER_ADDRESS_FROM_AUTO_COMPLETE";
export const SET_USER_ENTERED_DELIVERY_ADDRESS = "SET_USER_ENTERED_DELIVERY_ADDRESS";
export const SET_USER_ADDRESS_CONFIRMATION = "SET_USER_ADDRESS_CONFIRMATION";
export const ADDRESS_IS_LOADING = "ADDRESS_IS_LOADING";
export const SET_ADD_ADDRESS_MODAL = "SET_ADD_ADDRESS_MODAL";
export const EDIT_ADD_ADDRESS_MODAL = "EDIT_ADD_ADDRESS_MODAL";
