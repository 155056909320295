import AddressSelectedPickupCard from "components/OrderNow/AddressSelectedCard/AddressSelectedPickupCard/AddressSelectedPickupCard";
import OrderPageContainer from "../OrderPageContainer/OrderPageContainer";

const AddressSelectedPickup = () => {
  return (
    <OrderPageContainer mobileLayout="REBRAND_SCREEN">
      <AddressSelectedPickupCard />
    </OrderPageContainer>
  );
};

export default AddressSelectedPickup;
